import axios from "axios";

// export const searchPartner = (body) => async (dispatch) => {
//   const baseURL = process.env.REACT_APP_ADMIN_BASE_URL_SERVER;
//   try {
//     const res = await axios.post(baseURL + "/user/search", body);

//     dispatch({
//       type: "SEARCH_WAS_SUCCESSFUL",
//       payload: res.data.data,
//     });
//   } catch (error) {
//     console.log(error.message);
//   }
// };
export const searchPartner = (body, page, perPage) => async (dispatch) => {
  const baseURL = process.env.REACT_APP_ADMIN_BASE_URL_SERVER;
  
  try {
    let pagination;
    const res = await axios.post(baseURL + `/user/search?page=${page}&perPage=${perPage}`, body);

    dispatch({
      type: "SEARCH_WAS_SUCCESSFUL",
      payload: {
        users: res.data.data.users, 
        pagination: res.data.data.pagination,
      },
    });
    // console.log('action search', res.data.data)
  } catch (error) {
    console.log(error.message);
  }
};