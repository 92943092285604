import React, { useState } from "react";
import "./changepass.css";
import axios from "../../axios";

const Changepass = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);

    // Compare the new password and confirm password
    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match");
      // alert("New password and confirm password do not match")
      return;
    }

    try {
      // Send the API request to change the password
      const res = await axios.post("/user/change-password", {
        newPassword: newPassword,
      });

      // Check the response for success
      if (res.data.success) {
        setSuccess(true);
        // alert("Password changed successfully!");
      } else {
        setError("Password change failed");
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
      console.error(error);
    }
  };

  return (
    <div className="">
      <div className="">
        <h2>Change Password</h2>
        <form className="password-form" onSubmit={handlePasswordChange}>
          <label htmlFor="new-password">New Password:</label>
          <input
            type="password"
            id="new-password"
            name="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <br />

          <label htmlFor="confirm-password">Confirm New Password:</label>
          <input
            type="password"
            id="confirm-password"
            name="confirm-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <br />

          <input type="submit" value="Submit" className="submit-button" />
        </form>
        {error && <p className="error-message">{error}</p>}
        {success && (
          <p className="success-message">Password changed successfully</p>
        )}
      </div>
    </div>
  );
};

export default Changepass;
