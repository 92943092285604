import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { AiFillHeart, AiOutlineStar } from "react-icons/ai";
import { FaUserTimes, FaUserCircle } from "react-icons/fa";
import { MdReport } from "react-icons/md";
// import { CgProfile } from "react-icons/cg";
import { image } from "../../constants";
import authAxios from "../../axios";
import axio from "../../axios";
import "./sammelanUser.css";
import MessageIcon from "@material-ui/icons/Message";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSammelan,
  fetchSammleanUsers,
  setCurrentPage,
} from "../../redux/actions";

// import NotifyPopup from '../../component/NotifyPopup/NotifyPopup';

const SammelanUser = () => {
  const getMe = async (setCurrentUser) => {
    try {
      const res = await authAxios.get(
        process.env.REACT_APP_ADMIN_BASE_URL_SERVER + "/user/me"
      );
      setCurrentUser(res.data.user);
    } catch (error) {
      console.log(error.message);
    }
  };

  const sammelan = useSelector((state) => state.User.sammelan);
  // const sammelanUsers = useSelector((state) => state.User.sammelanUsers)
  const sammelanUsersState = useSelector((state) => state.User.sammelanUsers);
  const sammelanUsers = sammelanUsersState ? sammelanUsersState.users : [];
  const pagination = sammelanUsersState ? sammelanUsersState.pagination : null;

  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.User);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  // console.log("Sammelan ID:", id);
  // const [isShortlisted, setIsShortlisted] = useState(false)
  const [isOpenShortlist, setIsOpenShortlist] = useState(false);
  const [isOpenInterest, setIsOpenInterest] = useState(false);

  const [valueShortlist, setvalueShortlist] = useState();
  const [valueInterest, setvalueInterest] = useState();

  // const [sammelan, setSammelan] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  // const [currentPage, setCurrentPage] = useState(1);
  // const { currentPage } = useSelector((state) => state.User);
  const currentPage = useSelector((state) => state.User.currentPage);

  const pageChangeHandler = (pageNumber) => {
    console.log("New Page:", pageNumber); // Log the new page
    dispatch(setCurrentPage(pageNumber));
    dispatch(fetchSammleanUsers(id, pageNumber, 10));
    localStorage.setItem("sammelanCurrentPage", pageNumber);
    
  };

  const calculateAge = (birthYear) => {
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear;
  };
  // const [users, setUsers] = useState([]);

  // const usersPerPage = 10
  // const currentPage = 1;

  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    const storedPage = localStorage.getItem("sammelanCurrentPage");
    const pageNumber = storedPage ? parseInt(storedPage) : 1;
  
    dispatch(fetchSammelan(id));
    dispatch(setCurrentPage(pageNumber));
    dispatch(fetchSammleanUsers(id, pageNumber, 10));
  
    if (!storedPage) {
      localStorage.setItem("sammelanCurrentPage", pageNumber);
    }
  }, [dispatch, id]);

  useEffect(() => {
    // console.log("sammelanUsers:", sammelanUsers);
    // Update total pages when sammelanUsers change
    if (sammelanUsers && pagination) {
      // console.log("Total Pages:", pagination.totalPages); // Log total pages
      setTotalPages(pagination.totalPages);
    }
  }, [sammelanUsers]);

  const handleViewProfile = async (targetId) => {
    try {
      const response = await authAxios.post(
        `/interactions/profile-view/${targetId}`
      );

      // Handle the response as needed
      // console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleShortlistClick = async (targetId) => {
    try {
      const response = await authAxios.patch(
        `/interactions/shortlist/${targetId}`,
        {}
      );

      // Check if API request was successful
      if (response.status === 200) {
        setIsOpenShortlist(true);
        setvalueShortlist(targetId);
        // Close the notification after 3 seconds
        setTimeout(() => {
          setIsOpenShortlist(false);
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInterestClick = async (targetId) => {
    try {
      const response = await authAxios.patch(
        `/interactions/interest/${targetId}`,
        {}
      );

      // Check if API request was successful
      if (response.status === 200) {
        setIsOpenInterest(true);
        setvalueInterest(targetId);

        setTimeout(() => {
          setIsOpenInterest(false);
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!currentUser) {
      getMe(setCurrentUser);
    }
  }, [currentUser]);

  if (currentUser?.length <= 0) return null;
  if (!sammelan) {
    return <div>Loading...</div>;
  }

  function TruncatedText({ text, maxLength }) {
    const [truncate, setTruncate] = useState(true);
    const shouldTruncate = text.length > maxLength;

    const toggleTruncate = () => {
      setTruncate(!truncate);
    };

    // console.log('reducer', sammelanUsers)

    return (
      <div>
        <p>
          {truncate ? text.slice(0, maxLength) : text}
          {shouldTruncate && (
            <span
              onClick={toggleTruncate}
              style={{ cursor: "pointer", color: "black" }}
            >
              {truncate ? (
                <>
                  ...
                  <MdExpandMore size={20} />{" "}
                </>
              ) : (
                <>
                  <MdExpandLess size={20} />
                </>
              )}
            </span>
          )}
        </p>
      </div>
    );
  }

  // const handlePageChange = (pageNumber) => {
  //   if (isAuthenticated) {
  //     setCurrentPage(pageNumber);
  //     // Fetch users for the new page
  //     fetchUsers();
  //     window.scrollTo(0, 0); // Scroll to the top of the page
  //   } else {
  //     navigate("/userLogin");
  //   }
  // };

  const renderPagination = () => {
    const pagesToShow = 5;
    const halfPagesToShow = Math.floor(pagesToShow / 2);
    const startPage = Math.max(1, currentPage - halfPagesToShow);
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    return (
      <div className="pagination">
        <button
          className="load-more-button"
          onClick={() => pageChangeHandler(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>

        {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
          <span
            key={startPage + index}
            className={currentPage === startPage + index ? "active" : ""}
            onClick={() => pageChangeHandler(startPage + index)}
          >
            {startPage + index}
          </span>
        ))}

        <button
          className="load-more-button"
          onClick={() => pageChangeHandler(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    );
  };

  const sortedSammelanUsers = [...sammelanUsers].sort((a, b) => {
    // Extract last 5 digits from OLAKH IDs
    const getIdSuffix = (user) => user.userName.slice(-5);

    const idA = getIdSuffix(a);
    const idB = getIdSuffix(b);

    return idA - idB; // Compare the last 5 digits numerically
  });

  return (
    <>
      <div
        className="sammelan-user__heading"
        style={{ textAlign: "center", padding: "1rem", fontSize: "1.5rem" }}
      >
        {sammelan.name}
      </div>

      <div className="sammelan-user__user-card">
        {/* <h2 className="sammelan-user__heading">MULTIPLE USERS' DATA</h2> */}
        <div className="sammelan-user__user-data">
          {sortedSammelanUsers?.map((user) => (
            <div key={user._id} className="sammelan-user__user">
              <div
                style={{ border: "1px solid black" }}
                className="sammelan-user__user-image"
              >
                <img
                  src={`${process.env.REACT_APP_SERVER_URL}${user.profile.avatar}`}
                  alt=""
                />
              </div>
              <div className="sammelan-user__user-content">
                <div>
                  {/* <h3 className="sammelan-user__user-name">
                    {user.firstName + " " + user.surname}
                  </h3> */}
                  <h3 className="sammelan-user__user-name">
                    Member ID: <span>{user.userName}</span>
                  </h3>
                </div>

                <div className="sammelan-user__user-content__details-box">
                  <div className="sammelan-user__user-content__details-box-column">
                    {isAuthenticated && (
                      <>
                        {" "}
                        <div className="sammelan-user__user-content__detail  ">
                          <div className="sammelan-user__user-content__age-label">
                            <h3
                              style={{
                                fontWeight: "bolder",
                                color: "#444",
                                marginRight: "4px",
                              }}
                            >
                              {" "}
                              Name:{" "}
                            </h3>
                          </div>
                          <div className="sammelan-user__user-content__age-value">
                            <h3 style={{ fontWeight: "bolder", color: "#444" }}>
                              {" "}
                              {`${user.profile?.prefix} ${user.firstName} ${user.surname}`}{" "}
                            </h3>
                          </div>
                        </div>{" "}
                      </>
                    )}

                    <div className="sammelan-user__user-content__detail  ">
                      <div className="sammelan-user__user-content__age-label">
                        <strong> Age: </strong>
                      </div>
                      <div className="sammelan-user__user-content__age-value">
                        <p>{calculateAge(user.profile.DobYear)} Years </p>
                      </div>
                    </div>

                    <div className="sammelan-user__user-content__detail">
                      <div className="sammelan-user__user-content__mangal-label">
                        <strong>Mangal: </strong>
                      </div>
                      <div className="sammelan-user__user-content__mangal-value">
                        <p>{user.profile.mangalik}</p>
                      </div>
                    </div>

                    {/* <div className="sammelan-user__user-content__detail">
                      <div className="sammelan-user__user-content__email-label">
                        <strong>Email: </strong>
                      </div>
                      <div className="sammelan-user__user-content__email-value">
                        <p>{user.email ? user.email : "N/A"} </p>
                      </div>
                    </div> */}

                    <div className="sammelan-user__user-content__detail">
                      <div className="sammelan-user__user-content__caste-label">
                        <strong>Caste: </strong>
                      </div>
                      <div className="sammelan-user__user-content__caste-value">
                        <p>{user.profile.caste} </p>
                      </div>
                    </div>

                    <div className="sammelan-user__user-content__detail">
                      <div className="sammelan-user__user-content__martial-status-label">
                        <strong>Martial Status: </strong>
                      </div>
                      <div className="sammelan-user__user-content__martial-status-value">
                        <p> {user.profile.maritialStatus} </p>
                      </div>
                    </div>

                    <div className="sammelan-user__user-content__detail">
                      <div className="sammelan-user__user-content__DOB-label">
                        <strong>Date Of Birth: </strong>
                      </div>
                      <div className="sammelan-user__user-content__Dob-value">
                        <p>
                          {user.profile.DobDay +
                            "-" +
                            user.profile.DobMonth +
                            "-" +
                            user.profile.DobYear}{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sammelan-user__user-content__details-box-column">
                    <div className="sammelan-user__user-content__detail">
                      <div className="sammelan-user__user-content__complexion-label">
                        <strong>Complexion: </strong>
                      </div>
                      <div className="sammelan-user__user-content__complexion-value">
                        <p>{user.profile.complexion} </p>
                      </div>
                    </div>

                    <div className="sammelan-user__user-content__detail">
                      <div className="sammelan-user__user-content__height-label">
                        <strong>Height: </strong>
                      </div>
                      <div className="search-result__user-content__height-value">
                        <p>{user.profile.height}</p>
                      </div>
                    </div>

                    <div className="sammelan-user__user-content__detail">
                      <div className="sammelan-user__user-content__city-label">
                        <strong>City: </strong>
                      </div>
                      <div className="sammelan-user__user-content__city-value">
                        <p>{user.profile.city} </p>
                      </div>
                    </div>

                    <div className="sammelan-user__user-content__detail">
                      <div
                        className="sammelan-user__user-content__education-label"
                        style={{ marginRight: "5px" }}
                      >
                        <strong>Education: </strong>
                      </div>
                      <div
                        className="sammelan-user__user-content__education-value"
                        style={{ maxWidth: "210px" }}
                      >
                        <TruncatedText
                          text={`${user.profile.qualification}`}
                          maxLength={20}
                        />
                      </div>
                    </div>

                    <div className="sammelan-user__user-content__detail">
                      <div
                        className="sammelan-user__user-content__job-title-label"
                        style={{ marginRight: "5px" }}
                      >
                        <strong>{`Job Title:`}</strong>
                      </div>
                      <div className="sammelan-user__user-content__job-title-value">
                        <TruncatedText
                          text={`${user.profile.completeOccupationDetails}`}
                          maxLength={20}
                        />
                      </div>
                    </div>
                    <div className="sammelan-user__user-content__detail">
                      <div
                        className="sammelan-user__user-content__job-title-label"
                        style={{ marginRight: "5px" }}
                      >
                        <strong>{`Annual Income:`}</strong>
                      </div>
                      {user?.profile.annualIncomeExample ? (
                        <div className="sammelan-user__user-content__job-title-value">
                          <TruncatedText
                            text={`${user.profile.annualIncomeExample}`}
                            maxLength={20}
                          />
                        </div>
                      ) : (
                        <div>N/A</div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Interaction buttons start */}
                <div className="sammelan-user__user-interaction-container">
                  <Link
                    to={
                      isAuthenticated
                        ? `/user-profile/${user._id}`
                        : "/userLogin"
                    }
                    onClick={
                      isAuthenticated
                        ? () => handleViewProfile(user._id)
                        : undefined
                    }
                  >
                    <div className="sammelan-user__user-interaction-box">
                      <FaUserCircle className="sammelan-user__user-interaction-box__view-full-profile-icon" />
                      <p>View Full Profile</p>
                    </div>
                  </Link>

                  <div
                    className="sammelan-user__user-interaction-box"
                    onClick={() => handleInterestClick(user._id)}
                  >
                    <AiFillHeart className="sammelan-user__user-interaction-box__interest-icon" />
                    <p>Interest</p>

                    {isOpenInterest && user._id === valueInterest && (
                      <div className="notification">
                        <p>{`You have shown interest in ${
                          user.firstName + user.surname
                        }`}</p>
                      </div>
                    )}
                  </div>

                  <div
                    className="sammelan-user__user-interaction-box"
                    onClick={() =>
                      navigate(`/dashboard/singleChat/${user._id}`)
                    }
                  >
                    <MessageIcon
                      fontSize="small"
                      className="sammelan-user__user-interaction-box__interest-icon message-icon"
                    />
                    <p>Message</p>
                  </div>

                  <div className="sammelan-user__user-interaction-box">
                    <div>
                      <AiOutlineStar
                        className="sammelan-user__user-interaction-box__shortlist-icon"
                        onClick={() => handleShortlistClick(user._id)}
                      />
                      <p>Shortlist</p>

                      {isOpenShortlist && user._id === valueShortlist && (
                        <div className="notification">
                          <p>{`You have shortlisted ${
                            user.firstName + user.surname
                          }`}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Interaction buttons end */}
              </div>
            </div>
          ))}
        </div>
      </div>

      {renderPagination()}
    </>
  );
};

export default SammelanUser;
