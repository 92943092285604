import React, { useState, useEffect } from "react";
import "./Chats.css";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import MessageIcon from "@material-ui/icons/Message";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteParticipant, getAllChat } from "../../redux/actions";

const useStyles = makeStyles({
  table: {
    border: "none",
    "& th": {
      border: "none",
      fontWeight: "bold",
    },
  },
  avatar: {
    display: "inline-flex",
    marginRight: "1rem",
  },
});

const Chats = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useStyles();

  const handleDelete = (userId) => {
    // Perform delete operation using the userId
    dispatch(deleteParticipant(userId));
  };

  const { myAllParticipants } = useSelector((state) => state.Chat);
  // console.log(currentUser?._id)
  useEffect(() => {
    if (myAllParticipants.length <= 0) {
      dispatch(getAllChat());
    }
  }, [dispatch, myAllParticipants]);

  return (
   <><h2>Chats</h2>
    <TableContainer> 
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>S.N</TableCell>
            <TableCell>Avatar</TableCell>
            <TableCell>Name</TableCell>
            {/* <TableCell>Age</TableCell> */}
            <TableCell>Message</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {typeof myAllParticipants !== "string" &&
          myAllParticipants.length > 0 ? (
            myAllParticipants.map((user, index) => (
              <TableRow key={user.idOfParticipant}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <Link to={`/user-profile/${user.idOfParticipant}`}>
                    <Avatar
                      className={classes.avatar}
                      alt={user.nameOfParticipant}
                      src={
                        process.env.REACT_APP_SERVER_URL +
                        user.avatarOfParticipant
                      }
                    />
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/user-profile/${user.idOfParticipant}`}>
                    {user.nameOfParticipant}
                  </Link>
                </TableCell>
                <TableCell>{user.ageOfParticipant}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() =>
                      navigate(`/dashboard/singleChat/${user.idOfParticipant}`)
                    }
                  >
                    <MessageIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleDelete(user.idOfParticipant)}
                  >
                    <DeleteIcon style={{ color: "red" }} />
                  </IconButton>
                  <span>Delete</span>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6}>No Chats Found</TableCell>
            </TableRow>
          )}
          {/* Render dummy row */}
          {/* <TableRow key={dummyRow._id}>
            <TableCell>--</TableCell>
            <TableCell>
              <Avatar
                className={classes.avatar}
                alt={dummyRow.firstName}
                src={process.env.REACT_APP_SERVER_URL + dummyRow.profile.avatar}
              />
            </TableCell>
            <TableCell>{dummyRow.firstName}</TableCell>
            <TableCell>{dummyRow.profile.age}</TableCell>
            <TableCell>
              <Link to="/dashboard/singlechat">
                <IconButton>
                  <MessageIcon />
                </IconButton>
              </Link>
            </TableCell>
            <TableCell>
              <IconButton onClick={() => handleDelete(dummyRow._id)}>
                <DeleteIcon style={{ color: "red" }} />
              </IconButton>
              <span>Delete</span>
            </TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
};

export default Chats;
