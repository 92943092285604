import {
  USER_LOGIN_GOOD,
  USER_LOGIN_BAD,
  LOGOUT_SUCCESS,
  GET_ME_SUCCESS,
  GET_ME_FAIL,
  PROFILE_PICTURE_UPDATE_SUCCESS,
  PROFILE_PICTURE_UPDATE_fail,
  GET_SINGLE_USER,
  FETCH_SAMMELAN_SUCCESS,
  FETCH_SAMMELAN_FAILURE,
  FETCH_SAMMLEN_USERS,
  SET_CURRENT_PAGE
} from "./types";

const initialState = {
  error: "",
  s: null,
  token: localStorage.token || null,
  isAuthenticated: localStorage.token ? true : false,
  isStandard: false,
  singleUser: null,
  currentPage: 1,
  pagination: {
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    nextPage: null,
  },
};

const User = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_LOGIN_GOOD:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        currentUser: payload.user,
        token: payload.token,
        error: "",
        isAuthenticated: true,
        isStandard: payload.user.subscription.paidMembership === "Standard",
      };
    case USER_LOGIN_BAD:
      return { ...state, error: payload, isAuthenticated: false };
    case LOGOUT_SUCCESS:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        currentUser: null,
        isAuthenticated: false,
      };
    case GET_ME_SUCCESS:
      return {
        ...state,
        currentUser: payload,
        error: "",
        isStandard: payload.subscription.paidMembership === "Standard",
      };
    case GET_ME_FAIL:
      localStorage.removeItem("token");
      return {
        ...state,
        currentUser: null,
        token: null,
        isAuthenticated: false,
        isStandard: true,
      };
    case PROFILE_PICTURE_UPDATE_SUCCESS:
      return {
        ...state,
        error: "",
        success: "Profile picture Update request sent",
      };
    case GET_SINGLE_USER:
      return {
        ...state,
        singleUser: payload,
      };
    case FETCH_SAMMELAN_SUCCESS:
      return {
        ...state,
        sammelan: action.payload,
      };
    case FETCH_SAMMELAN_FAILURE:
      return {
        ...state,
        error : 'sammelan fetch failed',
        success: "",
      };
    case FETCH_SAMMLEN_USERS:
      return {
        ...state,
        
        sammelanUsers: {
          users: action.payload,
          pagination: action.pagination,
        },
      };
      case SET_CURRENT_PAGE:
        return {
          ...state,
          currentPage: action.payload,
        };

    default:
      return state;
  }
};

export default User;
