import React from "react";
import "./sidebar.css";
import { image } from "../../constants";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import {
  AiOutlineHistory,
  AiOutlineUnorderedList,
  AiOutlineHeart,
  AiOutlineMail,
} from "react-icons/ai";
import { RiGalleryLine } from "react-icons/ri";
import { BiBlock, BiEdit } from "react-icons/bi";
import { HiOutlineTicket } from "react-icons/hi";
import { RxAvatar, RxExit } from "react-icons/rx";
import { BsKey, BsChatDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout, profilePictureUpdate } from "../../redux/actions";

const Sidebar = () => {
  const { currentUser } = useSelector((state) => state.User);
  // const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();

  const changeProfilePicture = (event) => {
    const files = event.target.files;
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append(
      "data",
      JSON.stringify({
        gender: currentUser.profile?.gender,
        avatar: currentUser.approvals?.profileImage || null,
      })
    );
    dispatch(profilePictureUpdate(formData))
      .then(() => {
        formData.delete("file");
        formData.delete("data");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (!currentUser) return;
  return (
    <>
      <div className="sidebar">

        <div className="profile-avatar-info">
          <div className="image-container">
            {currentUser.profile.avatar ? (
              <img
                src={
                  `${process.env.REACT_APP_SERVER_URL}${currentUser.profile.avatar}`
                }
                alt={currentUser?.firstName + " " + currentUser?.surname}
              />
            ) : (
              <img src={image.avatar} alt="" />
            )}
          </div>
          <h2>{currentUser?.firstName + " " + currentUser?.surname}</h2>
          <h5> ID : {currentUser?.userName}</h5>
          <Link to='/public-profile'>
          <button type="button" className="profile-avatar-info-btn">
            Your Profile
          </button>
          </Link>
        </div>
        <div className="overlay-button">
          <label htmlFor="changeProfileImg">
            <BiEdit
              size={25}
              style={{ cursor: "pointer", marginTop: "10px" }}
            />
          </label>
        </div>
        <input
          type="file"
          style={{ visibility: "hidden" }}
          id="changeProfileImg"
          accept=".jpg,.jpeg,.png"
          onChange={changeProfilePicture}
        />


        <div className="sidebar-tabs">
          <ul>
            <div className="hover-div">
              <Link to="/dashboard/info" style={{ textDecoration: "none" }}>
                {" "}
                <li>
                  <MdOutlineSpaceDashboard
                    size={16}
                    className="sidebar-tabs-icons"
                  />
                  Dashboard
                </li>
              </Link>
            </div>

            <div className="hover-div">
              <Link to="/search-olakhid" style={{ textDecoration: "none" }}>
                {" "}
                <li>
                  <RxAvatar
                    size={16}
                    className="sidebar-tabs-icons"
                  />
                  Search By OLAKH ID
                </li>
              </Link>
            </div>
            {/* <Link
              to="/dashboard/purchase-history"
              style={{ textDecoration: "none" }}
            >
              <li>
                <AiOutlineHistory size={16} className="sidebar-tabs-icons" />
                Purchase History
              </li>
            </Link> */}
            <Link to="/dashboard/gallery" style={{ textDecoration: "none" }}>
              <li>
                <RiGalleryLine size={16} className="sidebar-tabs-icons" />
                Gallery
              </li>
            </Link>

            <Link to="/dashboard/shortlist" style={{ textDecoration: "none" }}>
              <li>
                <AiOutlineUnorderedList
                  size={16}
                  className="sidebar-tabs-icons"
                />
                My Shortlist
              </li>
            </Link>

            <Link to="/dashboard/who-shortlisted-me" style={{ textDecoration: "none" }}>
              <li>
                <AiOutlineUnorderedList
                  size={16}
                  className="sidebar-tabs-icons"
                />
                Who Shortlisted Me
              </li>
            </Link>

            <Link
              to="/dashboard/my-interest"
              style={{ textDecoration: "none" }}
            >
              <li>
                <AiOutlineHeart size={16} className="sidebar-tabs-icons" />
                My Interest
              </li>
            </Link>

            <Link
              to="/dashboard/interest-received"
              style={{ textDecoration: "none" }}
            >
              <li>
                <AiOutlineHeart size={16} className="sidebar-tabs-icons" />
                Interest Received
              </li>
            </Link>
            <Link
              to="/dashboard/who-viewed-my-profile"
              style={{ textDecoration: "none" }}
            >
              <li>
                <AiOutlineHeart size={16} className="sidebar-tabs-icons" />
               Who Visited My Profile
              </li>
            </Link>
            <Link
              to="/dashboard/my-profile-views"
              style={{ textDecoration: "none" }}
            >
              <li>
                <AiOutlineHeart size={16} className="sidebar-tabs-icons" />
                My Visited Profiles
              </li>
            </Link>

            {/* <Link
              to="/dashboard/ignored-lists"
              style={{ textDecoration: "none" }}
            >
              <li>
                <BiBlock size={16} className="sidebar-tabs-icons" />
                Ignored Lists
              </li>
            </Link> */}
            {/* <Link to="/dashboard/message" style={{ textDecoration: "none" }}>
              <li>
                <AiOutlineMail size={16} className="sidebar-tabs-icons" />
                Message
              </li>
            </Link> */}
            {/* <Link
              to="/dashboard/support-ticket"
              style={{ textDecoration: "none" }}
            >
              <li>
                <HiOutlineTicket size={16} className="sidebar-tabs-icons" />
                Support Tickets
              </li>
            </Link> */}

            <Link
              to="/dashboard/profile-settings"
              style={{ textDecoration: "none" }}
            >
              <li>
                <RxAvatar size={16} className="sidebar-tabs-icons" />
                Profile Settings
              </li>
            </Link>

            <Link
              to="/dashboard/chats"
              style={{ textDecoration: "none" }}
            >
              <li>
                <BsChatDots size={16} className="sidebar-tabs-icons" />
                Chats
              </li>
            </Link>

            <Link
              to="/dashboard/change-password"
              style={{ textDecoration: "none" }}
            >
              <li>
                <BsChatDots size={16} className="sidebar-tabs-icons" />
                Change Password
              </li>
            </Link>
            {/* <Link
              to="/dashboard/change-password"
              style={{ textDecoration: "none" }}
            >
              <li>
                <BsKey size={16} className="sidebar-tabs-icons" />
                Change Password
              </li>
            </Link> */}
            <Link
              to="/home"
              style={{ textDecoration: "none" }}
              onClick={() => dispatch(logout())}
            >
              <li>
                <RxExit size={16} className="sidebar-tabs-icons" />
                Sign Out
              </li>{" "}
            </Link>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
