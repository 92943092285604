import { useEffect, useState } from "react";
import "./Membership.css"; 
import { useSelector } from "react-redux";
import { BsCheck } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

function Membership() {
  const { isAuthenticated } = useSelector((state) => state.User);
  const navigate = useNavigate();
  const [bankAccountInfo, setbankAccountInfo] = useState('');
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    fetchBankDetails();
    fetchpackage();
  }, []);


  const fetchpackage = async ()=>{
    axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/package/all-packages`)
    .then((response) => {
      setPackages(response.data);
      console.log(response.data)
    })
    .catch((error) => {
      console.error(error);
    });
  }

  const fetchBankDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/website-cms/`);
      //   console.log(response.data);

      if (response.status === 200) {
        const { bankAccountInfo } = response.data; // Correctly extract the refunds data
        setbankAccountInfo(bankAccountInfo); // Update the state variable
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toolbarOptions = {
    toolbar: false, // Hide the toolbar
  };
  return (
    <div className="Membership">
      <header className="centered">
        <h1 style={{ fontSize: "32px", color: "#444" }}>Membership Plan</h1>
      </header>
      <div className="grey-text centered">
        <p style={{ textAlign: "center", color: "#999", fontWeight: "300" }}>
          Our platform offers personalized packages for each user. You have the
          freedom to upgrade or purchase a package that suits your needs, all
          through our secure online payment system.
        </p>
      </div>
      <div className="container">
     <div className="row">
         { packages.map((pkg) => <div className="col-md-6">
            <div className="box" key={pkg._id}>
              <div
                className="box-header"
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #FD2C79, #ED0CBD)",
                }}
              >
                <h2>{pkg.name}</h2>
              </div>
              <div className="box-body">
                <h3 style={{ fontSize: "30px" }}>{pkg.price }</h3>
                <ul className="icon-list">
                {pkg.fields.map((field, index) => (
                <li key={index}> <BsCheck size={30} color="#FD2C79" />{field}</li>
              ))}
            
                </ul>
                <div className="centered">
                  {localStorage.getItem("firstName") ? (
                    <>
                      {!isAuthenticated && (
                        <div>
                          <a
                            href={pkg.priceButtonLink}
                            rel="im-checkout"
                            data-text="Pay Now"
                            data-css-style="color:#ffffff; background:#000000; width:180px; border-radius:4px"
                            data-layout="vertical"
                            style={{
                              width: "135px",
                              backgroundColor: "#0D1E29",
                              textAlign: "center",
                              fontWeight: 800,
                              padding: "11px 0px",
                              color: "white",
                              fontSize: "12px",
                              display: "inline-block",
                              textDecoration: "none",
                            }}
                          >
                            Pay Now
                          </a>
                          <script src="https://js.instamojo.com/v1/button.js"></script>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {!isAuthenticated && (
                        <button
                          className="singleFeaturedCard-button-gradient"
                          onClick={() => navigate("/register")}
                        >
                          Pay Now
                        </button>
                      )}
                    </>
                  )}
                </div>

              </div>
            </div>
          </div> )}
          
        </div> 
        
        <div>
          <h1 style={{ textAlign: 'center', margin: '1rem' }}>Bank Details</h1>
          <style>{`.ql-container.ql-snow.ql-disabled { border: none; }`}</style>
          <ReactQuill
            value={bankAccountInfo}
            readOnly
            theme="snow"
            modules={toolbarOptions}
          />
        </div>
      </div>
    </div>
  );
}

export default Membership;
