import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import './privacyPolicy.css';

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState('');

  useEffect(() => {
    fetchPrivacyPolicy();
  }, []);

  const fetchPrivacyPolicy = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/website-cms/`);
      if (response.status === 200) {
        const { privacyPolicy } = response.data;
        setPrivacyPolicy(privacyPolicy);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="terms-and-conditions">
        <h1 style={{ textAlign: 'center', margin: '1rem' }}>Privacy Policy</h1>
        <style>{`.ql-container.ql-snow.ql-disabled { border: none; }`}</style>

        <ReactQuill
          value={privacyPolicy}
          readOnly
          theme="snow"
          modules={{ toolbar: false }}
        />
      </div>
    </>
  );
};

export default PrivacyPolicy;
