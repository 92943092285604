const gotraData = [
  "Agasti",
  "Agatsya",
  "Agrayan",
  "Ahaabhay",
  "Ahbhun",
  "Alambayan",
  "Amrish",
  "Angiras",
  "Archinas",
  "Arshan",
  "Ashthirshen",
  "Ashtrishen",
  "Atri",
  "Attreya",
  "Aupanyav",
  "Avtsar",
  "Avyayan",
  "Ayasya",
  "Babhrav",
  "Babhravya",
  "Badrayan",
  "Badul",
  "Bahulas",
  "Balakhya",
  "Bandishti",
  "Bandul",
  "Bhardwaj",
  "Bhargav",
  "Boudhya",
  "Chandratra",
  "Chandratreya",
  "Chandratri",
  "Dadharya",
  "Dadharyachut",
  "Darbhas",
  "Dardvi",
  "Deval",
  "Devargha",
  "Devraj",
  "Devrat",
  "Devshravas",
  "Dhananjay",
  "Dharanya",
  "Dharikarn",
  "Dharini",
  "Dhumrayan",
  "Durvas",
  "Falgu",
  "Galav",
  "Garg",
  "Gargya",
  "Gobhil",
  "Goutam",
  "Grutsamad",
  "Haridra",
  "Harikaran",
  "Harikarn",
  "Haritas",
  "Hiranyaksh",
  "Jaimini",
  "Jamdagni",
  "Jamdagni Vats",
  "Jamdagnya",
  "Jashk",
  "Jatukarnya",
  "Jaymantayan",
  "Jaywant",
  "Jaywantayan",
  "Kamakayan",
  "Kamaksh",
  "Kamishtha",
  "Kamkayan",
  "Kansh",
  "Kapi",
  "Kapichhal",
  "Kapil",
  "Kapinjal",
  "Kapisth",
  "Karishay",
  "Karnik",
  "Karnishthal",
  "Karshi",
  "Karshya",
  "Kashyap",
  "Katyayan",
  "Kaushik",
  "Kondanya",
  "Kondinya",
  "Kontilya",
  "Koukhya",
  "Kounis",
  "Kousalya",
  "Koushik",
  "Krishnatra",
  "Krishnatray",
  "Kutchhat",
  "Kuts",
  "Lohit",
  "Lohitaksh",
  "Lougaksha",
  "Madhu",
  "Madhuchchand",
  "Madhur",
  "Maharsh",
  "Maharshan",
  "Mahatreya",
  "Mahulastra",
  "Maitrey",
  "Maitreya",
  "Mandavya",
  "Mandvya",
  "Markandeya",
  "Marshan",
  "Mathar",
  "Mitra",
  "Mitragun",
  "Monas",
  "Moujayan",
  "Moujmajya",
  "Moun",
  "Moun Bhargav",
  "Mounibhargav",
  "Mudgal",
  "Muk",
  "Mukund",
  "Munibhargav",
  "Naidhruv",
  "Nityundan",
  "Paingas",
  "Paingya",
  "Parashar",
  "Parthiv",
  "Patanjal",
  "Pingaksha",
  "Pinglayan",
  "Pisavar",
  "Polkutsa",
  "Poutmakhya",
  "Pracharas",
  "Pracharyah",
  "Prachi",
  "Prachin",
  "Prachinas",
  "Prachinyogi",
  "Prachya",
  "Prathamatray",
  "Prathmamitra",
  "Pratimaan",
  "Pratkoushik",
  "Pratnashya",
  "Punmala",
  "Rathitar",
  "Ratich",
  "Rishishrung",
  "Rohinya",
  "Rohinyayan",
  "Sahul",
  "Sahulmahul",
  "Saindhav",
  "Saindhvayan",
  "Sambhayan",
  "Sambhrayan",
  "Samrayan",
  "Sanang",
  "Sanatan",
  "Sankhyayan",
  "Sankrut",
  "Sankruti",
  "Sankrutya",
  "Sankrutyayan",
  "Satharmathar",
  "Savarn",
  "Savarni",
  "Savarnik",
  "Savarnya",
  "Shabashva",
  "Shahabaaj",
  "Shakalya",
  "Shaklayan",
  "Shalaksh",
  "Shalankayan",
  "Shalwat",
  "Shamras",
  "Shandilya",
  "Shardang",
  "Shath",
  "Shavarya",
  "Shavashv",
  "Shavasya",
  "Shavrayan",
  "Shayan",
  "Shounak",
  "Shrivats",
  "Shrungrishi",
  "Slankayan",
  "Soubhayan",
  "Soumyas",
  "Soundhrayan",
  "Suparn",
  "Survanasya",
  "Udalay",
  "Udmedh",
  "Udyalak",
  "Ullis",
  "Upmanyu",
  "Vadhul",
  "Vaghulya",
  "Vaidhruv",
  "Vaidurbh",
  "Vaishya",
  "Vainya",
  "Vaitakhya",
  "Vaiyagrah",
  "Vaiyagrahpaad",
  "Valkhilya",
  "Vamrathya",
  "Vanchhayan",
  "Vanjayan",
  "Vardhavya",
  "Vardhul",
  "Vardhyasva",
  "Vasishtha",
  "Vats",
  "Vatsayan",
  "Vidarbh",
  "Vishnuvraddha",
  "Vishwamitra",
  "Vyaghrapushpi",
  "Vyaghrpaad",
  "Yask",
  "Other",
];

const casteData = [
  "Arya Vaishya",
  "Bhawsar Kshtriya",
  "rahma Vaishya",
  "Brahmin",
  "Chandraseniya Kayastha Prabhu",
  "Dhangar",
  "Gomantkiya Vaishya",
  "Kokanastha Vaishya",
  "Kshtriya Maratha",
  "Kunabi",
  "Maratha 96 kuli",
  "Marathi Teli",
  "Narvekar Vaishya",
  "Somvanshi Kshatriya Kasar",
  "Somvanshiya Sahastrajun Kshtriya",
  "Sonar",
  "Other",
];

const brahminSubCasteData = [
  "Deshastha Rigvedi",
  "Padhye",
  "Kokanastha (Chitpavan)",
  "Devrukhe",
  "Shukla Yajurvedi",
  "Katyayan Yajurvedi",
  "Govardhan",
  "Karhade",
  "Daivadnya",
  "Gour Saraswat",
  "Somvedi Latyayan",
  "Deshastha Yajurvedi",
  "Deshastha",
  "ManavSutri",
];

const heightData = [
  "4Ft",
  "4Ft 1 inch",
  "4Ft 2 inch",
  "4Ft 3 inch",
  "4Ft 4 inch",
  "4Ft 5 inch",
  "4Ft 6 inch",
  "4Ft 7 inch",
  "4Ft 8 inch",
  "4Ft 9 inch",
  "4Ft 10 inch",
  "4Ft 11 inch",
  "5Ft",
  "5Ft 1 inch",
  "5Ft 2 inch",
  "5Ft 3 inch",
  "5Ft 4 inch",
  "5Ft 5 inch",
  "5Ft 6 inch",
  "5Ft 7 inch",
  "5Ft 8 inch",
  "5Ft 9 inch",
  "5Ft 10 inch",
  "5Ft 11 inch",
  "6Ft",
  "6Ft 1 inch",
  "6Ft 2 inch",
  "6Ft 3 inch",
  "6Ft 4 inch",
  "6Ft 5 inch",
  "6Ft 6 inch",
  "6Ft 7 inch",
  "6Ft 8 inch",
  "6Ft 9 inch",
  "6Ft 10 inch",
  "6Ft 11 inch",
  "7Ft ",
];

const maritalStatusData = [
  "Unmarried",
  "Divorced",
  "Widower",
  "Widowed",
  "Awaiting Divorce",
  "Separated",
];

const manglikData = ["Yes", "No", "Soumya", "Nirdosh","Don't Know"];

const rashiData = [
  "Mesh",
  "Vrushabh",
  "Mithun",
  "Kark",
  "Sinmh",
  "Kanya",
  "Tula",
  "Vrushchik",
  "Dhanu",
  "Makar",
  "Kumbh",
  "Meen",
];

const naadiData = ["Antya", "Aadya", "Madhya"];
const ganData = ["Dev", "Manushya", "Rakshas"];
const charanData = ["Pratham", "Dwitiya", "Trutiya", "Chaturth"];

const nakshatraData = [
  "Ashvini",
  "Bharani",
  "Kruttika",
  "Rohini",
  "Mrugashira",
  "Ardra",
  "Mrug",
  "Punarvasu",
  "Pushya",
  "Ashlesha",
  "Magha",
  "Purva Phalguni",
  "Uttara Phalguni",
  "Hasta",
  "Chitra",
  "Swati",
  "Vishakha",
  "Anuradha",
  "Jyeshtha",
  "Mula",
  "Purva Ashadha",
  "Uttara Ashadha",
  "Shravan",
  "Ghanishta",
  "Shatabhisha",
  "Purva Bhadrapada",
  "Uttara Bhadrapada",
  "Revati",
  "Shatataraka",
  "Uttara",
];

const complexionData = ["Fair", "Wheatish", "Wheatish Brown", "Dark"];

const bodyTypeData = ["Slim", "Average", "Heavy", "Athletic", "Healthy"];

const birthMonthData = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "October",
  "November",
  "December",
];

export {
  gotraData,
  casteData,
  brahminSubCasteData,
  heightData,
  maritalStatusData,
  manglikData,
  rashiData,
  naadiData,
  ganData,
  charanData,
  nakshatraData,
  complexionData,
  bodyTypeData,
  birthMonthData,
};
