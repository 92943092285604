import { combineReducers } from "redux";
import Search from "./Search/reducers";
import User from "./User/reducers";
import Chat from "./Chat/reducers";
import sidebarReducer from "./sidebar/reducer";

export default combineReducers({
  Search,
  User,
  Chat,
  sidebarReducer
});
