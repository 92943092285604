import React, { useState, useEffect } from "react";
// import "./WhoShortlistedMe.css";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
// import MessageIcon from "@material-ui/icons/Message";
import axios from "../../axios";
import { Link } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination"; // Import TablePagination

const useStyles = makeStyles({
  table: {
    border: "none",
    "& th": {
      border: "none",
      fontWeight: "bold",
    },
  },
  avatar: {
    display: "inline-flex",
    marginRight: "1rem",
  },
});

const InterestReceived = () => {
  const [interestedInMe, setInterestedInMe] = useState([]);
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page

  useEffect(() => {
    axios
      .get("/interactions/interestedinme/:id")
      .then((response) => {
        setInterestedInMe(response.data);
        console.log(response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const calculateAge = (birthYear) => {
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear;
  };

  const handleDelete = (userId) => {
    axios
      .post(`/interactions/interestedinme_delete/${userId}`)
      .then((response) => {
        console.log("User deleted successfully");
        window.location.reload();
        // Perform any additional actions after deletion
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
        // Handle any error that occurred during deletion
      });
  };

  const classes = useStyles;

  // Calculate the index range for the currently visible items
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  return (
    <div>
                <h2>Interest Received</h2>

      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Olakh Id</TableCell>
              <TableCell>Avatar</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {interestedInMe.slice(startIndex, endIndex).map((user, index) => (
              <TableRow key={user._id}>
                {/* <TableCell>{startIndex + index + 1}</TableCell> */}
                <TableCell> {user?.userName.match(/\d+/)}</TableCell>

                <TableCell>
                  <Link to={`/user-profile/${user._id}`}>
                    <Avatar
                      className={classes.avatar}
                      alt={user.firstName || ""}
                      src={process.env.REACT_APP_SERVER_URL + user.profile?.avatar}
                    />
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/user-profile/${user._id}`}>
                    {user.firstName}
                  </Link>
                </TableCell>
                {/* <TableCell>
                  {user.profile && user.profile.dateOfBirth?.year ? (
                    calculateAge(user.profile.dateOfBirth.year)
                  ) : (
                    "N/A"
                  )}
                </TableCell> */}
                {/* calculateAge(user.profile.DobYear) */}
                <TableCell>
                  {user.profile && calculateAge(user.profile.DobYear) ? (
                    calculateAge(user.profile.DobYear)
                  ) : (
                    "N/A"
                  )}
                </TableCell>
                <TableCell>
                  <Link to="#" onClick={() => handleDelete(user._id)}>
                    <IconButton>
                      <DeleteIcon style={{ color: "red" }} />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={interestedInMe.length} // Total number of items
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={(event, newPage) => setPage(newPage)} // Handle page change
        onChangeRowsPerPage={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0); // Reset the page when the number of rows per page changes
        }}
      />
    </div>
  );
};

export default InterestReceived;
