import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './singlefeaturedprofile.css';
import { Link } from 'react-router-dom';
import authAxios from '../../axios';

const SingleFeaturedProfile = ({ cardimg, olakhId, age, height, userid, gender }) => {
  const { isAuthenticated } = useSelector((state) => state.User);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [buttonText, setButtonText] = useState('View Profile')

  const getMe = async () => {
    try {
      const res = await authAxios.get(
        process.env.REACT_APP_ADMIN_BASE_URL_SERVER + '/user/me'
      );
      setCurrentUser(res.data.user);

    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (!currentUser) {
      getMe();
    }
  }, [currentUser]);

  // if (!currentUser) return null;



  
  const handleClick = () => {
    // Change the button text to "Not Allowed to View this"
    setButtonText('Not Allowed to View this');

    // Set a timeout to change it back to "View Profile" after a few seconds
    setTimeout(() => {
      setButtonText('View Profile');
    }, 3000); // Change back after 3 seconds (adjust the delay as needed)
  };
  return (
    <div className='singleFeaturedCard'>
      <img src={cardimg} alt='' />

      <div className='singleFeaturedCard-row'>
        <div className='singleFeaturedCard-col'>
          <strong>Olakh ID</strong>
        </div>
        <div className='singleFeaturedCard-col'>
          <p>{olakhId}</p>
        </div>
      </div>
      <div className='singleFeaturedCard-row'>
        <div className='singleFeaturedCard-col'>
          <strong>Age</strong>
        </div>
        <div className='singleFeaturedCard-col'>
          <p>{age}</p>
        </div>
      </div>
      <div className='singleFeaturedCard-row'>
        <div className='singleFeaturedCard-col'>
          <strong>Height</strong>
        </div>
        <div className='singleFeaturedCard-col'>
          <p>{height}</p>
        </div>
      </div>
      <div className='singleFeaturedCard-row' style={{ display: 'none' }}>
        <div className='singleFeaturedCard-col'>
          <strong>Gender</strong>
        </div>
        <div className='singleFeaturedCard-col'>
          <p>{gender}</p>
        </div>
      </div>

      {/* <div className='singleFeaturedCard-button'>
        {isAuthenticated && currentUser ? (
          <Link to={`/user-profile/${userid}`}>
            <button className='singleFeaturedCard-button-gradient'>View Profile</button>
          </Link>
        ) : (
          <>
      
          <button
            className='singleFeaturedCard-button-gradient'
            onClick={() => navigate('/userLogin')}
          >
            View Profile
          </button>
          </>
        )}
      </div> */}

      <div className='singleFeaturedCard-button'>
        {isAuthenticated && currentUser && (
          ((currentUser.profile.gender === 'Male' && gender === 'Female') ||
            (currentUser.profile.gender === 'Female' && gender === 'Male')) ? (
            <Link to={`/user-profile/${userid}`}>
              <button className='singleFeaturedCard-button-gradient'>View Profile</button>
            </Link>
          ) : (

            <>
              {console.log("currentUser.profile.gender:", currentUser.profile.gender)}

              <button
                className='singleFeaturedCard-button-gradient'
                onClick={handleClick}
              >
                {buttonText}
                
              </button>
            </>

          )
        )}
      </div>

    </div>
  );
};

export default SingleFeaturedProfile;
