const initialState = {
  searchResponse: [],
  shortlistedUsers: [],
  error: "",
  success: "",
  pagination: {
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    nextPage: null,
  },
};

const Search = (state = initialState, actions) => {
  const { type, payload } = actions;

  switch (type) {
    case "SEARCH_WAS_SUCCESSFUL":
      // return { ...state, searchResponse: payload ,  pagination: actions.pagination, };
      return {
        ...state,
        searchResponse: payload,
        pagination: actions.payload.pagination,
      };

    case "SHORTLIST_USER":
      const updatedShortlistedUsers = [...state.shortlistedUsers, payload];
      return { ...state, shortlistedUsers: updatedShortlistedUsers };

    case "REMOVE_SHORTLISTED_USER":
      const filteredShortlistedUsers = state.shortlistedUsers.filter(user => user.id !== payload);
      return { ...state, shortlistedUsers: filteredShortlistedUsers };

    default:
      return state;
  }
};

export default Search;
