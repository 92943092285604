import React, { useState, useEffect } from 'react';
import './faq.css';
import axios from 'axios';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [faqs, setFaqs] = useState(null);

  const fetchFaqs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/website-cms`);

      if (response.status === 200) {
        const { faqs } = response.data;
        setFaqs(faqs); 
        console.log(faqs)
      } else {
        console.log('Error:', response.data);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  const handleAccordionClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <>
      <div className="faq-big-container">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-container">
          <div className="faq-column">
            {faqs &&
              faqs.slice(0, Math.ceil(faqs.length / 2)).map((faq, index) => (
                <div className="accordion-container" key={faq.id}>
                  <div
                    className={`accordion-header ${activeIndex === index ? 'active' : ''}`}
                    onClick={() => handleAccordionClick(index)}
                  >
                    <div className="accordion-question">{faq.question}</div>
                    <div className="accordion-icon">{activeIndex === index ? '-' : '+'}</div>
                  </div>
                  {activeIndex === index && (
                    <div className="accordion-content">
                      <p>{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className="faq-column">
            {faqs &&
              faqs.slice(Math.ceil(faqs.length / 2)).map((faq, index) => (
                <div className="accordion-container" key={faq.id}>
                  <div
                    className={`accordion-header ${activeIndex === index ? 'active' : ''}`}
                    onClick={() => handleAccordionClick(index + Math.ceil(faqs.length / 2))}
                  >
                    <div className="accordion-question">{faq.question}</div>
                    <div className="accordion-icon">
                      {activeIndex === index + Math.ceil(faqs.length / 2) ? '-' : '+'}
                    </div>
                  </div>
                  {activeIndex === index + Math.ceil(faqs.length / 2) && (
                    <div className="accordion-content">
                      <p>{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
