import React, { useState } from 'react'
import './storycards.css'

const Storycards = ({ storyImage, storyTitle, storyText }) => {
  const [showMore, setShowMore] = useState(false);

  const truncatedText = (storyText ?? '').split(" ").slice(0, 10).join(" ");


  return (
    <>
      <div className="story-card">
        <div className="story-image">
          <img src={storyImage} alt="" />
        </div>
        <div className="story-title">
          {/* <h3>{storyTitle}</h3> */}
        </div>
        {/* <div className="story-content">
          {showMore ? storyText : truncatedText + "... "}
          <button onClick={() => setShowMore(!showMore)}>
            {showMore ? "Read less" : "Read more"}
          </button>
        </div> */}
      </div>
    </>
  )
}

export default Storycards
