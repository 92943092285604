import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import "./SingleChat.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import {
  getSingleChat,
  getSingleUser,
  sendMessage,
  getAllChat,
} from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const SingleChat = () => {
  // const [messages, setMessages] = useState([
  //   { id: 1, content: "Hello", sender: "other" },
  //   { id: 2, content: "Hi, how are you?", sender: "user" },
  //   { id: 3, content: "I'm good. What about you?", sender: "other" },
  //   { id: 4, content: "I am doing great!", sender: "user" },
  //   { id: 5, content: "That sounds awesome!", sender: "other" },
  // ]);
  const [messageContent, setMessageContent] = useState("");
  // const [allChat, setAllChat] = useState([]);
  const { singleChat, myAllParticipants, chatId } = useSelector(
    (state) => state.Chat
  );
  const { singleUser, currentUser } = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const params = useParams();

  const handleSubmit = (event) => {
    event.preventDefault();
    const inputDate = `${currentUser.profile.DobDay} ${currentUser.profile.DobMonth} ${currentUser.profile.DobYear}`;

    // Parse the input date string using Moment.js
    const birthDate = moment(inputDate, "D MMMM YYYY");

    // Calculate the difference between the current date and the birth date
    const now = moment();
    const ageDuration = moment.duration(now.diff(birthDate));

    // Extract the years from the duration
    const userAge = ageDuration.years();
    if (messageContent !== "") {
      const newMessage = {
        content: messageContent,
        name: currentUser.firstName + " " + currentUser.surname,
        sender: currentUser._id,
        age: userAge,
        avatar: currentUser.profile.avatar,
      };

      dispatch(sendMessage(newMessage, params.id));
      // setAllChat([...allChat, newMessage]);

      setMessageContent("");
    }
  };
  // console.log(singleChat);
  useEffect(() => {
    dispatch(getSingleUser(params.id));

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getSingleChat(params.id));

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (myAllParticipants.length <= 0) {
      dispatch(getAllChat());
    }
  }, [dispatch, myAllParticipants]);

  useEffect(() => {
    const time = setTimeout(() => {
      dispatch(getSingleChat(params.id));
    }, 8000);
    return () => clearTimeout(time);
  }, [dispatch, params, singleChat]);

  if (!singleUser) return <CircularProgress />;

  return (
    <div className="single-chat">
      <div className="single-chat__header">
        <Avatar
          className="single-chat__avatar"
          alt={singleUser.firstName}
          src={process.env.REACT_APP_SERVER_URL + singleUser.profile.avatar}
        />
        <h2 className="single-chat__user">
          {singleUser.firstName + " " + singleUser.surname}
        </h2>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
        }}
      >
        <div
          className="single-chat__messages"
          style={{ height: "max-content", maxHeight: "400px", overflow: "auto" }}
          >
          {singleChat?.map((message, index) => (
            <div
              key={index + 6}
              className={`single-chat__message-bubble ${
                message.sender === singleUser._id.toString()
                  ? "single-chat__message-bubble--other"
                  : "single-chat__message-bubble--user"
              }`}
            >
              <div className="single-chat__message-content">
                {message.content}
              </div>
            </div>
          ))}
        </div>
        <form className="single-chat__form" onSubmit={handleSubmit}>
          <TextField
            name="message"
            className="single-chat__input"
            variant="outlined"
            label="Message"
            fullWidth
            multiline
            value={messageContent}
            onChange={(e) => setMessageContent(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="single-chat__button"
          >
            Send
          </Button>
        </form>
      </div>
    </div>
  );
};

export default SingleChat;
