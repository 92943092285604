import {
  SEND_CONTENT_SUCCESS,
  SEND_CONTENT_FAIL,
  RECEIVE_CONTENT_SUCCESS,
  RECEIVE_CONTENT_FAIL,
  GET_MY_PARTICIPANTS,
  GET_SINGLE_CHAT,
} from "./types";

const initialState = {
  error: "",
  sent: "",
  singleChat: [],
  chatId: "",
  multiChat: [],
  myAllParticipants: [],
};

const Chat = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SINGLE_CHAT:
      return {
        ...state,
        singleChat: payload.messages,
        chatId: payload.chatId,
      };
    case GET_MY_PARTICIPANTS:
      return { ...state, myAllParticipants: payload };
    case SEND_CONTENT_SUCCESS:
      return {
        ...state,
        singleChat: payload.messages,
        chatId: payload.chatId,
      };
    case SEND_CONTENT_FAIL:
      return { ...state, sent: "", error: "Cannot send message" };
    case RECEIVE_CONTENT_SUCCESS:
      return {
        ...state,
        singleChat: payload.messages,
        chatId: payload.chatId,
      };
    case RECEIVE_CONTENT_FAIL:
      return { ...state, singleChat: [], error: "Cannot receive message" };
    default:
      return state;
  }
};

export default Chat;
