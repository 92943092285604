import { React, useState, useEffect } from "react";
import axios from "axios";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./About.css";
import { image } from "../../constants";

const About = () => {
  const [aboutUs, setAboutUs] = useState(null);
  useEffect(() => {
    fetchAboutData();
  }, []);
  const fetchAboutData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/website-cms`);

      if (response.status === 200) {
        const { aboutUs } = response.data;
        setAboutUs(aboutUs);
    //    console.log(aboutUs)
      } else {
        console.log("Error:", response.data.msg);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <>
      <style>{`.ql-container.ql-snow.ql-disabled { border: none; }`}</style>
      <section className="section1">
        <div className="right-column">
          <img
            className="image"
            src={image.aboutUsImage}
            alt="About Us Image"
          />
        </div>
        <div className="left-column">
          <h1 className="secondary-heading"> About Olakh</h1>
          <ReactQuill
            value={aboutUs?.aboutOlakh}
            readOnly
            theme="snow"
            modules={{ toolbar: false }}
          />
        </div>
      </section>

      {/* <section className="section2">
        <div className="right-column">
          <img
            className="image"
            src={image.weddingRingCeremony}
            alt="About Us Image"
          />
        </div>
        <div className="left-column">
          <h1 className="secondary-heading"> Our Mission</h1>
          <ReactQuill
            value={aboutUs?.ourMission}
            readOnly
            theme="snow"
            modules={{ toolbar: false }}
          />
        </div>
      </section>

      <section className="section3">
        <div className="right-column">
          <img className="image" src={image.ourApproach} alt="About Us Image" />
        </div>
        <div className="left-column">
          <h1 className="secondary-heading"> Our Approach</h1>
          <ReactQuill
            value={aboutUs?.ourApproach}
            readOnly
            theme="snow"
            modules={{ toolbar: false }}
          />
        </div>
      </section>

      <section className="section4">
        <div className="right-column">
          <img
            className="image"
            src={image.ourCommunity}
            alt="About Us Image"
          />
        </div>
        <div className="left-column">
          <h1 className="secondary-heading"> Join Our Community</h1>
          <ReactQuill
            value={aboutUs?.joinOurCommunity}
            readOnly
            theme="snow"
            modules={{ toolbar: false }}
          />
        </div>
      </section> */}
    </>
  );
};

export default About;
