import React, { useState, useEffect } from "react";
import "./Contact.css";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import axios from "axios";

const Contact = () => {
  const [contactUs, setContactUs] = useState(null);

  useEffect(() => {
    fetchContactus();
  }, []);
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    subject: "",
    message: "",
  });

  const setData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/user/contact`, formData);
      alert("Message sent!");
      // Reset the form after successful submission
      setFormData({
        name: "",
        number: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      console.error(error);
      alert("An error occurred. Please try again.");
    }
  };


  const fetchContactus = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/website-cms`)
      if (response.status === 200) {
        const { contactUs } = response.data;
        setContactUs(contactUs);
        console.log(contactUs)
      } else {
        console.log("Error:", response.msg);
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="contact-icon-box">
            <div className="icon">
              <BsFillTelephoneFill />
            </div>
            <div className="icon-box-content">
              <h3>Phone Number</h3>
              <p>
                {contactUs?.phone1} <br />
                {contactUs?.phone2} <br />

              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="contact-icon-box">
            <div className="icon">
              <FaEnvelope />
            </div>
            <div className="icon-box-content">
              <h3>Email Address</h3>
              <p>{contactUs?.email}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="contact-icon-box">
            <div className="icon">
              <FaMapMarkerAlt />
            </div>
            <div className="icon-box-content">
              <h3>Address</h3>
              <p>
                {contactUs?.address}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="contact-form">
            <h2>Any Queries!
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={setData}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="WhatsApp Number"
                  name="number"
                  value={formData.number}
                  onChange={setData}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                  name="subject"
                  value={formData.subject}
                  onChange={setData}
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Message"
                  rows="5"
                  name="message"
                  value={formData.message}
                  onChange={setData}
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">
                Send Message
              </button>
            </form>
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <iframe
              title="Google Map"
              width="100%"
              height="500"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.6662259382047!2d75.86522261489908!3d22.703465885115573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd45ef644bf1%3A0x10e55e2ddfbd4c1a!2sOlakh%20Swapnanchi!5e0!3m2!1sen!2sin!4v1681209864294!5m2!1sen!2sin"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
