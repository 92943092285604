import React, { useState, useEffect } from 'react';
import './SuccessStories.css';
import Storycards from '../../component/storycards/Storycards';
// import { image } from '../../constants';
import axios from 'axios';

const SuccessStories = () => {
  const [successStories, setSuccessStories] = useState([]);

  useEffect(() => {
    fetchSuccessStories();
  }, []);

  const fetchSuccessStories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/success-stories`); // Update the endpoint URL
      const { successStories } = response.data;
      setSuccessStories(successStories);
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching success stories:', error);
    }
  };

  return (
    <>
      <div className="story-heading">
        <h1>Success Stories</h1>
      </div>
      <div className="success-stories">
        {successStories.map((story) => (
          <Storycards
            key={story._id} // Assuming each success story has a unique identifier (_id)
            storyImage={process.env.REACT_APP_SERVER_URL+story.marriagePhoto} // Update this with the corresponding image from the success story data
            storyTitle={story.groomsName + ' & ' + story.bridesName}
            storyText={story.storyMessage}
          />
        ))}
      </div>
    </>
  );
};

export default SuccessStories;
