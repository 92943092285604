import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { searchPartner } from "../../redux/actions";
import { image } from "../../constants";
import { AiFillHeart, AiOutlineStar } from "react-icons/ai";
import { FaUserTimes, FaUserCircle } from "react-icons/fa";
import { MdReport } from "react-icons/md";
import "./SearchOlakhId.css";
import axios from "axios";
import Redirect from "../../component/Redirect/Redirect";
import authAxios from "../../axios";
import { MdExpandLess, MdExpandMore } from "react-icons/md"
import MessageIcon from "@material-ui/icons/Message";


function SearchResult() {
    const navigate = useNavigate();
    const [isShortlisted, setIsShortlisted] = useState(false);
    const [isInterest, setIsInterest] = useState(false)

    const { searchResponse } = useSelector((state) => state.Search);
    const { isStandard } = useSelector((state) => state.User);
    const dispatch = useDispatch();
    const [currentUser, setCurrentUser] = useState(null);
    const { targetId } = useParams();
    const [valueNotification, setvalueNotification] = useState();
    const [userStates, setUserStates] = useState({});
    // const [searchQuery, setSearchQuery] = useState("");

    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [hasSomething, setHasSomething] = useState(false)
    const { isAuthenticated } = useSelector((state) => state.User);

    const getMe = async (setCurrentUser) => {
        try {
            const res = await authAxios.get(
                process.env.REACT_APP_ADMIN_BASE_URL_SERVER + "/user/me"
            );
            setCurrentUser(res.data.user);
        } catch (error) {
            console.log(error.message);
        }
    };
    const calculateAge = (birthYear) => {
        const currentYear = new Date().getFullYear();
        return currentYear - birthYear;
    };

    // handle profile view  
    const handleViewProfile = async (targetId) => {
        try {

            const response = await authAxios.post(`/interactions/profile-view/${targetId}`);

            // Handle the response as needed
            // console.log(response.data);
        } catch (error) {
            console.error(error);

        }
    };



    const handleNotificationClick = async (targetId) => {
        try {
            const response = await authAxios.patch(`/interactions/shortlist/${targetId}`, {});

            // Check if API request was successful
            if (response.status === 200) {
                setIsShortlisted(true);


                // Close the notification after 3 seconds
                setTimeout(() => {
                    setIsShortlisted(false);
                }, 3000);
            }
        } catch (error) {
            console.error(error);
        }
    };




    // Function to handle the search
    function searchByOlakhId() {
        // API request to search users by Olakh ID
        authAxios
            .get(`/user/search-by-olakh?olakh_id=${searchQuery}`)
            .then((response) => {
                setSearchResults(response.data.data);
                setHasSomething(true)
                // console.log(response.data.data)

                // Filter users based on gender after receiving the search results
                const { gender } = currentUser.profile;
                // console.log('user gender', gender);

                const searchedUserGender = response.data.data.profile.gender;


                if (isAuthenticated) {
                    if (gender === "Male" && searchedUserGender === "Female") {
                        // Display an error message here
                        console.error("You cannot search for a female user.");
                    } else if (gender === "Female" && searchedUserGender === "Male") {
                        // Display an error message here
                        console.error("You cannot search for a male user.");
                    } else {
                        setSearchResults(searchResults.filter(
                            (user) => user.profile.gender === (gender === "Male" ? "Female" : "Male")
                        ));
                    }
                } else {
                    setSearchResults(searchResults.filter(
                        (user) => user.profile.gender === "Male"
                    ));
                    console.log('search results', response.data.data)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };







    const filteredResults = searchResponse?.filter((user) =>
        user.userName.includes(searchQuery)

    );

    // const filteredResults = searchResponse?.filter((user) => {
    //   const userGender = user.profile.gender;
    //   const userAge = calculateAge(user.profile.DobYear);

    //   // Check if the user's gender is different from the currentUser's gender
    //   // and their age is less than the currentUser's age
    //   if (
    //     (currentUser?.profile.gender === 'Male' && userGender === 'Female' && userAge <= currentUser.profile.age) ||
    //     (currentUser?.profile.gender === 'Female' && userGender === 'Male' && userAge >= currentUser.profile.age)
    //   ) {
    //     return true;
    //   }

    //   return false;
    // });

    const handleInterestClick = async (targetId) => {
        try {
            const response = await authAxios.patch(
                `/interactions/interest/${targetId}`,

                {},


            );


            // Check if API request was successful
            if (response.status === 200) {
                setIsInterest(true);
                setvalueNotification(targetId);
                // Close the notification after 3 seconds
                setTimeout(() => {
                    setIsInterest(false);
                }, 3000);
            }
        } catch (error) {
            console.error(error.response);

        }
    };

    useEffect(() => {
        getMe(setCurrentUser);



    }, []);

    // Sidebar filter hide show on mobile
    const [isOpen, setIsOpen] = useState(false);


    function TruncatedText({ text, maxLength }) {
        const [truncate, setTruncate] = useState(true);
        const shouldTruncate = text.length > maxLength;

        const toggleTruncate = () => {
            setTruncate(!truncate);
        };

        return (
            <div>
                <p>
                    {truncate ? text.slice(0, maxLength) : text}
                    {shouldTruncate && (
                        <span onClick={toggleTruncate} style={{ cursor: 'pointer', color: 'black', }}>
                            {truncate ? (<>...<MdExpandMore size={20} /> </>) : (<><MdExpandLess size={20} /></>)}
                        </span>
                    )}
                </p>
            </div>
        );
    }


    return (
        <>

            <div className="search-result__container">
                <h2 className="search-result__mobile-head">Search Member</h2>
                {/* <button onClick={() => setIsOpen(!isOpen)}>Select filters</button> */}
                <div >
                    <h2 className="search-result__desktop-head">Search Member</h2>


                    <input
                        type="text"
                        className="search-result__search-bar"
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                            if (e.target.value === "") {
                                window.location.reload(); // Refresh the page
                            }
                        }}
                        placeholder="Search by Olakh ID"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                document.getElementById('search-button').click();
                            }
                        }}
                    />

                    <input
                        type="button"
                        className="search-button search-result__search-bar"
                        id="search-button"
                        value="Search"
                        onClick={searchByOlakhId}

                    />




                </div>


                <div className="search-result__user-card">
                    {/* <h2 className="search-result__heading">MULTIPLE USERS' DATA</h2> */}

                    {hasSomething && (
                        <>
                            <div className="search-result__user-data">

                                <div className="search-result__user">


                                    <div style={{ border: "1px solid black" }} className="search-result__user-image">
                                        <img

                                            src={process.env.REACT_APP_SERVER_URL + searchResults.profile?.avatar}
                                            alt=""
                                        />
                                    </div>
                                    <div className="search-result__user-content">
                                        <div>
                                            {/* <h3 className="search-result__user-name">
{searchResults.user.firstName + " " + user.surname}
</h3> */}
                                            <h3 className="search-result__user-name">
                                                Member ID: <span>{searchResults.userName}</span>
                                            </h3>
                                        </div>

                                        <div className="search-result__user-content__details-box">
                                            <div className="search-result__user-content__details-box-column">
                                                {isAuthenticated && (<>
                                                    <div className="search-result__user-content__detail  ">
                                                        <div className="search-result__user-content__age-label">
                                                            <strong> Name </strong>
                                                        </div>
                                                        <div className="search-result__user-content__age-value">
                                                            <p>{`${searchResults.firstName} ${searchResults.surname}`}  </p>
                                                        </div>
                                                    </div></>)}
                                                <div className="search-result__user-content__detail  ">
                                                    <div className="search-result__user-content__age-label">
                                                        <strong> Age: </strong>
                                                    </div>
                                                    <div className="search-result__user-content__age-value">
                                                        <p>{calculateAge(searchResults.profile?.DobYear)} Years </p>
                                                    </div>
                                                </div>

                                                <div className="search-result__user-content__detail">
                                                    <div className="search-result__user-content__mangal-label">
                                                        <strong>Mangal: </strong>
                                                    </div>
                                                    <div className="search-result__user-content__mangal-label">
                                                        <p>{searchResults.profile?.mangalik}</p>
                                                    </div>
                                                </div>

                                                {/* <div className="search-result__user-content__detail">
                                                    <div className="search-result__user-content__gender-label">
                                                        <strong>Looking for: </strong>
                                                    </div>
                                                    <div className="search-result__user-content__gender-value">
                                                        <p>{searchResults.profile?.gender === 'Male' ? 'Female' : 'Male'} </p>
                                                    </div>
                                                </div> */}

                                                {/* <div className="search-result__user-content__detail">
                                                    <div className="search-result__user-content__email-label">
                                                        <strong>Email: </strong>
                                                    </div>
                                                    <div className="search-result__user-content__email-value">
                                                        <p>{searchResults.user.email} </p>
                                                    </div>
                                                </div> */}

                                                <div className="search-result__user-content__detail">
                                                    <div className="search-result__user-content__caste-label">
                                                        <strong>Caste: </strong>
                                                    </div>
                                                    <div className="search-result__user-content__caste-value">
                                                        <p>{searchResults.profile?.caste} </p>
                                                    </div>
                                                </div>

                                                <div className="search-result__user-content__detail">
                                                    <div className="search-result__user-content__martial-status-label">
                                                        <strong>Martial Status: </strong>
                                                    </div>
                                                    <div className="search-result__user-content__martial-status-value">
                                                        <p> {searchResults.profile?.maritialStatus} </p>
                                                    </div>
                                                </div>

                                                <div className="search-result__user-content__detail">
                                                    <div className="search-result__user-content__DOB-label">
                                                        <strong>Date Of Birth: </strong>
                                                    </div>
                                                    <div className="search-result__user-content__Dob-value">
                                                        <p>{(searchResults.profile?.DobDay + "-" + searchResults.profile?.DobMonth + "-" + searchResults.profile?.DobYear)}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="search-result__user-content__details-box-column">
                                                <div className="search-result__user-content__detail">
                                                    <div className="search-result__user-content__complexion-label">
                                                        <strong>Complexion: </strong>
                                                    </div>
                                                    <div className="search-result__user-content__complexion-value">
                                                        <p> {searchResults?.profile?.complexion} </p>
                                                    </div>
                                                </div>

                                                <div className="search-result__user-content__detail">
                                                    <div className="search-result__user-content__height-label">
                                                        <strong>Height: </strong>
                                                    </div>
                                                    <div className="search-result__user-content__height-value">
                                                        <p> {searchResults.profile?.height} </p>
                                                    </div>
                                                </div>

                                                <div className="search-result__user-content__detail">
                                                    <div className="search-result__user-content__city-label">
                                                        <strong>City: </strong>
                                                    </div>
                                                    <div className="search-result__user-content__city-value">
                                                        <p> {searchResults.profile?.city} </p>
                                                    </div>
                                                </div>
                                                <div className="search-result__user-content__detail">
                                                    <div className="search-result__user-content__education-label" style={{ marginRight: '5px' }}>
                                                        <strong>Education: </strong>
                                                    </div>
                                                    <div className="search-result__user-content__education-value" style={{ maxWidth: "210px" }}>
                                                        <TruncatedText text={`${searchResults.profile?.qualification}`} maxLength={20} />
                                                    </div>
                                                </div>

                                                <div className="search-result__user-content__detail">
                                                    <div className="search-result__user-content__job-title-label" style={{ marginRight: '5px' }}>
                                                        <strong>Job Title: </strong>
                                                    </div>
                                                    <div className="search-result__user-content__job-title-value" style={{ maxWidth: "210px" }}>
                                                        <TruncatedText text={`${searchResults.profile?.completeOccupationDetails}`} maxLength={20} />
                                                    </div>
                                                </div>


                                                <div className="sammelan-user__user-content__detail">
                                                    <div className="sammelan-user__user-content__job-title-label" style={{ marginRight: '5px' }}>
                                                        <strong>{`Annual Income:`}</strong>
                                                    </div>
                                                    {searchResults?.profile.annualIncomeExample ? (
                                                        <div className="sammelan-user__user-content__job-title-value">
                                                            <TruncatedText text={`${searchResults.profile.annualIncomeExample}`} maxLength={20} />
                                                        </div>
                                                    ) : (
                                                        <div>N/A</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>


                                        {/* Interaction buttons start */}


                                        <div className="search-result__user-interaction-container">



                                            {isAuthenticated ? (
                                                <Link to={`/user-profile/${searchResults._id}`} onClick={() => handleViewProfile(searchResults._id)}>
                                                    <div className="sammelan-user__user-interaction-box">
                                                        <FaUserCircle className="sammelan-user__user-interaction-box__view-full-profile-icon" />
                                                        <p>View Full Profile</p>
                                                    </div>
                                                </Link>
                                            ) : (
                                                // Navigate the user to another location, like a login page
                                                <Link to='/register'>
                                                    <div className="sammelan-user__user-interaction-box">
                                                        <FaUserCircle className="sammelan-user__user-interaction-box__view-full-profile-icon" />
                                                        <p>View Full Profile</p>
                                                    </div>
                                                </Link>
                                            )}



                                            <div className="sammelan-user__user-interaction-box" onClick={() => handleInterestClick(searchResults?._id)}>

                                                <AiFillHeart className="sammelan-user__user-interaction-box__interest-icon" />
                                                <p>Interest</p>



                                                {isInterest && (
                                                    <div className="notification">
                                                        <p>{`You have shown interest in ${searchResults?.firstName + searchResults?.surname}`}</p>
                                                    </div>
                                                )}

                                            </div>


                                            <div className="sammelan-user__user-interaction-box" onClick={() =>
                                                navigate(`/dashboard/singleChat/${searchResults._id}`)
                                            }>
                                                <MessageIcon fontSize="small" className="sammelan-user__user-interaction-box__interest-icon message-icon" />
                                                <p>Message</p>


                                                {/* {isOpenInterest && user._id === valueInterest && (
<div className="notification">
<p>{`You have shown interest in ${user.firstName + user.surname}`}</p>
</div>
)} */}

                                            </div>


                                            <div className="sammelan-user__user-interaction-box">

                                                <AiOutlineStar className="sammelan-user__user-interaction-box__shortlist-icon" onClick={() => handleNotificationClick(searchResults?._id)} />
                                                <p>Shortlist</p>

                                                {isShortlisted && (
                                                    <div className="notification">
                                                        <p>{`You have shortlisted ${searchResults?.firstName + searchResults?.surname
                                                            }`}</p>
                                                    </div>
                                                )}
                                            </div>




                                        </div>
                                        {/* Interaction buttons end */}

                                    </div>
                                </div>













                            </div>  </>
                    )}


                </div>











            </div>

        </>
    );
}

export default SearchResult;
