import React, { useState, useEffect } from "react";
import "./home.css";
import HeroSection from "../../component/HeroSection/HeroSection";
import SingleFeaturedProfile from "../../component/SingleFeaturedProfile/SingleFeaturedProfile";
import { image } from "../../constants";
import { useSelector } from "react-redux";
import authAxios from "../../axios"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   contact,
//   messaging,
//   bell,
//   privacyandsecurity,
//   match,
//   Group,
//   lock,
//   faFacebook,
// } from "@fortawesome/free-brands-svg-icons";
import TestimonialsSlider from "../../component/TestimonialsSlider/TestimonialsSlider";
import FAQ from "../../component/Faq/Faq";
import Footer from "../../component/Footer/Footer";
// import { IoLogoGooglePlaystore } from 'react-icons/io';
import { AiFillWechat, AiFillContacts } from "react-icons/ai";
import { BsShieldShaded, BsFillBoxFill } from "react-icons/bs";
import Popup from "../../component/popup/popup";
import axios from "axios";
import { login } from "../../redux/actions";
import TeamSection from "../../component/Team/Team";
import FactsAndFigures from "../../component/FactsAndFigures/FactsAndFigures";


const Home = (props) => {
  const [users, setUsers] = useState([]);
  const { isAuthenticated } = useSelector((state) => state.User);
  const [currentUser, setCurrentUser] = useState(null);
  const [successStories, setSuccessStories] = useState([]);
  const [MobileApp, setMobileApp] = useState();
  


  //  useEffect(() => {
  //     const fetchUsers = async () => {
  //       try {
  //         const response = await axios.get(
  //           `${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/user/all-users-home`
  //         );

  //         if (Array.isArray(response.data.users)) {
  //           const randomizedUsers = response.data.users.sort(() => Math.random() - 0.5);
  //           const limitedUsers = randomizedUsers.slice(0, 12);
  //           setUsers(limitedUsers);
  //         } else {
  //           console.error("User data is not an array");
  //         }
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     };

  //     fetchUsers();
  //   }, []);

  const fetchSuccessStories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/success-stories`); // Update the endpoint URL
      const { successStories } = response.data;
      setSuccessStories(successStories);
      console.log(successStories)
    } catch (error) {
      console.error('Error fetching success stories:', error);
    }
  };
  const fetchMobileApp = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/website-cms`); // Update the endpoint URL
      const data = response.data.DownloadMobile;
      setMobileApp(data);
      // console.log(data)
    } catch (error) {
      console.error('Error fetching Mobile app data:', error);
    }
  };
  useEffect(() => {
    fetchSuccessStories();
    fetchMobileApp();
  }, []);

  const getMe = async (setCurrentUser) => {
    try {
      const res = await authAxios.get(
        process.env.REACT_APP_ADMIN_BASE_URL_SERVER + "/user/me"
      );
      setCurrentUser(res.data.user);
    } catch (error) {
      console.log(error.message);
    }
  };
  const calculateAge = (birthYear) => {
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear;
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/user/select-sammelan-users/:id`
        );
        console.log("sammelan user", response);
  
        if (Array.isArray(response.data.users)) {
          const allUsers = response.data.users;
  
          // Separate male and female users
          const maleUsers = allUsers.filter(user => user.profile.gender === "Male");
          const femaleUsers = allUsers.filter(user => user.profile.gender === "Female");
  
          if (isAuthenticated) {
            const { gender } = currentUser.profile;
  
            if (gender === "Male") {
              // Randomly shuffle the male and female user arrays
              shuffleArray(maleUsers);
              shuffleArray(femaleUsers);
              
              // Select 6 male and 6 female users
              setUsers([...maleUsers.slice(0, 6), ...femaleUsers.slice(0, 6)]);
            } else if (gender === "Female") {
              // Randomly shuffle the male and female user arrays
              shuffleArray(maleUsers);
              shuffleArray(femaleUsers);
              
              // Select 6 male and 6 female users
              setUsers([...maleUsers.slice(0, 6), ...femaleUsers.slice(0, 6)]);
            }
          } else {
            // Randomly shuffle the male and female user arrays
            shuffleArray(maleUsers);
            shuffleArray(femaleUsers);
            
            // Select 6 male and 6 female users
            setUsers([...maleUsers.slice(0, 6), ...femaleUsers.slice(0, 6)]);
          }
        } else {
          console.error("User data is not an array");
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    // Function to shuffle an array randomly
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };
  
    fetchUsers();
  }, [isAuthenticated, currentUser]);
  

  useEffect(() => {
    if (!currentUser) {
      getMe(setCurrentUser);
    }
  }, [currentUser]);

  if (currentUser?.length <= 0) return null;




  return (
    <>
      <Popup></Popup>
      {/* Hero Section Start */}
      <HeroSection />
      {/* Hero Section End */}

      {/* About Section Start */}
      <div className="aboutSection">
        <div className="aboutSection-left-column">
          <img src={image.aboutUsImage} alt="" width={"500px"} />
          {/* <a href="#">
                        <FontAwesomeIcon icon={faFacebook} className="aboutSection-left-column-playIcon" id="" />
                    </a> */}
        </div>

        <div className="aboutSection-right-column">
          <h2>About Us</h2>
          <p>
            Olakh.info is the fastest growing matrimonial services in Madhya
            Pradesh, Chattisgarh, Maharashtra, Rajasthan, Uttar Pradesh and
            Gujarat, promoted by Mr. Anand Chandorkar. Our aim is to serve on
            one platform through "OLAKH SWAPNANCHI" for Maharashtrians
            prospective brides and grooms to have dialogue and communicate with
            each other. It offers a superior matchmaking experience by expanding
            the opportunities available to meet potential life partners and
            build our dreams.
          </p>
          <ul className="aboutSection-right-column-about-lists">
            <li className="">
              <AiFillContacts className="aboutSection-right-column-about-lists-icon" />
              <h5>Contact genuine profiles</h5>
            </li>
            <li className="">
              <AiFillWechat className="aboutSection-right-column-about-lists-icon" />
              <h5>Find perfect match quite easily</h5>
            </li>
            <li className="">
              <BsFillBoxFill className="aboutSection-right-column-about-lists-icon" />
              <h5>100% security for data and Profile</h5>
            </li>
            <li className="">
              <BsShieldShaded className="aboutSection-right-column-about-lists-icon" />
              <h5>Trusted Matrimonial agency in the world</h5>
            </li>
          </ul>
          <a href="/about">
            <button>See More</button>
          </a>
        </div>
      </div>
      {/* About Section End */}

      {/* Featured Groom And Bride Section Start */}
      <div className="featuredSection">
        <h2>Olakh Verified Profiles</h2>
        <div className="featuredSection-profiles">
          {users.map((user) => (
            <SingleFeaturedProfile
              key={user._id}
              cardimg={process.env.REACT_APP_SERVER_URL + user.profile.avatar}
              olakhId={user.userName}
              age={calculateAge(user.profile.DobYear)}
              height={user.profile.height}
              userid={user._id}
              gender={user.profile.gender}
            />
          ))}
        </div>
      </div>
      {/* Featured Groom And Bride Section End */}

      {/* How It Work Section start */}
      <div className="howItWork">
        <div className="howItWork-content">
          <h2>How It Work</h2>
          <p>
            Find your dream partner on Olakh Swapnanchi by following the suggested process.
          </p>
        </div>
        <div className="howItWork-box">
          <div className="howItWork-box-1">
            <img src={image.user} alt="" />
            <div className="ho  wItWork-box-1-content">
              <h5>Create Account</h5>
              <p>
                To create an account on Olakh Swapnanchi, simply click on the "Register" button.
              </p>
            </div>
          </div>
          <div className="howItWork-box-2">
            <img src={image.search} alt="" />
            <div className="howItWork-box-1-content">
              <h5>Easy Search</h5>
              <p>
                Become a premium member and start searching verified profiles
              </p>
            </div>
          </div>
          <div className="howItWork-box-3">
            <img src={image.chat} alt="" />
            <div className="howItWork-box-1-content">
              <h5>Start Interaction</h5>
              <p>You can start interacting with members of Olakh.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* How It Work Section end */}

      {/* Stats start */}
      <FactsAndFigures />
      {/* Stats end */}


      {/* Success Stories Section Start */}
      <div className="gallery">
        <div className="gallery-content">
          <h2>Success Stories</h2>
          <p>Our successful stories are too diverse. These are awesome, romantic, like a dream.</p>
        </div>
        <div className="gallery-row">
          {successStories.map((story, index) => (
            <div className="gallery-row-item" key={index}>
              <img src={`${process.env.REACT_APP_SERVER_URL}` + story.marriagePhoto} alt="" />
            </div>
          ))}
        </div>
      </div>
      {/* <div className="story-heading">
        <h1>Success Stories</h1>
      </div>
      <div className="success-stories">
        {successStories.map((story) => (
          <imag
            key={story._id} // Assuming each success story has a unique identifier (_id)
            storyImage={process.env.REACT_APP_SERVER_URL + story.marriagePhoto} // Update this with the corresponding image from the success story data
            storyTitle={story.groomsName + ' & ' + story.bridesName}
            storyText={story.storyMessage}
          />
        ))}
      </div> */}
      {/* Success Stories Section End */}

      {/* Features and Policy Section Start */}
      <div className="FeaturesPolicy">
        <div className="FeaturesPolicy-header">
          <h2 className="FeaturesPolicy-title">Features and Policy</h2>
          <p className="FeaturesPolicy-desc">
            Our website offers free SMS and chat, auto match maker, recommend
            profiles, and notification alerts.
          </p>
        </div>
        <div className="FeaturesPolicy-grid-container">
          {/* <div className="FeaturesPolicy-grid-item">
            <div className="FeaturesPolicy-grid-item-image-div">
              <img src={image.messaging} alt="" />
            </div>
            <h3 className="FeaturesPolicy-grid-item-title">Free SMS & Chat</h3>
            <p className="FeaturesPolicy-grid-item-desc">
              An important facility such as chat is available in our site, so
              that people can interact with each other.
            </p>
          </div>
          <div className="FeaturesPolicy-grid-item">
            <div className="FeaturesPolicy-grid-item-image-div">
              <img src={image.bell} alt="" />
            </div>
            <h3 className="FeaturesPolicy-grid-item-title">Auto Match Maker</h3>
            <p className="FeaturesPolicy-grid-item-desc">
              Restrictions Enable Copying Photos & Video Disable Anti Spam
              System Provided.
            </p>
          </div> */}
          <div className="FeaturesPolicy-grid-item">
            <div className="FeaturesPolicy-grid-item-image-div">
              <img src={image.privacyandsecurity} alt="" />
            </div>
            <h3 className="FeaturesPolicy-grid-item-title">
              Verified Profiles
            </h3>
            {/* <p className="FeaturesPolicy-grid-item-desc">
              when you follow some-one, you can see the updates of people whom
              you follow.
            </p> */}
          </div>
          <div className="FeaturesPolicy-grid-item">
            <div className="FeaturesPolicy-grid-item-image-div">
              <img src={image.bell} alt="" />
            </div>
            <h3 className="FeaturesPolicy-grid-item-title">
              Notification Alerts
            </h3>
            {/* <p className="FeaturesPolicy-grid-item-desc">
              Some new members get registered daily. This matrimony system
              updates and shows you matched profile.
            </p> */}
          </div>
          <div className="FeaturesPolicy-grid-item">
            <div className="FeaturesPolicy-grid-item-image-div">
              <img src={image.heart} alt="" />
            </div>
            <h3 className="FeaturesPolicy-grid-item-title">
              Shortlist and Express Interest
            </h3>
            {/* <p className="FeaturesPolicy-grid-item-desc">
              This electronic website is being operated and owned by olakh.info
            </p> */}
          </div>
          <div className="FeaturesPolicy-grid-item">
            <div className="FeaturesPolicy-grid-item-image-div">
              <img src={image.lock} alt="" />
            </div>
            <h3 className="FeaturesPolicy-grid-item-title">Privacy Policy</h3>
            {/* <p className="FeaturesPolicy-grid-item-desc">
              The activities such as changing the display picture, birthday
              notification, photo requests.
            </p> */}
          </div>
        </div>
      </div>
      {/* Features and Policy Section End */}

      {/* Testimonial Slider Start */}
      <TestimonialsSlider />
      {/* Testimonial Slider End */}

      {/* Team start */}
      <TeamSection />
      {/* Team end */}



      {/* Mobile app available on play store start */}
      <div className="mobileApp">

        <div className="mobileApp-left-column">
          <h2 className="mobileApp-heading">{MobileApp?.MobileTitle}</h2>
          <p className="mobileApp-paragraph">{MobileApp?.MobileContent}</p>
          <br />
          <a
            href={MobileApp?.MobileLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={image.playStoreLogo} alt="" width={200} />
          </a>
        </div>

        <div className="mobileApp-right-column">
          <img src={image.olakhMobileAppAvailableOnPlayStore} alt="Mobile App" className="mobileApp-image" />
        </div>
      </div>
      {/* Mobile app available on play store end */}

    </>
  );
};

export default Home;
