import { useEffect, useState } from "react";
import axios from "../../axios";
import "./ProfileSettings.css";
import {
  gotraData,
  casteData,
  heightData,
  maritalStatusData,
  manglikData,
  rashiData,
  naadiData,
  ganData,
  charanData,
  nakshatraData,
  complexionData,
  bodyTypeData,
  brahminSubCasteData
} from "../../data/UserData";
import { useSelector } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import validator from "validator";

const getMe = async (setCurrentUser) => {

  try {
    const res = await axios.get(
      process.env.REACT_APP_ADMIN_BASE_URL_SERVER + "/user/me"
    );
    setCurrentUser(res.data.user);
  } catch (error) {
    console.log(error.message);
  }
};

function ProfileSettings() {
  const [currentUser, setCurrentUser] = useState(null);
  const [formData, setFormData] = useState({});
  const [isBasicInfoOpen, setIsBasicInfoOpen] = useState(true);
  const [isAdditionalInfoOpen, setIsAdditionalInfoOpen] = useState(true);

  useEffect(() => {
    if (!currentUser) {
      getMe(setCurrentUser);
    }
  }, [currentUser]);

  const setData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.patch(
        process.env.REACT_APP_ADMIN_BASE_URL_SERVER +
        "/user/" +
        currentUser._id,
        formData
      );
      setCurrentUser(res.data.data);
      // window.location.reload();

    } catch (error) {
      console.log(error.message);
    }
  };

  // async function onSubmit(e) {
  //   e.preventDefault();
  //   try {
  //     const data = { ...formData };
  //     const res = await axios.post("/approval/by-user/profile-data", data);
  //     console.log(res.data);
  //     alert("Form submitted successfully. Wait for admin approval!");
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // }

  if (!currentUser) return null;
  return (
    <>

      <h2 className="profile-settings">Profile Settings</h2>
      <button
        className="profile-settings__basic-info-toggle"
        onClick={() => setIsBasicInfoOpen(!isBasicInfoOpen)}
      >
        {isBasicInfoOpen ? "Basic Information" : "Basic Information"}
      </button>
      {isBasicInfoOpen && (
        <form className="profile-settings__basic-info-form">
          <div className="profile-settings__basic-info-flex-container">

            <div className="profile-settings__basic-info-field">
              <label
                className="profile-settings__basic-info-label"
                htmlFor="name-prefix"
              >
                Name Prefix
              </label>
              <input
                className="profile-settings__basic-info-input profile-settings__basic-info-input--first-name"
                value={currentUser.profile.prefix}
                readOnly
              />
            </div>

            <div className="profile-settings__basic-info-field">
              <label
                className="profile-settings__basic-info-label"
                htmlFor="first-name"
              >
                Candidate Name
              </label>
              <input
                className="profile-settings__basic-info-input profile-settings__basic-info-input--first-name"
                value={currentUser.firstName}
                readOnly
              />
            </div>
            <div className="profile-settings__basic-info-field">
              <label
                className="profile-settings__basic-info-label"
                htmlFor="last-name"
              >
                Surname
              </label>
              <input
                className="profile-settings__basic-info-input profile-settings__basic-info-input--last-name"
                value={currentUser.surname}
                readOnly
              />
            </div>

            <div className="profile-settings__basic-info-field">
              <label
                className="profile-settings__basic-info-label"
                htmlFor="gender"
              >
                Gender
              </label>
              <input
                className="profile-settings__basic-info-input profile-settings__basic-info-input--gender"
                value={currentUser.profile.gender}
                readOnly
              />
            </div>
          </div>

          <div className="profile-settings__basic-info-flex-container">
            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="father-prefix"
              >
                Father's Prefix
              </label>
              <input
                className="profile-settings__additional-info-input profile-settings__additional-info-input--father-prefix"
                type="text"
                name="fatherPrefix"
                defaultValue={currentUser.profile.fatherPrefix}
                onChange={setData}
              />
            </div>
            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="father-name"
              >
                Father's Full Name
              </label>
              <input
                className="profile-settings__additional-info-input profile-settings__additional-info-input--father-name"
                type="text"
                name="fatherName"
                defaultValue={currentUser.profile.fatherName}
                onChange={setData}
              />
            </div>

            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="mother-prefix"
              >
                Mother Prefix
              </label>
              <input
                className="profile-settings__additional-info-input profile-settings__additional-info-input--mother-name"
                type="text"
                name="motherprefix"
                defaultValue={currentUser.profile.motherPrefix}
                onChange={setData}
              />
            </div>
            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="mother-name"
              >
                Mother's Full Name
              </label>
              <input
                className="profile-settings__additional-info-input profile-settings__additional-info-input--mother-name"
                type="text"
                name="motherName"
                defaultValue={currentUser.profile.motherName}
                onChange={setData}
              />
            </div>
          </div>


          <div className="profile-settings__basic-info-flex-container">
            <div className="profile-settings__basic-info-field">
              <label
                className="profile-settings__basic-info-label"
                htmlFor="phone-number"
              >
                Phone Number
              </label>
              <input
                className="profile-settings__basic-info-input profile-settings__basic-info-input--phone-number"
                value={currentUser.profile.phoneNumber}
                readOnly
              />
            </div>
            <div className="profile-settings__basic-info-field">
              <label
                className="profile-settings__basic-info-label"
                htmlFor="whatsapp-number"
              >
                WhatsApp Number
              </label>
              <input
                className="profile-settings__basic-info-input profile-settings__basic-info-input--whatsapp-number"
                value={currentUser.profile.whatsappNumber}
                readOnly
              />
            </div>
            <div className="profile-settings__basic-info-field">
              <label
                className="profile-settings__basic-info-label"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="profile-settings__basic-info-input profile-settings__basic-info-input--email"
                value={currentUser.email}
                readOnly
              />
            </div>
            <div className="profile-settings__basic-info-field">
              <label
                className="profile-settings__basic-info-label"
                htmlFor="maritial-status"
              >
                Marital Status
              </label>
              <input
                className="profile-settings__basic-info-input profile-settings__basic-info-input--maritial-status"
                value={currentUser.profile.maritialStatus}
                readOnly
              />
            </div>
          </div>

          <div className="profile-settings__basic-info-flex-container">


            <div className="profile-settings__basic-info-field">
              <label
                className="profile-settings__basic-info-label"
                htmlFor="date-of-birth"
              >
                Birth Date
              </label>
              <input
                className="profile-settings__basic-info-input profile-settings__basic-info-input--date-of-birth"
                value={currentUser.profile.DobDay}
                readOnly
              />
            </div>

            <div className="profile-settings__basic-info-field">
              <label
                className="profile-settings__basic-info-label"
                htmlFor="month"
              >
                Birth Month
              </label>
              <input
                className="profile-settings__basic-info-input profile-settings__basic-info-input--month"
                value={currentUser.profile.DobMonth}
                readOnly
              />
            </div>

            <div className="profile-settings__basic-info-field">
              <label
                className="profile-settings__basic-info-label"
                htmlFor="year"
              >
                Birth Year
              </label>
              <input
                className="profile-settings__basic-info-input profile-settings__basic-info-input--year"
                value={currentUser.profile.DobYear}
                readOnly
              />
            </div>



            <div className="profile-settings__basic-info-field">
              <label
                className="profile-settings__basic-info-label"
                htmlFor="caste"
              >
                Caste
              </label>
              <input
                className="profile-settings__basic-info-input profile-settings__basic-info-input--caste"
                value={currentUser.profile.caste}
                readOnly
              />
            </div>

            {/* {currentUser.profile.subCaste && (
              // <div className="user-profile__row user-profile__highlight-row">
              //   <div className="user-profile__column">
              //     <img className="user-profile__icon" src={image.avatar} alt="Icon 3" />
              //     <p className="user-profile__text">subCaste</p>
              //   </div>
              //   <div className="user-profile__column">
              //     <p className="user-profile__text">{currentUser.profile.subCaste}</p>
              //   </div>
              // </div>
              <div className="profile-settings__basic-info-field">
                <label
                  className="profile-settings__basic-info-label"
                  htmlFor="caste"
                >
                  Shakha
                </label>
                <input
                  className="profile-settings__basic-info-input profile-settings__basic-info-input--caste"
                  value={currentUser.profile.subCaste}
                  readOnly
                />
              </div>

            )} */}

            {/* <button className="profile-settings__basic-info-save-button" type="submit">Save</button> */}
          </div>
        </form>
      )}

      <button
        className="profile-settings__additional-info-toggle"
        onClick={() => setIsAdditionalInfoOpen(!isAdditionalInfoOpen)}
      >
        {isAdditionalInfoOpen
          ? "Additional Information"
          : "Additional Information"}
      </button>
      {isAdditionalInfoOpen && (
        <form
          className="profile-settings__additional-info-form"
          onSubmit={onSubmit}
        >




          <div className="profile-settings__basic-info-flex-container">


            {currentUser.profile.caste === "Brahmin" ? (<>
              <div className="profile-settings__additional-info-field">
                <label
                  className="profile-settings__additional-info-label"
                  htmlFor="subCaste"
                >
                  Shakha{" "}
                </label>
                <select
                  onChange={setData}
                  defaultValue={currentUser.profile.subCaste}
                  name="subCaste"
                  className="profile-settings__additional-info-input profile-settings__additional-info-input--no-of-brothers"
                >
                  <option value="" disabled defaultValue="-">
                    Shakha
                  </option>
                  {brahminSubCasteData.map((caste) => (
                    <option key={caste} value={caste}>
                      {caste}
                    </option>
                  ))}
                </select>
              </div>
            </>) : (<> Not Brahmin</>)}

            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="no-of-brothers"
              >
                No. of Brothers{" "}
              </label>
              <select
                onChange={setData}
                defaultValue={currentUser.profile.noOfBrothers}
                name="noOfBrothers"
                className="profile-settings__additional-info-input profile-settings__additional-info-input--no-of-brothers"
              >
                <option value="select" disabled>
                  Select No. Of Brothers
                </option>
                {Array.from({ length: 7 }).map((_, i) => (
                  <option key={i} value={i}>
                    {i === 6 ? "5+" : i}
                  </option>
                ))}
              </select>
            </div>

            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="no-of-sisters"
              >
                No. of Sisters{" "}
              </label>
              <select
                onChange={setData}
                defaultValue={currentUser.profile.noOfSisters}
                name="noOfSisters"
                className="profile-settings__additional-info-input profile-settings__additional-info-input--no-of-sisters"
              >
                <option value="select" disabled>
                  Select No. Of Sisters
                </option>
                {Array.from({ length: 7 }).map((_, i) => (
                  <option key={i} value={i}>
                    {i === 6 ? "5+" : i}
                  </option>
                ))}
              </select>
            </div>


            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="permanent-address"
              >
                Postal Address
              </label>
              <input
                className="profile-settings__additional-info-input profile-settings__additional-info-input--permanent-address"
                type="text"
                name="permanentAddress"
                defaultValue={currentUser.profile.permanentAddress}
                onChange={setData}
              />
            </div>



            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="city"
              >
                City
              </label>
              <input
                className="profile-settings__additional-info-input profile-settings__additional-info-input--city"
                type="text"
                name="city"
                defaultValue={currentUser.profile.city}
                onChange={setData}
              />
            </div>

            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="birth-place"
              >
                Birth Place
              </label>
              <input
                className="profile-settings__additional-info-input profile-settings__additional-info-input--birth-place"
                type="text"
                name="birthPlace"
                defaultValue={currentUser.profile.birthPlace}
                onChange={setData}
              />
            </div>


            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="pincode"
              >
                Pincode
              </label>
              <input
                className="profile-settings__additional-info-input profile-settings__additional-info-input--pincode"
                type="text"
                name="pincode"
                defaultValue={currentUser.profile.pincode}
                onChange={setData}
              />
            </div>

            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="other-contact-number"
              >
                Other Contact No.
              </label>
              <input
                className="profile-settings__additional-info-input profile-settings__additional-info-input--other-contact-number"
                type="text"
                name="otherContactInfo"
                defaultValue={currentUser.profile.otherContactInfo}
                onChange={setData}
              />
            </div>


            <div className="profile-settings__additional-info-field">
              <label className="profile-settings__additional-info-label" htmlFor="DobHours">
                Birth Time (Hour)
              </label>
              <select
                onChange={setData}
                defaultValue={currentUser.profile.DobHours}
                name="DobHours"
                className="profile-settings__additional-info-input profile-settings__additional-info-input--hours"
              >
                <option value="select" defaultValue disabled>
                  Select Hour
                </option>
                {Array.from({ length: 12 }).map((_, i) => (
                  <option key={i} value={i}>
                    {String(i).padStart(2, '0')}
                  </option>
                ))}
              </select>
            </div>

            <div className="profile-settings__additional-info-field">
              <label className="profile-settings__additional-info-label" htmlFor="minutes">
                Birth Time (Minutes)
              </label>
              <select
                onChange={setData}
                defaultValue={currentUser.profile.DobMinutes}
                name="DobMinutes"
                className="profile-settings__additional-info-input profile-settings__additional-info-input--minutes"
              >
                <option value="select" disabled defaultValue>
                  Select Minute
                </option>
                {Array.from({ length: 60 }).map((_, i) => (
                  <option key={i} value={i}>
                    {String(i).padStart(2, '0')}
                  </option>
                ))}
              </select>
            </div>



            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="DobAMPM"
              >
                Birth Time (AM/PM)
              </label>
              <select
                id="DobAMPM"
                name="DobAMPM"
                className="profile-settings__additional-info-input profile-settings__additional-info-input--ampm"
                defaultValue={currentUser.profile.DobAMPM}
                onChange={setData}
              >
                <option value="select" disabled defaultValue>Select AM/PM</option>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>


            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="complexion"
              >
                Complexion
              </label>
              <select
                name="complexion"
                className="profile-settings__additional-info-input profile-settings__additional-info-input--complexion"
                onChange={setData}
                defaultValue={currentUser.profile.complexion}
              >
                <option disabled>Select Complexion</option>
                {complexionData.map((complexion) => (
                  <option key={complexion} value={complexion}>
                    {complexion}
                  </option>
                ))}
              </select>
            </div>


            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="rashi"
              >
                Rashi
              </label>
              <select
                name="rashi"
                className="profile-settings__additional-info-input profile-settings__additional-info-input--rashi"
                onChange={setData}
                defaultValue={currentUser.profile.rashi}
              >
                <option disabled>Select Rashi</option>
                {rashiData.map((rashi) => (
                  <option key={rashi} value={rashi}>
                    {rashi}
                  </option>
                ))}
              </select>
            </div>


            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="gotra"
              >
                Gotra
              </label>
              <select
                name="gotra"
                className="profile-settings__additional-info-input profile-settings__additional-info-input--gotra"
                onChange={setData}
                defaultValue={currentUser.profile.gotra}
              >
                <option disabled>Select gotra</option>
                {gotraData.map((gotra) => (
                  <option key={gotra} value={gotra}>
                    {gotra}
                  </option>
                ))}
              </select>
            </div>

            {/* --------------------------------------------------------------------- */}
            {/* <div>
              <label
                className="profile-settings__additional-info-label"
                htmlFor="income-type"
              >
                Currency
              </label>
              <select
                name="incomeType"
                defaultValue={currentUser.profile.incomeType}
                onChange={setData}
                className="profile-settings__additional-info-input profile-settings__additional-info-input--incometype"
              >
                <option value="" disabled defaultValue="Select Income Type">
                  Select Currency
                </option>
                <option value="Rs">Rs</option>
                <option value="Dollar">Dollar</option>
                <option value="Euro">Euro</option>
                <option value="Yen">Yen</option>
                <option value="Pound">Pound</option>
                <option value="Dirham">Dirham</option>
                <option value="Other">Other</option>
              </select>
              {currentUser.profile.incomeType === "Other" && (
                <input
                  name="otherIncomeType"
                  defaultValue={currentUser.profile.otherIncomeType}
                  onChange={setData}
                  className="register-form__input"
                  type="text"
                  placeholder="Enter custom income type"
                />
              )}
            </div> */}
            {/* ---------------------------------------------------------------------------------- */}


            <div className="profile-settings__additional-info-field">
              <label className="profile-settings__additional-info-label" htmlFor="gan">
                Gan
              </label>
              <select
                name="gan"
                defaultValue={currentUser.profile.gan}
                onChange={setData}
                className="profile-settings__additional-info-input profile-settings__additional-info-input--gan"
              >
                <option value="" disabled>Select Gan</option>
                {ganData.map((gan) => (
                  <option key={gan} value={gan}>
                    {gan}
                  </option>
                ))}
              </select>
            </div>


            {/* <div className="profile-settings__additional-info-field">
              <label className="profile-settings__additional-info-label"
                htmlFor="gan">Gan</label>
              <select
                className="profile-settings__additional-info-input profile-settings__additional-info-input--gan"
                name="gan"
                value={currentUser.profile.gan}
                onChange={setData}
              >
                <option disabled defaultValue="gan" value="">Gan</option>
                <option value="dev">Dev</option>
                <option value="manushya">Manushya</option>
                <option value="rakshas">Rakshas</option>
              </select>
            </div> */}

            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="naadi"
              >
                Naadi
              </label>
              <select
                name="naadi"
                className="profile-settings__additional-info-input profile-settings__additional-info-input--naadi"
                onChange={setData}
                defaultValue={currentUser.profile.naadi}
              >
                <option disabled>Select Naadi</option>
                {naadiData.map((naadi) => (
                  <option key={naadi} value={naadi}>
                    {naadi}
                  </option>
                ))}
              </select>
            </div>

            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="nakshatra"
              >
                Nakshatra
              </label>
              <select
                name="nakshatra"
                className="profile-settings__additional-info-input profile-settings__additional-info-input--nakshatra"
                onChange={setData}
                defaultValue={currentUser.profile.nakshatra}
              >
                <option disabled>Select Nakshatra</option>
                {nakshatraData.map((nakshatra) => (
                  <option key={nakshatra} value={nakshatra}>
                    {nakshatra}
                  </option>
                ))}
              </select>
            </div>

            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="charan"
              >
                Charan
              </label>
              <select
                name="charan"
                className="profile-settings__additional-info-input profile-settings__additional-info-input--charan"
                onChange={setData}
                defaultValue={currentUser.profile.charan}
              >
                <option disabled>Select Charan</option>
                {charanData.map((charan) => (
                  <option key={charan} value={charan}>
                    {charan}
                  </option>
                ))}
              </select>
            </div>

            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="blood-group"
              >
                Blood Group{" "}
              </label>
              <select
                name="bloodGroup"
                className="profile-settings__additional-info-input profile-settings__additional-info-input--blood-group"
                onChange={setData}
                defaultValue={currentUser.profile.bloodGroup}
              >
                <option defaultValue="Select Blood Group" disabled>
                  Select Blood Group
                </option>
                {[
                  "A+",
                  "A-",
                  "B+",
                  "B-",
                  "AB+",
                  "AB-",
                  "O+",
                  "O-",
                  "Don't Know",
                ].map((blood) => (
                  <option key={blood} value={blood}>
                    {blood}
                  </option>
                ))}
              </select>
            </div>


            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="body-type"
              >
                Body type
              </label>
              <select
                name="bodyType"
                className="profile-settings__additional-info-input profile-settings__additional-info-input--body-type"
                onChange={setData}
                defaultValue={currentUser.profile.bodyType}
              >
                <option disabled>Select Body Type</option>
                {bodyTypeData.map((bodyType) => (
                  <option key={bodyType} value={bodyType}>
                    {bodyType}
                  </option>
                ))}
              </select>
            </div>

            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="height"
              >
                Height
              </label>
              <select
                name="height"
                className="profile-settings__additional-info-input profile-settings__additional-info-input--height"
                onChange={setData}
              >
                <option defaultValue={currentUser.profile.height} >
                  {currentUser.profile.height}
                </option>
                {heightData.map((heights) => (
                  <option key={heights} value={heights}>
                    {heights}
                  </option>
                ))}
              </select>
            </div>



            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="mangalik"
              >
                Mangal
              </label>
              <select
                name="mangalik"
                className="profile-settings__additional-info-input profile-settings__additional-info-input--manglik"
                onChange={setData}
              >
                <option defaultValue={currentUser.profile.mangalik}
                >{currentUser.profile.mangalik}</option>
                {manglikData.map((manglik) => (
                  <option key={manglik} value={manglik}>
                    {manglik}
                  </option>
                ))}
              </select>
            </div>


            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="complete-occupation-details"
              >
                Educational Qualification:
              </label>
              <input
                className="profile-settings__additional-info-input profile-settings__additional-info-input--complete-occupation-details"
                type="text"
                name="qualification"
                defaultValue={currentUser.profile.qualification}
                onChange={setData}
              />
            </div>

            <div className="profile-settings__additional-info-field">

              <label
                className="profile-settings__additional-info-label"
                htmlFor="complete-occupation-details"
              >
                Occupation Details (if applicable)
              </label>
              <input
                className="profile-settings__additional-info-input profile-settings__additional-info-input--complete-occupation-details"
                name="completeOccupationDetails"
                defaultValue={currentUser.profile.completeOccupationDetails}
                onChange={setData}
                type="text"
                placeholder="Occupation Details (Designation, Company Name)"
              />


            </div>
            <div className="profile-settings__additional-info-field">

              <label
                className="profile-settings__additional-info-label"
                htmlFor="job-location"
              >
                Job Location
              </label>

              <input
                className="profile-settings__additional-info-input profile-settings__additional-info-input--joblocation"
                name="jobLocation"
                defaultValue={currentUser.profile.jobLocation}
                onChange={setData}
                type="text"
                placeholder="Job Location"
              />


            </div>
            <div className="profile-settings__additional-info-field">

              <label
                className="profile-settings__additional-info-label"
                htmlFor="income-type"
              >
                Currency
              </label>
              <select
                name="incomeType"
                defaultValue={currentUser.profile.incomeType}
                onChange={setData}
                className="profile-settings__additional-info-input profile-settings__additional-info-input--incometype"
              >
                <option value="" disabled defaultValue="Select Income Type">
                  Select Currency
                </option>
                <option value="Rs">Rs</option>
                <option value="Dollar">Dollar</option>
                <option value="Euro">Euro</option>
                <option value="Yen">Yen</option>
                <option value="Pound">Pound</option>
                <option value="Dirham">Dirham</option>
                <option value="Other">Other</option>
              </select>
              {currentUser.profile.incomeType === "Other" && (
                <input
                  name="otherIncomeType"
                  defaultValue={currentUser.profile.otherIncomeType}
                  onChange={setData}
                  className="register-form__input"
                  type="text"
                  placeholder="Enter custom income type"
                />
              )}


            </div>


            {/* <label
                className="profile-settings__additional-info-label"
                htmlFor="annual-income"
              >
                Annual Income
              </label> */}
            {/* <input
                  className="profile-settings__additional-info-input profile-settings__additional-info-input--annualIncomeExample"
                  name="annualIncomeExample"
                  defaultValue={currentUser.profile.annualIncomeExample}
                  onChange={setData}
                  type="number"
                 
                  placeholder="Annual Income"
                /> */}


            {/* <div className="profile-settings__additional-info-field">
            </div> */}
            <div className="profile-settings__additional-info-field">

              <div className="profile-settings__additional-info-field">
                <label
                  className="profile-settings__additional-info-label"
                  htmlFor="organizationExperienceYears"
                >
                  Current Org Experience (years)
                </label>
                <select
                  onChange={setData}
                  defaultValue={currentUser.profile.organizationExperienceYears}
                  name="organizationExperienceYears"
                  className="profile-settings__additional-info-input profile-settings__additional-info-input--current-org-experience-years"
                >
                  <option value="select" disabled defaultValue>
                    Select Years
                  </option>
                  {Array.from({ length: 51 }).map((_, i) => (
                    <option key={i} value={i + 0}>
                      {i + 0}
                    </option>
                  ))}
                </select>
              </div>


            </div>
            <div className="profile-settings__additional-info-field">

              <div className="profile-settings__additional-info-field">
                <label
                  className="profile-settings__additional-info-label"
                  htmlFor="organizationExperienceMonths"
                >
                  Current Org Experience (months)
                </label>
                <select
                  onChange={setData}
                  defaultValue={currentUser.profile.organizationExperienceMonths}
                  name="organizationExperienceMonths"
                  className="profile-settings__additional-info-input profile-settings__additional-info-input--current-org-experience-months"
                >
                  <option value="select" disabled defaultValue>
                    Select Months
                  </option>
                  {Array.from({ length: 13 }).map((_, i) => (
                    <option key={i} value={i + 0}>
                      {i + 0}
                    </option>
                  ))}
                </select>
              </div>


            </div>





            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="annual-income"
              >
                Annual Income
              </label>
              <input
                className="profile-settings__additional-info-input profile-settings__additional-info-input--annualIncomeExample"
                name="annualIncomeExample"
                defaultValue={currentUser.profile.annualIncomeExample}
                onChange={setData}
                type="number"
                placeholder="Annual Income"
              />
            </div>



            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="hobby"
              >
                Hobby
              </label>
              <input
                className="profile-settings__additional-info-input profile-settings__additional-info-input--hobby"
                type="text"
                name="hobby"
                defaultValue={currentUser.profile.hobby}
                onChange={setData}
                placeholder="Hobby"
              />
            </div>

            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="aboutMe"
              >
                About Me
              </label>
              <input
                className="profile-settings__additional-info-input profile-settings__additional-info-input--aboutme"
                type="text"
                name="aboutMe"
                defaultValue={currentUser.profile.aboutMe}
                onChange={setData}
              />
            </div>

            <div className="profile-settings__additional-info-field">
              <label
                className="profile-settings__additional-info-label"
                htmlFor="other-disabilities"
              >
                Other Disabilities
              </label>
              <input
                className="profile-settings__additional-info-input profile-settings__additional-info-input--other-disabilities"
                type="text"
                name="otherDisabilities"
                defaultValue={currentUser.profile.otherDisabilities}
                onChange={setData}
              />
            </div>







            <button
              className="profile-settings__additional-info-save-button"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      )}
    </>
  );
}

export default ProfileSettings;
