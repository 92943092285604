import React from "react";
import {MdComputer} from 'react-icons/md'
import "./PurchaseHistory.css";

const PurchaseHistory = () => {
  return (
    <>
      <div className="">
        <div className="">
          <h2 >Purchase History</h2>
          <table>
            <thead>
                <tr>
                    <th>Package</th>
                    <th>Validity</th>
                    <th>Price</th>
                    <th>Purchase Date</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Advance</td>
                    <td>999 Days</td>
                    <td>1000 Rs</td>
                    <td>11 Feb, 2023</td>
                    <td>Payment Pending</td>
                    <td><button className="table-btn"><MdComputer size={20}/></button></td>
                </tr>
            </tbody>

          </table>

    

          
        </div>
      </div>
    </>
  );
};

export default PurchaseHistory;
