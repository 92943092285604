import React, { useState, useEffect } from "react";
// import "./WhoShortlistedMe.css";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "../../axios";
import { Link } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination"; // Import TablePagination

const useStyles = makeStyles({
  table: {
    border: "none",
    "& th": {
      border: "none",
      fontWeight: "bold",
    },
  },
  avatar: {
    display: "inline-flex",
    marginRight: "1rem",
  },
});

const WhoViewedProfile = () => {
  const [ViewedMyProfile, setViewedMyProfile] = useState([]);
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page

  const getViewedMyProfile = async () => {
    await axios
      .get("/interactions/viewedmyprofile/")
      .then((response) => {
        setViewedMyProfile(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getViewedMyProfile();
  }, []);

  const classes = useStyles;

  // Calculate the index range for the currently visible items
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const calculateAge = (birthYear) => {
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear;
  };

  return (
    <div>
      <h2>Who Visited my Profile </h2>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Olakh Id</TableCell>
              <TableCell>Avatar</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Age</TableCell>
            {/* <TableCell>Action</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {ViewedMyProfile.slice(startIndex, endIndex).map((user, index) => (
              <TableRow key={user._id}>
                {/* <TableCell>{startIndex + index + 1}</TableCell> */}
                <TableCell> {user.userName?.match(/\d+/)}</TableCell>

                <TableCell>
                  <Link to={`/user-profile/${user._id}`}>
                    <Avatar
                      className={classes.avatar}
                      alt={ViewedMyProfile.firstName}
                      src={process.env.REACT_APP_SERVER_URL + user.profile.avatar}
                    />
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/user-profile/${user._id}`}>{user.firstName}</Link>
                </TableCell>
                {/* <TableCell>{user.profile.age}</TableCell> */}
                <TableCell>{calculateAge(user.profile.DobYear)}</TableCell>
                {/* <TableCell>
                  <Link to="#" onClick={() => handleDelete(user._id)}>
                    <IconButton>
                      <DeleteIcon style={{ color: "red" }} />
                    </IconButton>
                  </Link>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={ViewedMyProfile.length} // Total number of items
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={(event, newPage) => setPage(newPage)} // Handle page change
        onChangeRowsPerPage={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0); // Reset the page when the number of rows per page changes
        }}
      />
    </div>
  );
};

export default WhoViewedProfile;
