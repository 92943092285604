import React from "react";
import { Link } from "react-router-dom";
import './reidrect.css'
function Redirect() {
  return (
    <div>
      <h2  className=" redirect-upgrade-heading"></h2>
   
    </div>
  );
}

export default Redirect;
