import { useState, useEffect } from "react";
import "./UserLogin.css";
import {useSelector, useDispatch } from "react-redux";
import { login } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from "validator";

function UserLogin() {
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
  });
  const dispatch = useDispatch();
  const { isAuthenticated, loginError } = useSelector((state) => state.User);
  const navigate = useNavigate();
 
  useEffect(() => {
    if (isAuthenticated) {
      // Navigate to the dashboard if the user is authenticated
      toast.success('Login successful');
      navigate("/dashboard/info");
    } else if (loginError === 'Invalid credentials') {
      toast.error('Invalid credentials');
    }
  }, [isAuthenticated, loginError]);
  

  const { userName, password } = formData;
 
  const setData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    // Validate User Name
    if (userName.trim() === '') {
      toast.error('Username / Olakh ID is required');
      return false; // Stop form submission
    } else {
      // Remove spaces from the userName
      const userNameWithoutSpaces = userName.replace(/\s/g, '');
    
      // Now, you can work with userNameWithoutSpaces
      // For example, if you want to check if it's not empty:
      if (userNameWithoutSpaces === '') {
        toast.error('Username / Olakh ID must contain at least one non-space character');
        return false; // Stop form submission
      }
      // You can add more validation if needed here
    }
    // Validate Password
    if (password.trim() === '') {
      toast.error('Password is required');
      return false; // Stop form submission
    }

    // Set the formErrors state variable with the errors object
    setFormErrors(errors);

    // Return true if there are no errors, otherwise return false
    return Object.keys(errors).length === 0;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // Validate the form
    const isValid = validateForm();
    if (isValid) {
      try {
        if (!userName || !password) {
          return "Please enter username or password";
        }
        const response = await dispatch(login({ userName, password }));
  
        // Check if the API response contains an error message
        if (response.data.msg) {
          throw new Error(response.data.msg); // Throw an error with the API error message
        }
        // Continue with successful login logic
      } catch (error) {
        console.log("Error occurred:", error);
        toast.error(`Invalid Username or Password`); // Display the API error message in the toast notification
        // Display error notification
      }
    }
  };
  
  return (
    <>
      <ToastContainer />
      <form className="login-form" onSubmit={onSubmit}>
        <h1 className="login-form__heading">Login to your account</h1>
        <p className="login-form__paragraph">
          Please input your username and password and login to your account to
          get access to your dashboard.
        </p>

        <input
          className="login-form__input"
          name="userName"
          value={userName}
          onChange={setData}
          type="text"
          placeholder="Phone Number / Olakh ID"
        />

        <input
          className="login-form__input"
          name="password"
          value={password}
          onChange={setData}
          type="password"
          placeholder="Password"
        />

        <button className="login-form__submit-button" type="submit">
          Login
        </button>
      </form>
    </>
  );
}

export default UserLogin;
