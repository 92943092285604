import React from "react";
import { Link } from "react-router-dom";

function UnderReview() {
  return (
    <div
      style={{
        marginTop: "5rem",
        marginBottom: "5rem",
        width: "100%",
        textAlign: "center",
      }}
    >
      <h3>Your account is registered and reviewed by our team and will revert back. For more details contact : 9074151229 / 9479444774</h3>
      <p style={{ marginTop: "3rem" }}>
        Kindly make payment to proceed to 1 year subscription
      </p>
      <Link to="/membership">
      <button>Proceed To Payment</button>
      </Link>
    </div>
  );
}

export default UnderReview;
