import mail from "../assets/mail.svg";
import phone from "../assets/phone.svg";
import olakh_logo from "../assets/olakh-logo.png";
import avatar from "../assets/avatar.png"
import aboutUsImage from "../assets/all-success-stories-images.jpg"
import ourApproach from "../assets/our-approach.jpg"
import ourCommunity from "../assets/our-community.jpg"

import featuredImage1 from "../assets/featured-image-1.jpg"
import featuredImage2 from "../assets/featured-image-2.jpg"
import featuredImage3 from "../assets/featured-image-3.jpg"
import featuredImage4 from "../assets/featured-image-4.jpg"
import featuredImage5 from "../assets/featured-image-5.jpg"
import featuredImage6 from "../assets/featured-image-6.jpg"
import featuredImage7 from "../assets/featured-image-7.jpg"
import featuredImage8 from "../assets/featured-image-8.jpg"
import featuredImage9 from "../assets/featured-image-9.jpg"
import featuredImage10 from "../assets/featured-image-10.jpg"
import featuredImage11 from "../assets/featured-image-11.jpg"
import featuredImage12 from "../assets/featured-image-12.jpg"

import user from "../assets/user.png"
import search from "../assets/search.png"
import chat from "../assets/chat.png"
import successStory1 from "../assets/success-story-1.png"
import successStory2 from "../assets/success-story-2.png"
import successStory3 from "../assets/success-story-3.png"
import successStory4 from "../assets/success-story-4.png"
import successStory5 from "../assets/success-story-5.png"
import successStory6 from "../assets/success-story-6.png"
import successStory7 from "../assets/success-story-7.png"
import successStory8 from "../assets/success-story-8.png"
import messaging from "../assets/messaging.png"
import bell from "../assets/bell.png"
import heart from "../assets/heart.png"
import privacyandsecurity from "../assets/privacyandsecurity.png"
import match from "../assets/match.png"
import Group from "../assets/Group.png"
import lock from "../assets/lock.png"
import contact from "../assets/contact.jpg"
import olakhMobileAppAvailableOnPlayStore from "../assets/olakh-mobile-app-available-on-play-store.png"
import interestEmail from "../assets/interest-email.jpg"
import shortlistEmail from "../assets/shortlist-email.jpg"
import playStoreLogo from "../assets/play-store-logo.png"


import weddingRingCeremony from "../assets/wedding-ring-ceremony.jpg"

export default {
    mail, phone, olakh_logo, avatar, aboutUsImage, featuredImage1,featuredImage2,featuredImage3,featuredImage4,featuredImage5,featuredImage6,featuredImage7,featuredImage8,featuredImage9,featuredImage10,featuredImage11,featuredImage12, user, search, chat, successStory1, successStory2, successStory3, successStory4, successStory5, successStory6, successStory7, successStory8, messaging, bell, privacyandsecurity, match, Group, lock,weddingRingCeremony,ourApproach,ourCommunity,contact,olakhMobileAppAvailableOnPlayStore,interestEmail,shortlistEmail,heart,playStoreLogo,
};
