import "./App.css";
import Layout from "./layout";
import PrivateCheck from "./PrivateCheck";
// import Navbar from "./component/Navbar/Navbar";
import Home from "./screen/home/Home";
import About from "./screen/about/About";
import { BrowserRouter, Route, Routes, useNavigate, useLocation, useBeforeUnload   } from "react-router-dom";
import User from "./screen/userDashBoard/User";
import Shortlist from "./screen/shortlist/Shortlist";

import MyInterest from "./screen/My Interest/MyInterest";
import Ignoredlist from "./screen/Ignored List/Ignoredlist";
import Changepass from "./screen/Change Password/Changepass";
// import Footer from "./component/Footer/Footer";
import UserRegister from "./screen/Register/UserRegister";
import Sammelans from "./screen/sammelans/Sammelans";
import Membership from "./screen/membership/Membership";
import Event from "./screen/event/Event";
import SuccessStories from "./screen/successStories/SuccessStories";
import SearchResult from "./component/SearchResult/SearchResult";
import SearchOlakhId from "./component/searchOlakhId/SearchOlakhId";
import Contact from "./screen/contact/Contact";
import UserLogin from "./screen/userLogin/UserLogin";
import Dashboard from "./screen/dashboard/Dashboard";
import ProfileSettings from "./screen/profileSettings/ProfileSettings";
import UnderReview from "./screen/UnderReview";
import { useSelector, useDispatch } from "react-redux";
import { getMe, logout } from "./redux/actions";
import { Suspense, useEffect, useState } from "react";
import Gallery from "./screen/gallery/Gallery";
import SammelanUser from "./screen/sammelanUser/sammelanUser";
import PurchaseHistory from "./screen/purchase history/PurchaseHistory";
import UserProfile from "./screen/userProfile/UserProfile";
import TermsAndConditions from "./screen/termsAndConditions/termsAndConditions";
import PrivacyPolicy from "./screen/privacyPolicy/privacyPolicy";
import RefundPolicy from "./screen/refundPolicy/refundPolicy";
import Disclaimer from "./screen/disclaimer/disclaimer";
import ReportMisuse from "./screen/reportMisuse/reportMisuse";
import WhoShortlistedMe from "./screen/whoShortlistedMe/WhoShortlistedMe";
import Chats from "./screen/chats/Chats";
import SingleChat from "./screen/singleChat/SingleChat";
import InterestReceived from "./screen/interestReceived/InterestReceived";
import FAQ from "./component/Faq/Faq";
import Salman from "./screen/salman/Salman";
import WhoViewedProfile from "./screen/WhoViewedProfile/WhoViewedProfile";
import MyProfileViews from "./screen/MyProfileViews/MyProfileView";
import PublicProfile from "./screen/publicProfile/PublicProfile";
import axiosInstance from "./axios";
import { error } from "pdf-lib";

function App() {
  const dispatch = useDispatch();
 

  const { currentUser, isAuthenticated } = useSelector((state) => state.User);

 

  useEffect(() => {
    if (isAuthenticated && !currentUser) {
      dispatch(getMe());
    }
  }, [dispatch, currentUser, isAuthenticated]);


// 

  
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on route change
  }, [pathname]);

  return null;
}
  
  // ---------inform server that client is still active
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) return;

    const sendNotification = async () => {
      try {
        await axiosInstance.post(SERVER_URL + "/api/user/activeclient");
      } catch (error) {
        // console.error("Error in active mssg:", error.message);
        // Do nothing
      }
    };

    const sendNotificationInterval = setInterval(() => {
      sendNotification();
    }, 1 * 3 * 1000); //<-- time 10 sec

    return () => {
      clearInterval(sendNotificationInterval);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<></>}>
              <ScrollToTop />
          <Routes>
            <Route path="/" element={<Layout />}>
                
   
              <Route element={<Home />} path="/" />

              {/* PRIVATE ROUTES */}
              <Route path="/" element={<PrivateCheck />}>
                <Route element={<User />} path="/dashboard">
                  <Route element={<Dashboard />} path="/dashboard/info" />
                  <Route
                    element={<PurchaseHistory />}
                    path="/dashboard/purchase-history"
                  />
                  <Route
                    element={<ProfileSettings />}
                    path="/dashboard/profile-settings"
                  />
                  <Route element={<Shortlist />} path="/dashboard/shortlist" />

                  <Route
                    element={<WhoShortlistedMe />}
                    path="/dashboard/who-shortlisted-me"
                  />

                  <Route
                    element={<Changepass />}
                    path="/dashboard/change-password"
                  />
                  <Route element={<Gallery />} path="/dashboard/gallery" />
                  <Route
                    element={<MyInterest />}
                    path="/dashboard/my-interest"
                  />
                  <Route
                    element={<InterestReceived />}
                    path="/dashboard/interest-received"
                  />
                  <Route
                    element={<Ignoredlist />}
                    path="/dashboard/ignored-lists"
                  />
                  <Route element={<Chats />} path="/dashboard/chats" />
                  <Route
                    element={<SingleChat />}
                    path="/dashboard/singleChat/:id"
                  />
                  <Route
                    element={<WhoViewedProfile />}
                    path="/dashboard/who-viewed-my-profile"
                  />
                  <Route
                    element={<MyProfileViews />}
                    path="/dashboard/my-profile-views"
                  />
                 
              </Route>
              <Route
                    element={<PublicProfile />}
                    path="/public-profile"
                  />
                </Route>
              <Route element={<UnderReview />} path="/under-review" />

              {/* PUBLIC ROUTES */}
            
              <Route element={<Home />} path="/home" />
              <Route element={<About />} path="/about" />
              <Route element={<Sammelans />} path="/sammelans" />
              <Route element={<Membership />} path="/Membership" />
              <Route element={<Event />} path="/event" />
              <Route element={<Contact />} path="/contact" />
              <Route element={<FAQ />} path="/faq" />
              <Route element={<Salman />} path="/salman" />

              <Route
                element={<TermsAndConditions />}
                path="/terms-and-conditions"
              />
              <Route element={<PrivacyPolicy />} path="/privacy-policy" />
              <Route element={<RefundPolicy />} path="/refund-policy" />
              <Route element={<Disclaimer />} path="/disclaimer" />
              <Route element={<ReportMisuse />} path="/report-misuse" />

              <Route element={<UserRegister />} path="/Register" />
              <Route element={<UserLogin />} path="/userLogin" />
              <Route element={<SearchResult />} path="/search-result" />
              <Route element={<SearchOlakhId />} path="/search-olakhid" />
              <Route element={<SuccessStories />} path="/success-stories" />
              <Route element={<SammelanUser />} path="/sammelan/:id" />
              <Route element={<UserProfile />} path="/user-profile/:id" />

              <Route element={<UserRegister />} path="/user-register" />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
