import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination"; // Import TablePagination
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "../../axios";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    border: "none",
    "& th": {
      border: "none",
      fontWeight: "bold",
    },
  },
  avatar: {
    display: "inline-flex",
    marginRight: "1rem",
  },
});

const MyProfileViews = () => {
  const [MyProfileViews, setMyprofileViews] = useState([]);
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page

  const getViewedMyProfile = async () => {
    // Fetch data from the server
    await axios
      .get("/interactions/myviewedprofile/")
      .then((response) => {
        setMyprofileViews(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getViewedMyProfile();
  }, []);

  const handleDelete = (personId) => {
    // Handle user deletion
    axios
      .post(`/interactions/shortlistedme_delete/${personId}`)
      .then((response) => {
        console.log("User deleted successfully");
        window.location.reload();
        // Perform any additional actions after deletion
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
        // Handle any error that occurred during deletion
      });
  };

  const classes = useStyles;

  // Calculate the index range for the currently visible items
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const calculateAge = (birthYear) => {
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear;
  };
  const filteredMyProfileViews = MyProfileViews.filter((user) => user !== null);
  console.log(filteredMyProfileViews)
  return (
    <div>
      <h2>My Visited Profiles</h2>

  <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Olakh Id</TableCell>
              <TableCell>Profile Pic</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Age</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMyProfileViews.slice(startIndex, endIndex).map((user, index) => (
              <TableRow key={user?._id}>
                {/* <TableCell>{startIndex + index + 1}</TableCell> */}
                <TableCell> {user?.userName.match(/\d+/)}</TableCell>

                <TableCell>
                  <Link to={`/user-profile/${user?._id}`}>
                    <Avatar
                      className={classes.avatar}
                      alt={user?.firstName}
                      src={process.env.REACT_APP_SERVER_URL + user?.profile.avatar}
                    />
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/user-profile/${user?._id}`}>{user?.firstName}</Link>
                </TableCell>
                {/* <TableCell>{user?.profile.age}</TableCell> */}
                <TableCell>{calculateAge(user?.profile.DobYear)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      
      <TablePagination
        component="div"
        count={filteredMyProfileViews?.length} // Total number of items
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={(event, newPage) => setPage(newPage)} // Handle page change
        onChangeRowsPerPage={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0); // Reset the page when the number of rows per page changes
        }}
      />
    </div>
  );
};

export default MyProfileViews;
