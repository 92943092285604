import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import './footer.css';
import axios from "axios"
import { Link } from 'react-router-dom';

const Footer = () => {
    const [about, setAbout] = useState()

    const fetchAbout = async () => {
        const res = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/website-cms`);

        const data = res.data.footerAbout
        setAbout(data)
        console.log(data)

    }
    useEffect(() => {
        fetchAbout();
    }, [])
    return (
        <>
            <footer className="Footer">
                <div className="Footer-column">
                    <h2>About Us</h2>
                    <p>Olakh.info is the fastest growing maharashtrian matrimony platform in Madhya Pradesh, Chattisgarh, Maharashtra, Rajasthan, Uttar Pradesh & Gujarat founded by MR. ANAND CHANDORKAR.

                    </p>
                </div>
                <div className="Footer-column">
                    <h2>Quick Links</h2>
                    <ul>
                        <li><Link to="/home">Home</Link></li>
                        <li><Link to="/about">About Us</Link></li>
                        <li><Link to="/sammelans">Sammelans</Link></li>
                        <li><Link to="/membership">Membership</Link></li>
                        <li><Link to="/success-stories"> Success Stories</Link></li>
                        <li><Link to="/event"> Event</Link></li>
                        <li><Link to="/contact"> Contact Us</Link></li>
                        <li><Link to="/faq">FAQ</Link></li>
                    </ul>
                </div>
                <div className="Footer-column">
                    <h2>Policies</h2>
                    <ul>
                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                        <li><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
                        <li><Link to="/refund-policy">Refund Policy</Link></li>
                        <li><Link to="/disclaimer">Disclaimer</Link></li>
                        <li><Link to="/report-misuse">Report Misuse</Link></li>
                    </ul>
                </div>
                <div className="Footer-column col-4th">
                    <h2>Contact Us</h2>
                    <ul>
                        <li><FontAwesomeIcon icon={faPhone} /><a  href="tel:+9107314700547" target='_blank'> +91 7314700547</a></li>
                        <li><FontAwesomeIcon icon={faEnvelope} /><a  href="mailto:info@olakh.info" target='_blank'> info@olakh.info</a></li>
                        <li><FontAwesomeIcon icon={faMapMarkerAlt} /><a  href="https://www.google.com/maps?ll=22.703899,75.867525&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=1217482825393851418" target='blank'> Olakh Swapnanchi, near, C/o Mai Mangeshkar Sabhagruh, Marathi Samaj 31, Loha Mandi, Panchshil Nagar, Indore, Madhya Pradesh 452001</a></li>
                    </ul>
                </div>

                <div className='Footer-bottom'><p>Copyright © 2023. All Rights Reserved By Olakh.info</p></div>
            </footer>
        </>
    );
}

export default Footer;
