import React, {useState, useEffect} from 'react';
import CountUp from 'react-countup';
import "./FactsAndFigures.css";  
import axios from 'axios';

const FactsAndFigures = () => {



  const[factsAndFigures, SetfactsAndFigures] = useState([]);

  const fetchFact = async ()=>{
    const res = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/website-cms`)
    const data = res.data.factsAndFigures
    SetfactsAndFigures(data)
    console.log(data)
  }


  useEffect(()=>{
    fetchFact();
  },[])
  return (
    <section className="facts-and-figures">
      <h2 className="facts-and-figures__heading">Achievement Milestones at Olakh Matrimonial</h2>
      <div className="facts-and-figures__columns">
        {factsAndFigures.map((item, index) => (
          <div className="facts-and-figures__column" key={index}>
            <h3 className="facts-and-figures__number">
              <CountUp end={item.factNumber} duration={3} />
            </h3>
            <h4 className="facts-and-figures__title">{item.factTitle}</h4>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FactsAndFigures;
