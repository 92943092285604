import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { searchPartner } from "../../redux/actions";
import { image } from "../../constants";
import { AiFillHeart, AiOutlineStar } from "react-icons/ai";
import { FaUserTimes, FaUserCircle } from "react-icons/fa";
import { MdReport } from "react-icons/md";
import "./search-result.css";
import axios from "axios";
import Redirect from "../../component/Redirect/Redirect";
import authAxios from "../../axios";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import MessageIcon from "@material-ui/icons/Message";

function SearchResult() {
  const navigate = useNavigate();
  const [lastViewedPage, setLastViewedPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(() => {
    const savedCurrentPage = localStorage.getItem("currentPage");
    return savedCurrentPage ? parseInt(savedCurrentPage, 10) : 1;
  });

  const [perPage, setPerPage] = useState(5);
  const [disableBtnClass, setDisableBtnClass] = useState("null");

  const [isShortlisted, setIsShortlisted] = useState(false);
  const { searchResponse, pagination } = useSelector((state) => state.Search);
  // console.log('pagination ', pagination.totalPages)
  const totalPages = pagination.totalPages;

  const { isStandard } = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState(null);
  const { targetId } = useParams();
  const [valueNotification, setvalueNotification] = useState();
  // const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { isAuthenticated } = useSelector((state) => state.User);

  // const[totalPages, setTotalPages] = useState(1)

  const getMe = async (setCurrentUser) => {
    try {
      const res = await authAxios.get(
        process.env.REACT_APP_ADMIN_BASE_URL_SERVER + "/user/me"
      );
      setCurrentUser(res.data.user);
    } catch (error) {
      console.log(error.message);
    }
  };
  const calculateAge = (birthYear) => {
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear;
  };

  // handle profile view
  const handleViewProfile = async (targetId) => {
    try {
      const response = await authAxios.post(
        `/interactions/profile-view/${targetId}`
      );

      // Handle the response as needed
      // console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };

  const handleNotificationClick = async (targetId) => {
    try {
      const response = await authAxios.patch(
        `/interactions/shortlist/${targetId}`,
        {}
      );

      // Check if API request was successful
      if (response.status === 200) {
        setIsShortlisted(true);

        // Close the notification after 3 seconds
        setTimeout(() => {
          setIsShortlisted(false);
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const filteredResults = (searchResponse?.data?.users ?? []).filter((user)  => {
  //   const userGender = user.profile.gender;
  //   const userAge = calculateAge(user.profile.DobYear);
  //   const currentUserGender = currentUser?.profile.gender;

  //   // Check if the user's gender is different from the currentUser's gender
  //   if (
  //     (currentUserGender === 'Male' && userGender === 'Female') ||
  //     (currentUserGender === 'Female' && userGender === 'Male')
  //   ) {
  //     return true;
  //   }

  //   return false;
  // });
  const filteredResults = (searchResponse?.users ?? []).filter((user) => {
    const userGender = user.profile.gender;
    const currentUserGender = currentUser?.profile.gender;

    // Check if the user's gender is different from the currentUser's gender
    return userGender !== currentUserGender;
  });

  const handleInterestClick = async (targetId) => {
    try {
      const response = await authAxios.patch(
        `/interactions/interest/${targetId}`,

        {}
      );

      // Check if API request was successful
      if (response.status === 200) {
        setIsOpen(true);
        setvalueNotification(targetId);
        // Close the notification after 3 seconds
        setTimeout(() => {
          setIsOpen(false);
        }, 3000);
      }
    } catch (error) {
      console.error(error.response);
    }
  };

  const fetchSearchResults = (page) => {
    const body = JSON.parse(localStorage.getItem("searchBody"));
    dispatch(searchPartner(body, page, perPage));
    // console.log('current page', page)
    setCurrentPage(page);
  };

  useEffect(() => {
    const savedCurrentPage = localStorage.getItem("currentPage");
    if (savedCurrentPage) {
      setCurrentPage(savedCurrentPage);
    } else {
      setCurrentPage(1); // Default to page 1 if no previous page is stored
    }

    getMe(setCurrentUser);
    fetchSearchResults(currentPage);
  }, []);

  const pageChangeHandler = (page) => {
    // console.log("Current Page:", currentPage); // Log the current page
    localStorage.setItem("currentPage", page.toString());
    setCurrentPage(page);
    // console.log("New Page:", page); // Log the new page
    fetchSearchResults(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Sidebar filter hide show on mobile
  const [isOpen, setIsOpen] = useState(false);

  function TruncatedText({ text, maxLength }) {
    const [truncate, setTruncate] = useState(true);
    const shouldTruncate = text.length > maxLength;

    const toggleTruncate = () => {
      setTruncate(!truncate);
    };

    return (
      <div>
        <p>
          {truncate ? text.slice(0, maxLength) : text}
          {shouldTruncate && (
            <span
              onClick={toggleTruncate}
              style={{ cursor: "pointer", color: "black" }}
            >
              {truncate ? (
                <>
                  ...
                  <MdExpandMore size={20} />{" "}
                </>
              ) : (
                <>
                  <MdExpandLess size={20} />
                </>
              )}
            </span>
          )}
        </p>
      </div>
    );
  }

  // render pagination func
  const renderPagination = () => {
    const pagesToShow = 5;
    const halfPagesToShow = Math.floor(pagesToShow / 2);
    const startPage = Math.max(1, currentPage - halfPagesToShow);
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    return (
      <div className="pagination">
        <button
          className="load-more-button"
          onClick={() => pageChangeHandler(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>

        {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
          <span
            key={startPage + index}
            className={currentPage === startPage + index ? "active" : ""}
            onClick={() => pageChangeHandler(startPage + index)}
          >
            {startPage + index}
          </span>
        ))}

        <button
          className="load-more-button"
          onClick={() => pageChangeHandler(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="search-result__container">
        {filteredResults.length > 0 ? (
          <>
            {" "}
            <div className="search-result__user-card">
              <h2 className="search-result__heading">Search Results</h2>
              <div className="search-result__user-data">
                {filteredResults.map((user) => (
                  <div key={user._id} className="search-result__user">
                    <div
                      style={{ border: "1px solid black" }}
                      className="search-result__user-image"
                    >
                      <img
                        src={
                          process.env.REACT_APP_SERVER_URL + user.profile.avatar
                        }
                        alt=""
                      />
                    </div>
                    <div className="search-result__user-content">
                      <div>
                        {/* <h3 className="search-result__user-name">
                      {user.firstName + " " + user.surname}
                    </h3> */}
                        <h3 className="search-result__user-name">
                          Member ID: <span>{user.userName}</span>
                        </h3>
                      </div>

                      <div className="search-result__user-content__details-box">
                        <div className="search-result__user-content__details-box-column">
                          {isAuthenticated && (
                            <>
                              <div className="search-result__user-content__detail  ">
                                <div className="search-result__user-content__age-label">
                                  <strong> Name </strong>
                                </div>
                                <div className="search-result__user-content__age-value">
                                  <p>{`${user.firstName} ${user.surname}`} </p>
                                </div>
                              </div>
                            </>
                          )}
                          {/* <div className="search-result__user-content__detail  ">
                        <div className="search-result__user-content__age-label">
                          <strong> Age: </strong>
                        </div>
                        <div className="search-result__user-content__age-value">
                          <p>{calculateAge(user.profile.DobYear)} Years </p>
                        </div>
                      </div> */}

                          <div className="search-result__user-content__detail">
                            <div className="search-result__user-content__DOB-label">
                              <strong>Date Of Birth: </strong>
                            </div>
                            <div className="search-result__user-content__Dob-value">
                              <p>
                                {user.profile.DobDay +
                                  "-" +
                                  user.profile.DobMonth +
                                  "-" +
                                  user.profile.DobYear}
                              </p>
                            </div>
                          </div>

                          <div className="search-result__user-content__detail">
                            <div className="search-result__user-content__mangal-label">
                              <strong>Mangal: </strong>
                            </div>
                            <div className="search-result__user-content__mangal-label">
                              <p>{user.profile?.mangalik}</p>
                            </div>
                          </div>

                          {/* <div className="search-result__user-content__detail">
                        <div className="search-result__user-content__gender-label">
                          <strong>Looking for: </strong>
                        </div>
                        <div className="search-result__user-content__gender-value">
                          <p>{user.profile.gender === 'Male' ? 'Female' : 'Male'} </p>
                        </div>
                      </div> */}

                          {/* <div className="search-result__user-content__detail">
                        <div className="search-result__user-content__email-label">
                          <strong>Email: </strong>
                        </div>
                        <div className="search-result__user-content__email-value">
                          <p>{user.email} </p>
                        </div>
                      </div> */}

                          <div className="search-result__user-content__detail">
                            <div className="search-result__user-content__caste-label">
                              <strong>Caste: </strong>
                            </div>
                            <div className="search-result__user-content__caste-value">
                              <p>{user.profile.caste} </p>
                            </div>
                          </div>

                          <div className="search-result__user-content__detail">
                            <div className="search-result__user-content__martial-status-label">
                              <strong>Martial Status: </strong>
                            </div>
                            <div className="search-result__user-content__martial-status-value">
                              <p> {user.profile.maritialStatus} </p>
                            </div>
                          </div>
                        </div>

                        <div className="search-result__user-content__details-box-column">
                          <div className="search-result__user-content__detail">
                            <div className="search-result__user-content__complexion-label">
                              <strong>Complexion: </strong>
                            </div>
                            <div className="search-result__user-content__complexion-value">
                              <p> {user.profile.complexion} </p>
                            </div>
                          </div>

                          <div className="search-result__user-content__detail">
                            <div className="search-result__user-content__height-label">
                              <strong>Height: </strong>
                            </div>
                            <div className="search-result__user-content__height-value">
                              <p> {user.profile.height} </p>
                            </div>
                          </div>

                          <div className="search-result__user-content__detail">
                            <div className="search-result__user-content__city-label">
                              <strong>City: </strong>
                            </div>
                            <div className="search-result__user-content__city-value">
                              <p> {user.profile.city} </p>
                            </div>
                          </div>
                          <div className="search-result__user-content__detail">
                            <div
                              className="search-result__user-content__education-label"
                              style={{ marginRight: "5px" }}
                            >
                              <strong>Education: </strong>
                            </div>
                            <div
                              className="search-result__user-content__education-value"
                              style={{ maxWidth: "210px" }}
                            >
                              <TruncatedText
                                text={`${user.profile.qualification}`}
                                maxLength={20}
                              />
                            </div>
                          </div>

                          <div className="search-result__user-content__detail">
                            <div
                              className="search-result__user-content__job-title-label"
                              style={{ marginRight: "5px" }}
                            >
                              <strong>Job Title: </strong>
                            </div>
                            <div
                              className="search-result__user-content__job-title-value"
                              style={{ maxWidth: "210px" }}
                            >
                              <TruncatedText
                                text={`${user.profile.completeOccupationDetails}`}
                                maxLength={20}
                              />
                            </div>
                          </div>
                          <div className="sammelan-user__user-content__detail">
                            <div
                              className="sammelan-user__user-content__job-title-label"
                              style={{ marginRight: "5px" }}
                            >
                              <strong>{`Annual Income:`}</strong>
                            </div>
                            {user?.profile.annualIncomeExample ? (
                              <div className="sammelan-user__user-content__job-title-value">
                                <TruncatedText
                                  text={`${user.profile.annualIncomeExample}`}
                                  maxLength={20}
                                />
                              </div>
                            ) : (
                              <div>N/A</div>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* Interaction buttons start */}

                      <div className="search-result__user-interaction-container">
                        {isAuthenticated ? (
                          <Link
                            to={`/user-profile/${user._id}`}
                            onClick={() => handleViewProfile(user._id)}
                          >
                            <div className="sammelan-user__user-interaction-box">
                              <FaUserCircle className="sammelan-user__user-interaction-box__view-full-profile-icon" />
                              <p>View Full Profile</p>
                            </div>
                          </Link>
                        ) : (
                          // Navigate the user to another location, like a login page
                          <Link to="/register">
                            <div className="sammelan-user__user-interaction-box">
                              <FaUserCircle className="sammelan-user__user-interaction-box__view-full-profile-icon" />
                              <p>View Full Profile</p>
                            </div>
                          </Link>
                        )}

                        <div
                          className="sammelan-user__user-interaction-box"
                          onClick={() => handleInterestClick(user._id)}
                        >
                          <AiFillHeart className="sammelan-user__user-interaction-box__interest-icon" />
                          <p>Interest</p>

                          {isOpen && user._id === valueNotification && (
                            <div className="notification">
                              <p>{`You have shown interest in ${
                                user.firstName + user.surname
                              }`}</p>
                            </div>
                          )}
                        </div>

                        <div className="sammelan-user__user-interaction-box">
                          <AiOutlineStar
                            className="sammelan-user__user-interaction-box__shortlist-icon"
                            onClick={() => handleNotificationClick(user._id)}
                          />
                          <p>Shortlist</p>

                          {isShortlisted && (
                            <div className="notification">
                              <p>{`You have shortlisted ${
                                user.firstName + user.surname
                              }`}</p>
                            </div>
                          )}
                        </div>

                        <div
                          className="sammelan-user__user-interaction-box"
                          onClick={() =>
                            navigate(`/dashboard/singleChat/${user._id}`)
                          }
                        >
                          <MessageIcon
                            fontSize="small"
                            className="sammelan-user__user-interaction-box__interest-icon message-icon"
                          />
                          <p>Message</p>

                          {/* {isOpenInterest && user._id === valueInterest && (
                      <div className="notification">
                        <p>{`You have shown interest in ${user.firstName + user.surname}`}</p>
                      </div>
                    )} */}
                        </div>
                      </div>
                      {/* Interaction buttons end */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <> No more users to show go to previous page. </>
        )}
      </div>

      {renderPagination()}
    </>
  );
}

export default SearchResult;
