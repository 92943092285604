import React, { useState, useEffect } from "react";
import axios from "axios";
import "./termsAndConditions.css";

function TermsAndConditions() {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetchExistingContent();
  }, []);

  const fetchExistingContent = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/website-cms/`);
        console.log( response.data);
        const existingContent = response.data?.termsAndConditions;
       
      if (existingContent) {
        setContent(existingContent);
      }
    } catch (error) {
      console.error('Error fetching existing content:', error);
      // Handle the error case
    }
  };

  return (
    <>
      <div className="terms-and-conditions">
        <h1>Terms & Conditions</h1>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </>
  );
}

export default TermsAndConditions;
 