import React, { useEffect, useState } from 'react'
import './card.css'
import authAxios from "../../axios";

var id = null
const getMe = async () => {
  try {
    const res = await authAxios.get(
      process.env.REACT_APP_ADMIN_BASE_URL_SERVER + "/user/me"
    );
    return res.data.user._id

  } catch (error) {
    console.log(error.message);
  }
};

const Card = ({ icon, number, title, className, iconStyle }) => {


  const [webSocket, setWebSocket] = useState(null);
  useEffect(() => {
    // Connect to WebSocket server
    const ws = new WebSocket('ws://127.0.0.1:3001');


    ws.onopen = async () => {
      id = await getMe();
      // console.log(id);
      // Send user ID or any other necessary information
      ws.send(JSON.stringify({ userID: id }));


    };

    ws.onmessage = async (message) => {
      id = await getMe();
      ws.send(JSON.stringify({ userID: id }));

      // console.log('Message from server ', message.data,);
    };


    // window.addEventListener('beforeunload', () => {
    //   ws.onclose = () => {

    //     ws.send(JSON.stringify("closed"));
  
    //     console.log('Disconnected from WebSocket');
    //   };
  
    //   localStorage.removeItem('token'); // Replace 'yourTokenKey' with your actual token key
    // });
    
    setWebSocket(ws);

    // Clean up function
    return () => {
      ws.close(); // Close WebSocket connection
    };
  }, []);

  return (
    <>
      <div className={`card ${className}`}>
        <div className="card-icon" style={{ backgroundColor: iconStyle }}> {icon} </div>
        <div className="card-info">
          <h2>{number}</h2>
          <p>{title}</p>
        </div>
      </div>
    </>
  )
}

export default Card
