import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import { Outlet } from "react-router-dom";
import authAxios from "../axios";

import Mobilenav from "../component/mobilebottomnav/Mobilenav";
import Activity from "../component/Activity";
import "./index.css"
import { useEffect, useState } from "react";


const Layout = () => {

 
  return (
    <>
      <Navbar />
      {/* <Activity/> */}
      <main>
        <Outlet />
      </main>
      <div className="mobilenav-container" style={{ position: "fixed", bottom: 0, width: "100%" }}>
        <Mobilenav />

      </div>
      <Footer />
    </>
  );
};

export default Layout;
