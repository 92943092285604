import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './TestimonialsSlider.css';
import axios from "axios"

const TestimonialsSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const [reviews, setReviews] = useState([]);
  const fetchReviews = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/reviews`);   
      const data = response.data.review; // Access the 'review' array from the response data
      setReviews(data);
    } catch (error) {
      console.log(error);
    }
  }
  
  useEffect(() => {
    fetchReviews();
  }, []);

  return (
    <>
      <div className='testimonial-slider-content'>
        <h2>Testimonials</h2>
        <p>Our satisfied users share their success stories and experiences with our matrimonial website.</p>
      </div>
      <div className="testimonial-slider">
        <Slider {...settings}>
          {reviews.map(review => (
            <div className='testimonial-slider-single' key={review._id}>
              <img src={process.env.REACT_APP_SERVER_URL + review.reviewImage} alt={`${review.reviewBrideName} & ${review.reviewGroomName}`} />
              <h3>{`${review.reviewBrideName} & ${review.reviewGroomName}`}</h3>
              <p>{review.reviewDesc}</p>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default TestimonialsSlider;
