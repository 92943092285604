import React, { useState, useEffect, useRef } from "react";
import "./popup.css";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";

function Popup() {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef();
  const [eventPopup, seteventPopup] = useState();

  const getPopupData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/website-cms`);
      seteventPopup(response.data.eventPopup);
      // console.log(response.data)
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getPopupData();
      // Do something with the fetched data, such as setting it in state
      // setPopupData(data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const popupTimeout = setTimeout(() => {
      setShowPopup(true);
    }, 5000);
    return () => clearTimeout(popupTimeout);
  }, []);

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {showPopup && eventPopup && (
  <div className="popup">
    <div className="popup-content" ref={popupRef}>
      <div style={{position : 'relative', zIndex : '5'}}> 
      <button className="close-button" onClick={handleClose}>
        &times;
      </button>
      </div>
      <div className="terms-and-conditions tnc-mobile">
      
        <style>{`.ql-container.ql-snow.ql-disabled { border: none; }`}</style>

        <ReactQuill
          value={eventPopup}
          readOnly
          theme="snow"
          modules={{ toolbar: false }}
        />
      </div>
      {/* <p>{popupData.eventPopup.popupBody}</p> */}
    </div>
  </div>
)}

    </>
  );
}

export default Popup;
