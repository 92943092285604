import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

const RefundPolicy = () => {
  const [disclaimer, setdisclaimer] = useState('');

  useEffect(() => {
    fetchRefundPolicy();
  }, []);

  const fetchRefundPolicy = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/website-cms/`);
    //   console.log(response.data);

      if (response.status === 200) {
        const { disclaimer } = response.data; // Correctly extract the refunds data
        setdisclaimer(disclaimer); // Update the state variable
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  const toolbarOptions = {
    toolbar: false, // Hide the toolbar
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center', margin: '1rem' }}>Disclaimer</h1>
      <style>{`.ql-container.ql-snow.ql-disabled { border: none; }`}</style>
      <ReactQuill
        value={disclaimer}
        readOnly
        theme="snow"
        modules={toolbarOptions}
      />
    </div>
  );
};

export default RefundPolicy;
