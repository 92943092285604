import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./UserRegister.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import necessary validation functions or packages
// For example, you can use the `validator` package for validation
import validator from "validator";

// gotraData,
import {
  casteData,
  brahminSubCasteData,
  heightData,
  maritalStatusData,
  manglikData,
  rashiData,
  naadiData,
  charanData,
  nakshatraData,
  complexionData,
  bodyTypeData,
  birthMonthData,
} from "../../data/UserData";

// import axios from "axios";

function UserRegister() {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    // Perform validations for each form field
    // Validate Profile Image
    // if (!profileImage) {
    //   toast.error('Profile image is required');
    //   return false; // Stop form submission
    // }
    // Perform additional checks if needed (e.g., file type, file size, etc.)
    // Example:
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxFileSize = 5 * 1024 * 1024; // 5MB

    if (profileImage && !allowedFileTypes.includes(profileImage.type)) {
      toast.error(
        "Invalid file type. Please select a JPEG or JPG or PNG image"
      );
    }

    if (profileImage && profileImage.size > maxFileSize) {
      toast.error("File size exceeds the maximum limit of 5MB");
    }

    // Validate Name Prefix
    if (formData.prefix === "") {
      toast.error("Name Prefix is required");
      return false; // Stop form submission
    } else if (
      formData.prefix === "Other" &&
      formData.otherPrefix.trim() === ""
    ) {
      toast.error("Custom Name Prefix is required");
      return false; // Stop form submission
    }
    // Validate Custom Name Prefix
    if (
      formData.prefix === "Other" &&
      formData.otherPrefix.trim() !== "" &&
      !validator.isAlpha(formData.otherPrefix.trim())
    ) {
      toast.error("Custom Name Prefix must contain only alphabetic characters");
      return false; // Stop form submission
    }
    // Validate First name
    if (formData.firstName === "") {
      toast.error("Candidate Name is required");
      return false; // Stop form submission
    } else if (formData.firstName.includes(" ")) {
      toast.error("First Name should not contain spaces");
      return false; // Stop form submission
    } else if (!validator.isAlpha(formData.firstName)) {
      toast.error("First Name must contain only alphabetic characters");
      return false; // Stop form submission
    }

    // Validate Surname
    if (formData.surname === "") {
      toast.error("Surname is required");
      return false; // Stop form submission
    } else if (!validator.isAlpha(formData.surname.replace(/\s/g, ""))) {
      toast.error("Surname must contain only alphabetic characters");
      return false; // Stop form submission
    }

    // Validate Father Prefix
    if (formData.fatherPrefix === "") {
      toast.error("Father's Prefix is required");
      return false; // Stop form submission
    } else if (
      formData.fatherPrefix === "Other" &&
      formData.fatherOtherPrefix.trim() === ""
    ) {
      toast.error("Custom Father's Prefix is required");
      return false; // Stop form submission
    }
    // Validate Custom Father's Prefix
    if (
      formData.fatherPrefix === "Other" &&
      formData.fatherOtherPrefix.trim() !== "" &&
      !validator.isAlpha(formData.fatherOtherPrefix.trim())
    ) {
      toast.error(
        "Custom Father's Prefix must contain only alphabetic characters"
      );
      return false; // Stop form submission
    }
    // Validate Father Name
    if (formData.fatherName.trim() === "") {
      toast.error("Father's Name is required");
      return false; // Stop form submission
    } else if (!/^[A-Za-z\s]+$/.test(formData.fatherName.trim())) {
      toast.error("Father's Name must contain only alphabetic characters");
      return false; // Stop form submission
    }

    // Validate Mother Prefix
    if (formData.motherPrefix === "") {
      toast.error("Mother's Prefix is required");
      return false; // Stop form submission
    } else if (
      formData.motherPrefix === "Other" &&
      formData.motherOtherPrefix.trim() === ""
    ) {
      toast.error("Custom Mother's Prefix is required");
      return false; // Stop form submission
    }
    // Validate Custom Mother's Prefix
    if (
      formData.motherPrefix === "Other" &&
      formData.motherOtherPrefix.trim() !== "" &&
      !validator.isAlpha(formData.motherOtherPrefix.trim())
    ) {
      toast.error(
        "Custom Mother's Prefix must contain only alphabetic characters"
      );
      return false; // Stop form submission
    }
    // Validate Mother Name
    if (formData.motherName.trim() === "") {
      toast.error("Mother's Name is required");
      return false; // Stop form submission
    } else if (!/^[A-Za-z\s]+$/.test(formData.motherName.trim())) {
      toast.error("Mother's Name must contain only alphabetic characters");
      return false; // Stop form submission
    }

    // Validate Email
    // if (formData.email !== '' && !validator.isEmail(formData.email)) {
    //   toast.error('Please enter a valid email address');
    //   return false; // Stop form submission
    // }

    // // Validate Date of Birth
    // if (formData.dateOfBirth.day === 'Day Of Birth') {
    //   toast.error('Please select a valid day of birth');
    //   return false; // Stop form submission
    // }
    // // Validate Month of Birth
    // if (formData.dateOfBirth.month === 'Month Of Birth') {
    //   toast.error('Please select a valid birth month');
    //   return false; // Stop form submission
    // }
    // // Validate Year of Birth
    // if (formData.dateOfBirth.year === 'Year Of Birth') {
    //   toast.error('Please select a valid birth year');
    //   return false; // Stop form submission
    // }
    // // Validate Birth time AM/PM
    // if (formData.dateOfBirth.ampm === 'Birth time AM/PM') {
    //   toast.error('Please select a valid AM/PM');
    //   return false; // Stop form submission
    // }

    // // Validate Birth time in hours
    // if (formData.dateOfBirth.hours === 'Birth time hour') {
    //   toast.error('Please select a valid birth time hour');
    //   return false; // Stop form submission
    // }
    // // Validate Birth time in minutes
    // if (formData.dateOfBirth.minutes === 'Birth time minutes') {
    //   toast.error('Please select a valid birth time minutes');
    //   return false; // Stop form submission
    // } 


    if (formData.DobYear === '') {
      toast.error('Please select a valid Year');
      return false; // Stop form submission
    }

    //  Validate City
    if (formData.city === "") {
      toast.error("City is required");
      return false; // Stop form submission
    }
    //  Validate Pincode
    if (!validator.isNumeric(formData.pincode.trim())) {
      toast.error("Pin code must contain only numeric characters");
      return false; // Stop form submission
    } else if (formData.pincode.trim().length !== 6) {
      toast.error("Pin code must be 6 digits long");
      return false; // Stop form submission
    }

    //  Validate Gender
    if (formData.gender === "gender") {
      toast.error("Gender is required");
      return false; // Stop form submission
    }
    //  Validate Phone Number
    if (formData.phoneNumber.trim() === "") {
      toast.error("Phone number is required");
      return false; // Stop form submission
    } else if (!/^(\+91|0)?[6789]\d{9}$/.test(formData.phoneNumber.trim())) {
      toast.error("Invalid phone number");
      return false; // Stop form submission
    }
    // Validate WhatsApp Number
    if (formData.whatsappNumber.trim() === "") {
      toast.error("WhatsApp number is required");
      return false; // Stop form submission
    } else if (!/^(\+91|0)?[6789]\d{9}$/.test(formData.whatsappNumber.trim())) {
      toast.error("Invalid WhatsApp number");
      return false; // Stop form submission
    }

    //  Validate Caste and custom caste
    if (formData.caste === "Select Caste") {
      toast.error("Caste is required");
      return false; // Stop form submission
    } else if (formData.caste === "Other") {
      if (formData.otherCaste.trim() === "") {
        toast.error("Custom Caste is required");
        return false; // Stop form submission
      }
    }
    //  Validate Shakha from brahmin caste
    // if (formData.caste === "Brahmin" && formData.subCaste === "-") {
    //   toast.error('Shakha is required');
    //   return; // Stop form submission
    // }
    //  Validate Maritial Status
    if (formData.maritialStatus === "Select Marital Status") {
      toast.error("Marital Status is required");
      return false; // Stop form submission
    }
    // Validate permanentAddress field
    if (formData.permanentAddress === "") {
      toast.error("Postal Address is required");
      return false; // Stop form submission
    }

    // Validate otherContactInfo field
    // if (!/^[0-9]{1,10}$/.test(formData.otherContactInfo.trim())) {
    //   toast.error('Other Contact Number must contain only numeric values and have a maximum length of 10');
    //   return false; // Stop form submission
    // }

    // Validate Birth Place field
    if (formData.birthPlace.trim() === "") {
      toast.error("Birth Place is required");
      return false; // Stop form submission
    } else if (!validator.isAlpha(formData.birthPlace.trim())) {
      toast.error("Birth Place must contain only alphabetic characters");
      return false; // Stop form submission
    }
    // Validate no Of Brothers field
    if (formData.noOfBrothers === "No. of Brothers") {
      toast.error("Please select the number of brothers");
      return false; // Stop form submission
    }
    // Validate no Of Sisters field
    if (formData.noOfSisters === "No. of Sisters") {
      toast.error("Please select the number of sisters");
      return false; // Stop form submission
    }
    // Validate blood Group field
    // if (formData.bloodGroup === "Select Blood Group") {
    //   toast.error('Please select a blood group');
    //   return false; // Stop form submission
    // }

    // Validate height field
    if (formData.height === "Select Height") {
      toast.error("Please select a height");
      return false; // Stop form submission
    }
    // Validate Gotra field
    if (formData.gotra === "Other") {
      if (!validator.isAlpha(formData.otherGotra.trim())) {
        toast.error("Gotra details must contain only alphabetic characters");
        return false; // Stop form submission
      }
    }

    // Validate Mangalik field
    if (formData.mangalik === "Mangalik") {
      toast.error("Please select a Mangalik option");
      return false; // Stop form submission
    }
    // Validate Rashi field
    // if (formData.rashi === "Rashi") {
    //   toast.error('Please select a Rashi');
    //   return false; // Stop form submission
    // }
    // Validate Naadi field
    // if (formData.naadi === "Naadi") {
    //   toast.error('Please select a Naadi');
    //   return false; // Stop form submission
    // }
    // Validate Charan field
    // if (formData.charan === "Charan") {
    //   toast.error('Please select a Charan');
    //   return false; // Stop form submission
    // }
    // Validate Nakshatra field
    // if (formData.nakshatra === "Nakshatra") {
    //   toast.error('Please select a Nakshatra');
    //   return false; // Stop form submission
    // }
    // Validate Hobby field
    // if (formData.hobby.trim() !== '' && !validator.isAlphanumeric(formData.hobby.trim())) {
    //   toast.error('Hobby must contain only alphanumeric characters');
    //   return false; // Stop form submission
    // }

    // Validate Complexion field
    // if (formData.complexion.trim() === '' || formData.complexion === 'Select Complexion') {
    //   toast.error('Please select a complexion');
    //   return false; // Stop form submission
    // }
    // Validate Body Type field
    // if (formData.bodyType.trim() === '' || formData.bodyType === 'Select Body Type') {
    //   toast.error('Please select a body type');
    //   return false; // Stop form submission
    // }
    // Validate Qualification field
    // if (formData.qualification.trim() === '') {
    //   toast.error('Qualification is required');
    //   return false; // Stop form submission
    // } else if (!/^[a-zA-Z0-9\s!@#$%^&*()-_=+`~[\]{},.<>?/|\\]*$/.test(formData.qualification)) {
    //   toast.error('Qualification must contain only alphanumeric characters and special characters');
    //   return false; // Stop form submission
    // }

    // Validate Complete Occupation Details field
    // if (formData.completeOccupationDetails.trim() !== '' && !/^[a-zA-Z0-9\s]+$/.test(formData.completeOccupationDetails)) {
    //   toast.error('Complete Occupation Details must contain only alphanumeric characters');
    //   return false; // Stop form submission
    // }

    // Validate Complete Occupation Details field
    // if (isNaN(formData.annualIncomeExample)) {
    //   toast.error('Annual Income must be a valid number');
    //   return false; // Stop form submission
    // } else if (parseInt(formData.annualIncomeExample) <= 0) {
    //   toast.error('Annual Income must be greater than zero');
    //   return false; // Stop form submission
    // }

    // Validate Income Type field
    // if (formData.incomeType.trim() === 'Select Income Type') {
    //   toast.error('Income Type is required');
    //   return false; // Stop form submission
    // }
    // if (formData.incomeType === 'Other') {
    //   if (formData.otherIncomeType.trim() === '') {
    //     toast.error('Custom Income Type is required');
    //     return false; // Stop form submission
    //   } else if (!validator.isAlpha(formData.otherIncomeType.trim())) {
    //     toast.error('Custom Income Type must contain only alphabetic characters');
    //     return false; // Stop form submission
    //   }
    // }
    // Validate Other Disabilities field
    // if (formData.otherDisabilities.trim() !== '' && !/^[a-zA-Z0-9\s!@#$%^&*()-_=+`~[\]{},.<>?/|\\]*$/.test(formData.otherDisabilities)) {
    //   toast.error('Other disabilities must contain only alphanumeric characters and special characters');
    //   return false; // Stop form submission
    // }


    if (!formData.DobYear) {
      // Display an error message or perform any other action to handle the validation failure
      console.error("Please select a birth year from the dropdown.");
      return;
    }
    // Validate Agree to terms
    if (!formData.agreeToTerms) {
      toast.error("Please agree to the terms of service");
      return false; // Stop form submission
    }

    // Add more validation rules for other fields as needed

    // Set the formErrors state variable with the errors object
    setFormErrors(errors);

    // Return true if there are no errors, otherwise return false
    return Object.keys(errors).length === 0;
  };

  const [formData, setFormData] = useState({
    prefix: "",
    otherPrefix: "",
    firstName: "",
    surname: "",
    motherPrefix: "",
    motherOtherPrefix: "",
    motherName: "",
    fatherPrefix: "",
    fatherOtherPrefix: "",
    fatherName: "",
    email: "",
    city: "",
    pincode: "",
    phoneNumber: "",
    gender: "",
    caste: "",
    superCaste: "",
    subCaste: "",
    otherCaste: "",
    permanentAddress: "",
    otherContactInfo: "",
    birthPlace: "",
    noOfBrothers: "",
    noOfSisters: "",
    bloodGroup: "",
    height: "",
    maritialStatus: "",
    noOfChildren: "",
    childrenLivingStatus: "",
    gotra: "",
    otherGotra: "",
    mangalik: "",
    rashi: "",
    naadi: "",
    charan: "",
    nakshatra: "",
    hobby: "",
    complexion: "",
    bodyType: "",
    qualification: "",
    completeOccupationDetails: "",
    annualIncomeExample: "",
    incomeType: "",
    otherIncomeType: "",
    Organizationexperience: { months: "", years: "" },
    organizationExperienceYears: "",
    organizationExperienceMonths: "",
    otherDisabilities: "",
    agreeToTerms: "",
    aboutMe: "",
    gan: "",
    whatsappNumber: "",
    DobYear: "",
    DobMonth: "",
    DobDay: "",
    DobHours: "",
    DobMinutes: "",
    DobAMPM: "",
    dateOfBirth: {
      day: "",
      month: "",
      year: "",
      hours: "",
      minutes: "",
      ampm: "",
    },
  });

  //salman gotra
  const [gotraValues, setGotraValues] = useState([]);
  const [CasteValues, setCasteValues] = useState([]);
  const [selectedGotra, setSelectedGotra] = useState("");

  useEffect(() => {
    // Fetch gotra values from the API endpoint
    axios
      .get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/user/api/gotra`)
      .then((response) => {
        setGotraValues(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log("Error fetching gotra values:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch Caste values from the API endpoint
    axios
      .get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/user/api/caste`)
      .then((response) => {
        setCasteValues(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log("Error fetching caste values:", error);
      });
  }, []);

  const handleGotraChange = (event) => {
    setSelectedGotra(event.target.value);
  };

  // =====================

  const [profileImage, setProfileImage] = useState(null);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

  const setData = (e) => {
    const { name, value, type, checked } = e.target;
    let processedValue = value;

    if (name === "firstName") {
      processedValue = value.replace(/\s/g, ""); // Remove spaces from the input value
    }

    // if (name === "surname") {
    //   processedValue = value.replace(/\s/g, ""); // Remove spaces from the input value
    // }

    // localStorage.setItem("formData", JSON.stringify({
    //   ...formData, [name]:value
    // }))

    setFormData((prevState) => {
      if (type === "checkbox") {
        return {
          ...prevState,
          [name]: checked,
        };
      } else {
        return {
          ...prevState,
          [name]: processedValue,
        };
      }
    });
  };

  // const onSubmit = async (e) => {
  //   e.preventDefault();

  //   // Validate the form
  //   const isValid = validateForm();

  //   if (isValid) {
  //     // toast.error('Validation Error Message');
  //     if (formData.caste === "Brahmin") {
  //       setFormData((prev) => {
  //         return { ...prev, superCaste: prev.caste };
  //       });
  //     }
  //     if (formData.gotra === "Other") {
  //       setFormData((prev) => {
  //         return { ...prev, gotra: prev.otherGotra };
  //       });
  //     }
  //     console.log("salman 1");
  //     if (formData.caste === "Other") {
  //       // console.log("salman 2");
  //       setFormData((prev) => {
  //         return { ...prev, subCaste: prev.otherCaste };
  //       });
  //     }

  //     formData.subCaste = formData.otherCaste;
  //     // console.log('hogaya yes !!', formData.subCaste);

  //     const form = new FormData();
  //     form.append("file", profileImage);
  //     form.append("data", JSON.stringify(formData));
  //     console.log(form, formData);
  //     try {
  //       const res = await axios.post(
  //         `${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/user/register`,
  //         form
  //       );
  //       const firstName = res.data.firstName;
  //       localStorage.setItem("firstName", firstName);

  //       navigate("/under-review");
  //     } catch (error) {
  //       console.log(error, "error");
  //       const errorMessage = error.response
  //         ? error.response.data.msg
  //         : "An error occurred.";
  //       // alert(errorMessage);
  //       toast.error(errorMessage); // Display error notification
  //     }
  //     return;
  //   }
  // };



  const onSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    const isValid = validateForm();

    if (isValid) {
      if (formData.gotra === "Other") {
        setFormData((prev) => {
          return { ...prev, gotra: prev.otherGotra };
        });
      }

      if (formData.caste === "Other") {
        setFormData((prev) => {
          return { ...prev, subCaste: prev.otherCaste };
        });
      }

      if (formData.caste === "Brahmin") {
        setFormData((prev) => {
          return { ...prev, subCaste: prev.subCaste };
        });
      }

      const form = new FormData();
      form.append("file", profileImage);
      form.append("data", JSON.stringify(formData));

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/user/register`,
          form
        );

        const firstName = res.data.firstName;
        localStorage.setItem("firstName", firstName);

        navigate("/under-review");
      } catch (error) {
        console.log(error, "error");
        const errorMessage = error.response
          ? error.response.data.msg
          : "An error occurred.";

        toast.error(errorMessage); // Display error notification
      }
    }
      return;
    };


    return (
      <>
        <ToastContainer />

        <div className="register-form">
          <h1 className="register-form__heading">Create an account</h1>
          <p className="register-form__paragraph">
            Please Provide your valid informations to register!
          </p>

          <div className="register-form__profile-image">
            <label className="register-form__file-label">
              Select Profile Image
            </label>
            <input
              id="profileImageInput"
              className=""
              type="file"
              onChange={(e) => setProfileImage(e.target.files[0])}
            />
          </div>
          {page === 0 ? (
            <div>
              <select
                className="register-form__input"
                name="prefix"
                value={formData.prefix}
                onChange={setData}
              >
                <option value="" disabled>
                  Name Prefix
                </option>
                <option value="Mr.">Mr.</option>
                <option value="Miss">Miss</option>
                <option value="Dr.">Dr.</option>
                <option value="Other">Other</option>
              </select>
              {formData.prefix === "Other" && (
                <input
                  className="register-form__input"
                  name="otherPrefix"
                  value={formData.otherPrefix}
                  onChange={setData}
                  type="text"
                  placeholder="Enter custom Name prefix "
                />
              )}

              <input
                className="register-form__input"
                name="firstName"
                value={formData.firstName}
                onChange={setData}
                type="text"
                placeholder="Candidate Name"
              />

              <input
                className="register-form__input"
                name="surname"
                value={formData.surname}
                onChange={setData}
                type="text"
                placeholder="Surname"
              />

              <select
                className="register-form__input"
                name="gender"
                onChange={setData}
                value={formData.gender} // Set the default value
              >
                <option value="" disabled selected>
                  Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>

              <select
                className="register-form__input"
                name="fatherPrefix"
                value={formData.fatherPrefix}
                onChange={setData}
              >
                <option value="" disabled>
                  Father's Prefix
                </option>
                <option value="Mr.">Mr.</option>
                <option value="Late Mr.">Late Mr.</option>
                <option value="Other">Other</option>
              </select>
              {formData.fatherPrefix === "Other" && (
                <input
                  className="register-form__input"
                  name="fatherOtherPrefix"
                  value={formData.fatherOtherPrefix}
                  onChange={setData}
                  type="text"
                  placeholder="Enter custom prefix"
                />
              )}

              <input
                className="register-form__input"
                name="fatherName"
                value={formData.fatherName}
                onChange={setData}
                type="text"
                placeholder="Father's Full Name"
              />

              <select
                className="register-form__input"
                name="motherPrefix"
                value={formData.motherPrefix}
                onChange={setData}
              >
                <option value="" disabled>
                  Mother's Prefix
                </option>
                <option value="Mrs.">Mrs.</option>
                <option value="Late Mrs.">Late Mrs.</option>
                <option value="Other">Other</option>
              </select>
              {formData.motherPrefix === "Other" && (
                <input
                  className="register-form__input"
                  name="motherOtherPrefix"
                  value={formData.motherOtherPrefix}
                  onChange={setData}
                  type="text"
                  placeholder="Enter custom prefix"
                />
              )}

              <input
                className="register-form__input"
                name="motherName"
                value={formData.motherName}
                onChange={setData}
                type="text"
                placeholder="Mother's Full Name"
              />

              <select
                name="noOfBrothers"
                onChange={setData}
                value={formData.noOfBrothers}
                className="register-form__input"
              >
                <option value="" defaultValue="No. of Brothers" disabled>
                  No. of Brothers
                </option>
                {[0, 1, 2, 3, 4, 5, "5+"].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                name="noOfSisters"
                onChange={setData}
                value={formData.noOfSisters}
                className="register-form__input"
              >
                <option value="" defaultValue="No. of Sisters" disabled>
                  No. of Sisters
                </option>
                {[0, 1, 2, 3, 4, 5, "5+"].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <input
                className="register-form__input"
                name="email"
                value={formData.email}
                onChange={setData}
                type="text"
                placeholder="Email"
              />

              <input
                className="register-form__input"
                name="permanentAddress"
                value={formData.permanentAddress}
                onChange={setData}
                type="text"
                placeholder="Postal Address"
              />

              <input
                className="register-form__input"
                name="city"
                value={formData.city}
                onChange={setData}
                type="text"
                placeholder="City"
              />

              <input
                className="register-form__input"
                name="pincode"
                value={formData.pincode}
                onChange={setData}
                type="text"
                placeholder="Pin Code"
              />
              <input
                className="register-form__input"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={setData}
                type="text"
                placeholder="Phone Number"
              />
              <input
                className="register-form__input"
                name="whatsappNumber"
                value={formData.whatsappNumber}
                onChange={setData}
                type="text"
                placeholder="WhatsApp Number"
              />

              <input
                className="register-form__input"
                name="otherContactInfo"
                value={formData.otherContactInfo}
                onChange={setData}
                type="text"
                placeholder="Other Contact Number"
              />

              <select
                className="register-form__input"
                name="maritialStatus"
                value={formData.maritialStatus}
                onChange={setData}
              >
                <option value="" disabled defaultValue="Marital Status">
                  Marital Status
                </option>
                {maritalStatusData.map((marital) => (
                  <option key={marital} value={marital}>
                    {marital}
                  </option>
                ))}
              </select>
              {formData.maritialStatus !== "Unmarried" &&
                formData.maritialStatus.length > 0 && (
                  <select
                    className="register-form__input"
                    name="noOfChildren"
                    value={formData.noOfChildren}
                    onChange={setData}
                  >
                    <option value="" disabled defaultValue="No. of children">
                      No. of children
                    </option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4 and above">4 and above</option>
                  </select>
                )}
              {formData.maritialStatus !== "Unmarried" &&
                formData.noOfChildren !== "0" &&
                formData.noOfChildren.length > 0 && (
                  <select
                    className="register-form__input"
                    name="childrenLivingStatus"
                    value={formData.childrenLivingStatus}
                    onChange={setData}
                  >
                    <option
                      defaultValue="Children living status "
                      value=""
                      disabled
                    >
                      Children living status
                    </option>
                    <option value="Living with me">Living with me</option>
                    <option value="Not living with me">Not living with me</option>
                  </select>
                )}
            </div>
          ) : page === 1 ? (
            <div>
              <select
                className="register-form__input"
                name="DobDay"
                onChange={setData}
                value={formData.DobDay}
              >
                <option value="" disabled defaultValue="DobDay">
                  Birth Date
                </option>
                {new Array(31).fill().map((_, i) => (
                  <option key={i} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>

              <select
                className="register-form__input"
                name="DobMonth"
                onChange={setData}
                value={formData.DobMonth}
              >
                <option value="" disabled defaultValue="DobMonth">
                  Birth Month
                </option>
                {[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ].map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>

              <select
                type="number"
                className="register-form__input"
                name="DobYear"
                onChange={setData}
                value={formData.DobYear}
                required
              >
                <option value="" disabled defaultValue="DobYear">
                  Birth Year
                </option>
                {Array.from(
                  { length: 48 },
                  (_, i) => new Date().getFullYear() - i - 16
                )
                  .reverse()
                  .map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
              </select>

              <input
                className="register-form__input"
                name="birthPlace"
                value={formData.birthPlace}
                onChange={setData}
                type="text"
                placeholder="Birth Place"
              />

              <select
                type="number"
                className="register-form__input"
                name="DobHours"
                onChange={setData}
                value={formData.DobHours}
              >
                <option value="" disabled defaultValue="DobHours">
                  Birth time hour
                </option>
                {Array(12)
                  .fill()
                  .map((_, index) => (
                    <option
                      key={index}
                      value={String(index + 1).padStart(2, "0")}
                    >
                      {String(index + 1).padStart(2, "0")}
                    </option>
                  ))}
              </select>

              <select
                type="number"
                className="register-form__input"
                name="DobMinutes"
                onChange={setData}
                value={formData.DobMinutes}
              >
                <option value="" disabled defaultValue="DobMinutes">
                  Birth time minute
                </option>
                <option value="0">00</option>
                {Array(59)
                  .fill()
                  .map((_, index) => (
                    <option
                      key={index + 1}
                      value={String(index + 1).padStart(2, "0")}
                    >
                      {String(index + 1).padStart(2, "0")}
                    </option>
                  ))}
              </select>

              <select
                type="text"
                className="register-form__input"
                name="DobAMPM"
                onChange={setData}
                value={formData.DobAMPM}
              >
                <option value="" disabled defaultValue="DobAMPM">
                  Birth time AM/PM
                </option>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>

              <select
                name="complexion"
                className="register-form__input"
                value={formData.complexion}
                onChange={setData}
              >
                <option value="" disabled defaultValue="Complexion">
                  Complexion
                </option>
                {complexionData.map((complexion) => (
                  <option key={complexion} value={complexion}>
                    {complexion}
                  </option>
                ))}
              </select>

              <select
                name="caste"
                className="register-form__input"
                value={formData.caste}
                onChange={setData}
              >
                <option value="" disabled defaultValue="Caste">
                  Caste
                </option>
                {CasteValues.map((caste, index) => (
                  <option key={index} value={caste}>
                    {caste}
                  </option>
                ))}
                {formData.caste !== "Other" && (
                  <option value="Other">Other</option>
                )}
              </select>
              {formData.caste === "Other" && (
                <input
                  className="register-form__input"
                  name="otherCaste"
                  value={formData.otherCaste}
                  onChange={setData}
                  type="text"
                  placeholder="Caste details"
                />
              )}

              {formData.caste === "Brahmin" ? (
                <select
                  className="register-form__input"
                  name="subCaste"
                  value={formData.subCaste}
                  onChange={setData}
                >
                  <option value="" disabled defaultValue="-">
                    Shakha
                  </option>
                  {brahminSubCasteData.map((caste) => (
                    <option key={caste} value={caste}>
                      {caste}
                    </option>
                  ))}
                </select>
              ) : (
                <></>
              )}

              <select
                name="rashi"
                className="register-form__input"
                value={formData.rashi}
                onChange={setData}
              >
                <option value="" disabled selected>
                  Rashi
                </option>
                {rashiData.map((rashi) => (
                  <option key={rashi} value={rashi}>
                    {rashi}
                  </option>
                ))}
              </select>

              <select
                name="gotra"
                className="register-form__input"
                value={formData.gotra}
                onChange={setData}
              >
                <option value="" disabled defaultValue="Gotra">
                  Gotra
                </option>
                {gotraValues.map((gotra, index) => (
                  <option key={index} value={gotra}>
                    {gotra}
                  </option>
                ))}
                {formData.gotra !== "Other" && (
                  <option value="Other">Other</option>
                )}
              </select>
              {formData.gotra === "Other" && (
                <input
                  className="register-form__input"
                  name="otherGotra"
                  value={formData.otherGotra}
                  onChange={setData}
                  type="text"
                  placeholder="Gotra details"
                />
              )}

              <select
                className="register-form__input"
                name="gan"
                value={formData.gan}
                onChange={setData}
              >
                <option disabled defaultValue="Gan" value="">
                  Gan
                </option>
                <option value="dev">Dev</option>
                <option value="manushya">Manushya</option>
                <option value="rakshas">Rakshas</option>
              </select>

              <select
                name="naadi"
                className="register-form__input"
                value={formData.naadi}
                onChange={setData}
              >
                <option value="" disabled defaultValue="Naadi">
                  Naadi
                </option>
                {naadiData.map((naadi) => (
                  <option key={naadi} value={naadi}>
                    {naadi}
                  </option>
                ))}
              </select>

              <select
                name="nakshatra"
                className="register-form__input"
                value={formData.nakshatra}
                onChange={setData}
              >
                <option value="" disabled defaultValue="Nakshatra">
                  Nakshatra
                </option>
                {nakshatraData.map((nakshatra) => (
                  <option key={nakshatra} value={nakshatra}>
                    {nakshatra}
                  </option>
                ))}
              </select>

              <select
                name="charan"
                className="register-form__input"
                value={formData.charan}
                onChange={setData}
              >
                <option value="" disabled defaultValue="Charan">
                  Charan
                </option>
                {charanData.map((charan) => (
                  <option key={charan} value={charan}>
                    {charan}
                  </option>
                ))}
              </select>

              <select
                name="bloodGroup"
                className="register-form__input"
                value={formData.bloodGroup}
                onChange={setData}
              >
                <option value="" disabled defaultValue="Blood Group">
                  Blood Group
                </option>
                {[
                  "A+",
                  "A-",
                  "B+",
                  "B-",
                  "AB+",
                  "AB-",
                  "O+",
                  "O-",
                  "Don't Know",
                ].map((blood) => (
                  <option key={blood} value={blood}>
                    {blood}
                  </option>
                ))}
              </select>

              <select
                name="bodyType"
                className="register-form__input"
                value={formData.bodyType}
                onChange={setData}
              >
                <option value="" disabled defaultValue="Body Type">
                  Body Type
                </option>
                {bodyTypeData.map((bodyType) => (
                  <option key={bodyType} value={bodyType}>
                    {bodyType}
                  </option>
                ))}
              </select>
              <select
                name="height"
                className="register-form__input"
                value={formData.height}
                onChange={setData}
              >
                <option value="" disabled defaultValue="Height">
                  Height
                </option>
                {heightData.map((heights) => (
                  <option key={heights} value={heights}>
                    {heights}
                  </option>
                ))}
              </select>

              <select
                name="mangalik"
                className="register-form__input"
                value={formData.mangalik}
                onChange={setData}
              >
                <option value="" disabled defaultValue="Mangalik">
                  Mangal
                </option>
                {manglikData.map((mangalik) => (
                  <option key={mangalik} value={mangalik}>
                    {mangalik}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div>
              <input
                className="register-form__input"
                name="qualification"
                value={formData.qualification}
                onChange={setData}
                type="text"
                placeholder="Educational Qualification"
              />

              <input
                className="register-form__input"
                name="completeOccupationDetails"
                value={formData.completeOccupationDetails}
                onChange={setData}
                type="text"
                placeholder="Occupation Details (Designation, Company Name)"
              />
              {formData.completeOccupationDetails.trim() !== "" && (
                <>
                  <input
                    className="register-form__input"
                    name="jobLocation"
                    value={formData.jobLocation}
                    onChange={setData}
                    type="text"
                    placeholder="Job Location"
                  />

                  <select
                    name="incomeType"
                    value={formData.incomeType}
                    onChange={setData}
                    className="register-form__input"
                  >
                    <option value="" disabled defaultValue="Select Income Type">
                      Select Currency
                    </option>
                    <option value="Rs">Rs</option>
                    <option value="Dollar">Dollar</option>
                    <option value="Euro">Euro</option>
                    <option value="Yen">Yen</option>
                    <option value="Pound">Pound</option>
                    <option value="Dirham">Dirham</option>
                    <option value="Other">Other</option>
                  </select>
                  {formData.incomeType === "Other" && (
                    <input
                      name="otherIncomeType"
                      value={formData.otherIncomeType}
                      onChange={setData}
                      className="register-form__input"
                      type="text"
                      placeholder="Enter custom income type"
                    />
                  )}

                  <input
                    className="register-form__input"
                    name="annualIncomeExample"
                    value={formData.annualIncomeExample}
                    onChange={setData}
                    type="number"
                    placeholder="Annual Income"
                    onKeyDown={(event) => {
                      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                        event.preventDefault();
                      }
                    }}
                    style={{ paddingRight: "20px" }}
                  />

                  <select
                    name="organizationExperienceYears"
                    onChange={setData}
                    className="register-form__input"
                    value={formData.organizationExperienceYears}
                  >
                    <option
                      value=""
                      disabled
                      defaultValue="organizationExperienceYears"
                    >
                      Current Org Experience (years)
                    </option>
                    {new Array(51).fill().map((_, i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>

                  <select
                    name="organizationExperienceMonths"
                    onChange={setData}
                    className="register-form__input"
                    value={formData.organizationExperienceMonths}
                  >
                    <option
                      value=""
                      disabled
                      defaultValue="organizationExperienceMonths"
                    >
                      Current Org Experience (months)
                    </option>
                    {new Array(13).fill().map((_, i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                </>
              )}

              <input
                className="register-form__input"
                name="hobby"
                value={formData.hobby}
                onChange={setData}
                type="text"
                placeholder="Hobby"
              />

              <input
                className="register-form__input"
                name="aboutMe"
                value={formData.aboutMe}
                onChange={setData}
                type="text"
                placeholder="About Me"
              />

              {/* <input
              
              name="currentOrganizationexperience"
              value={formData.currentOrganizationexperience.months}
              onChange={setData}
              type="text"
              placeholder="Current Org Experience"
            /> */}

              {/* </div> */}

              <input
                className="register-form__input"
                name="otherDisabilities"
                value={formData.otherDisabilities}
                onChange={setData}
                type="text"
                placeholder="Other Disabilities"
              />

              <div>
                <label>
                  <input
                    type="checkbox"
                    name="agreeToTerms"
                    checked={formData.agreeToTerms}
                    onChange={(e) => setData(e)}
                  />
                  {"  "} I agree to the{" "}
                  <Link
                    to="/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms of service
                  </Link>
                </label>
              </div>
            </div>
          )}
          {page < 2 ? (
            <div className="flex-set">
              <button
                className="register-form__submit-button col-3 prev-bg"
                disabled={page === 0}
                onClick={() => setPage((prev) => prev - 1)}
              >
                Prev
              </button>
              <button
                className="register-form__submit-button col-4 next-bg"
                onClick={() => setPage((prev) => prev + 1)}
              >
                Next
              </button>
            </div>
          ) : (
            <div className="flex-set">
              <button
                className="register-form__submit-button col-3 prev-bg"
                onClick={() => setPage((prev) => prev - 1)}
              >
                Back
              </button>
              <button
                className="register-form__submit-button col-4"
                type="submit"
                onClick={onSubmit}
              >
                Register
              </button>
            </div>
          )}
        </div>
      </>
    );
  }

  export default UserRegister;
