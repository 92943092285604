import React, { useState, useEffect } from "react";
import { BsGearWideConnected, BsHandIndexThumb, BsHandThumbsUp, BsSuitHeart } from "react-icons/bs";
import { FaPhone, FaStreetView } from "react-icons/fa";
import { FiCheck } from "react-icons/fi";
import Tablecomp from "../../component/Table/Tablecomp";
import Card from "../../component/DashboardCards/Card";
import Redirect from "../../component/Redirect/Redirect";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import axios from "../../axios";
import { getMe } from "../../redux/actions";
import { useDispatch } from "react-redux";

import './dashboard.css'

const Dashboard = () => {
  const { isStandard, isAuthenticated, currentUser  } = useSelector((state) => state.User);
  const [shortlistCount, setShortlistCount] = useState(0); // Declare dataCount outside the useEffect hook
  const [interestCount, setInterestCount] = useState(0); // Declare dataCount outside the useEffect hook
  const [profileViewsCount, setProfileViewsCount] = useState(0); // Declare dataCount outside the useEffect hook
  const dispatch = useDispatch();
  const [shortlistedMe, setShortlistedMe] = useState([]);
  const [interestedinme, setInterestedinme] = useState([]);
  useEffect(() => {
    axios
      .get("/interactions/shortlistme/:id")
      .then((response) => {
        const count = response.data.length; // Calculate the count
        setShortlistedMe(response.data);
        console.log('shortlist count', count)
        setShortlistCount(count); // Set the dataCount state value
        // console.log("Data count:", count);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [setShortlistedMe, setShortlistCount]); // Add the state setters as dependencies

  useEffect(() => {
    // interest api
    axios
      .get("/interactions/interestedinme/:id")
      .then((response) => {
        const count = response.data.length; // Calculate the count
        setInterestedinme(response.data);
        setInterestCount(count); // Set the dataCount state value
        // console.log("Data count:", count);  
      })
      .catch((error) => {
        console.error(error);
      });

    // profile views api
    axios
      .get("interactions/profile-views/count")
      .then((response) => {
        const count = response.data.totalViewerCount; // Calculate the count 
        // setInterestedinme(response.data);
        setProfileViewsCount(count); // Set the dataCount state value
        console.log("Profile Views Count :", response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [setInterestedinme, setInterestCount]);

  useEffect(() => {
    if (isAuthenticated && !currentUser) {
      dispatch(getMe());
    }
  }, [dispatch]);




  return (
    <>


      {isStandard ? (
        <>
         <ToastContainer />
         <h2 style={{color : "#f8238c"}}>Welcome  {currentUser?.firstName}</h2>
          <h2>Dashboard</h2>  
          <div className="cards">
           
              <Card
                number={profileViewsCount !== null ? profileViewsCount : 0}
                title="Total Profile Visitors"
                className="card-1"
                icon={<FaStreetView />}
              />
  
        
            <Card
              number={interestCount !== null ? interestCount : 0}
              title="Interest Received"
              className="card-2"
              iconStyle="#FF9E42"
              icon={<BsSuitHeart />}
            />

            <Card
              number={shortlistCount !== null ? shortlistCount : 0}
              title="Who Shortlisted Me"
              className="card-3"
              iconStyle="#3BD855"
              icon={<BsHandThumbsUp />}
            />

            {/* <Card
          number="1"
          title="Today's Profile Views"
          className="card-4"
          iconStyle="#C085FF"
          icon={<BsSuitHeart />}
        />
        <Card
          number="3"
          title="Matches"
          className="card-5"
          iconStyle="#FD2C79"
          icon={<BsSuitHeart />}
        />
        <Card
          number="1"
          title="Your Connections"
          iconStyle="#FF9E42"
          className="card-6"
          icon={<BsSuitHeart />}
        /> */}
          </div>

          {/* <div className="current-package">
        <h2 className="current-package-heading">Current Package</h2>
      </div>
      <hr style={{ width: "100%" }} />
      <div className="current-package-info">
        <h2 style={{ marginBottom: "10px" }}>Free</h2>
        <div className="current-package-info-icon-box">
          <div className="icon-box">
            <FiCheck color="fd2c79" />
            <p>47 Express Interests</p>
          </div>

          <div className="icon-box">
            <FiCheck color="fd2c79" />
            <p>20 Contack View</p>
          </div>
          <div className="icon-box">
            <FiCheck color="fd2c79" />
            <p>20 Image Upload</p>
          </div>

          <p className="current-package-info-p">
            Package expiry date : 09 Mar, 2023
          </p>

          <a href="">
            <button type="button" className="gradient-btn">
              Upgrade Package
            </button>
          </a>
        </div>
      </div>
       */}
          <div className="dashboard-latest-interest">
            <h2>My Shortlist</h2>
            <Tablecomp />
          </div> </>) : (<Redirect />)}

    </>
  );
};

export default Dashboard;
