export const USER_LOGIN_GOOD = "USER_LOGIN_GOOD";
export const USER_LOGIN_BAD = "USER_LOGIN_BAD";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const GET_ME_SUCCESS = "GET_ME_SUCCESS";
export const GET_ME_FAIL = "GET_ME_FAIL";
export const PROFILE_PICTURE_UPDATE_SUCCESS = "PROFILE_PICTURE_UPDATE_SUCCESS";
export const PROFILE_PICTURE_UPDATE_fail = "PROFILE_PICTURE_UPDATE_fail";
export const GET_SINGLE_USER = "GET_SINGLE_USER";
export const FETCH_SAMMELAN_SUCCESS = 'FETCH_SAMMELAN_SUCCESS';
export const FETCH_SAMMELAN_FAILURE = 'FETCH_SAMMELAN_FAILURE';
export const FETCH_SAMMLEN_USERS = 'FETCH_SAMMLEN_USERS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
