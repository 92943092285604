import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Sammelans.css";
import { useNavigate } from 'react-router-dom';

function Sammelans() {
  const [sammelans, setSammelans] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/sammelan/all`);
      const sortedSammelans = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setSammelans(sortedSammelans);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    localStorage.removeItem('sammelanCurrentPage')
   
    fetchData();
  }, []);

  const handleViewSammelan = async (id) => {
  
    // navigate(`/sammelan/${id}`);
    window.location.href = `/sammelan/${id}`
  };

  return (
    <div className="container">
      {/* Upcoming Sammelans section */}
      <div className="upcoming">
        <h2>Sammelans</h2>
      </div>

      {/* Image boxes section */}
      <div className="image-boxes">
        {sammelans.map((sammelan) => (
          <div className="image-box" key={sammelan._id}>
            <div className="content">
              <h3>{sammelan.name}</h3>
              <p>{sammelan.date}</p>
              <button onClick={() => handleViewSammelan(sammelan._id)}>Show Users</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Sammelans;
