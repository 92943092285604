import React from "react";
import { useState, useEffect } from "react";
import "./navbar.css";
import { image } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/actions";
import { FaBell } from 'react-icons/fa';
import authAxios from "../../axios";
import axios from "axios";



const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [currentUser, setCurrentUser] = useState(null);
  const [socialLinks, setSocialLinks] = useState({});
  const [lastActivityTimestamp, setLastActivityTimestamp] = useState(Date.now());

  const { isAuthenticated } = useSelector((state) => state.User);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notify, setnotify] = useState([])

  const handleNotificationClick = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/home");
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };


  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/social-links`);
        const { socialLinks } = response.data;
        setSocialLinks(socialLinks);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSocialLinks();
  }, []);



  return (
    <>
      <div className="navbar">
        {/* For Large device  */}
        {/* Navbar Top Structure */}
        <div className="navbar-top">
          <div className="navbar-top-left-column">
            <div className="navbar-top-left-column-icon-box-1">
              <img src={image.mail} alt="" />
              <p>info@olakh.info</p>
              <div className="navbar-top-left-column-icon-box-divider"></div>
            </div>
            <div className="navbar-top-left-column-icon-box-2">
              <img src={image.phone} alt="" />
              <p>+91 07314700547</p>
            </div>
          </div>
          <div className="navbar-top-right-column">
            <a href={socialLinks.facebook} target="_blank" rel="noreferrer">
              <FontAwesomeIcon
                icon={faFacebook}
                className="navbar-top-right-column-social-icon"
                id="facebook"
              />
            </a>
            <a href={socialLinks.instagram} target="_blank" rel="noreferrer">
              <FontAwesomeIcon
                icon={faInstagram}
                className="navbar-top-right-column-social-icon"
                id="instagram"
              />
            </a>
            <a href={socialLinks.youtube} target="_blank" rel="noreferrer">
              <FontAwesomeIcon
                icon={faYoutube}
                className="navbar-top-right-column-social-icon"
                id="youtube"
              />
            </a>

          </div>
        </div>

        {/* Navbar Bottom Structure */}
        <div className="navbar-bottom">
          <div className="navbar-bottom-left-column">
            <Link to="/">
              <img src={image.olakh_logo} alt="olakh-logo" />
            </Link>
          </div>
          <div className="navbar-bottom-middle-column">
            <ul className="navbar-bottom-middle-column-menu">
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/sammelans">Sammelans</Link>
              </li>
              <li>
                <Link to="/membership">Membership</Link>
              </li>
              <li>
                <Link to="/success-stories">Success Stories</Link>
              </li>
              <li>
                <Link to="/event">Event</Link>
              </li>

              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              {isAuthenticated && (
                <li>
                  <Link to="/dashboard/info">Dashboard</Link>
                </li>
              )}
            </ul>
          </div>
          <div className="navbar-bottom-right-column">
            {isAuthenticated ? (
              <>
                {/* <div className="navbar-bottom-right-column-notification-container">
                  <FaBell
                    className="navbar-bottom-right-column-notification-icon"
                    onClick={handleNotificationClick}
                  />
                  {isNotificationOpen && (
                    <div className="navbar-bottom-right-column-notification-popup">
                      {notify.map((notify, index) => (
                        <div key={index}>
                          <p>{notify.notify}</p>

                        </div>
                      ))}
                    </div>
                  )}
                </div> */}
                <button
                  className="navbar-bottom-right-column-btn-2"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link to="/userLogin">
                  <button className="navbar-bottom-right-column-btn-1">
                    Login
                  </button>
                </Link>
                <Link to="/register">
                  <button className="navbar-bottom-right-column-btn-2">
                    Register
                  </button>
                </Link>
              </>
            )}
          </div>

          {/* For Small Device start */}




          <div
            className="navbar-bottom-right-column-hamburger"

          >
            {isAuthenticated ? (<>
              <button
                className="navbar-bottom-right-column-btn-1"
                onClick={handleLogout}
              >
                Logout
              </button> </>) : (<><Link to="/userLogin">
                <button className="navbar-bottom-right-column-btn-1 ">
                  Login
                </button>
              </Link> </>)}

            {/* <div className="navbar-bottom-right-column-notification-container">
              <FaBell
                className="navbar-bottom-right-column-notification-icon"
                onClick={handleNotificationClick}
              />
              {isNotificationOpen && (
                <div className="navbar-bottom-right-column-notification-popup navbar-bottom-right-column-notification-popup-mobile">
                  {notify.map((notify, index) => (
                    <div key={index}>
                      <p>{notify.notify}</p>

                    </div>
                  ))}
                </div>
              )}
            </div> */}
            {isMenuOpen ? (
              <AiOutlineClose
                onClick={closeMenu}
                className="hamburger-icon"
                size={24}
              />
            ) : (
              <>


                <RxHamburgerMenu size={24} onClick={toggleMenu} />
              </>
            )}
          </div>
          {isMenuOpen && (
            <div className="navbar-fullscreen-menu slide-in-top">
              <ul>
                <li >
                  <Link onClick={closeMenu} to="home">Home

                  </Link>
                </li>
                <li  >
                  <Link onClick={closeMenu} to="about">About Us</Link>
                </li>
                <li>
                  <Link onClick={closeMenu} to="sammelans">Sammelans</Link>
                </li>
                <li>
                  <Link onClick={closeMenu} to="membership">Membership</Link>
                </li>
                <li>
                  <Link onClick={closeMenu} to="success-stories">Success Stories</Link>
                </li>
                <li>
                  <Link onClick={closeMenu} to="event">Event</Link>
                </li>
                <li>
                  <Link onClick={closeMenu} to="contact">Contact Us</Link>
                </li>
                {isAuthenticated ? (<></>) : (<><li>
                  <Link onClick={closeMenu} to="/register">Register</Link>
                </li> </>)}


                {/* {isAuthenticated ? (
                  <li>
                    <Link onClick= {closeMenu} href="dashboard">Dashboard</Link>
                  </li>
                ) : (
                  <>
                    <li>
                      <Link onClick= {closeMenu} to="/register">Register</Link>
                    </li>

                  </>
                )} */}
              </ul>
            </div>
          )}
          {/* For Small Device end */}
        </div>
      </div>
    </>
  );
};

export default Navbar;
