import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../axios";
import { image } from "../../constants";
import "./publicProfile.css";
import { AiFillHeart, AiOutlineStar } from "react-icons/ai";
import { FaUserTimes } from "react-icons/fa";
import { MdReport } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import authAxios from "../../axios";
import { useNavigate } from "react-router-dom";
import MessageIcon from "@material-ui/icons/Message";


function PublicProfile() {
    const [isShortlisted, setIsShortlisted] = useState(false);

    // const [currentUser, setCurrentUser] = useState(null);

    const { currentUser } = useSelector((state) => state.User);
    const [users, setUsers] = useState([]);

    const { id } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [valueNotification, setvalueNotification] = useState();

    const [valueInterest, setvalueInterest] = useState();
    const [isOpenInterest, setIsOpenInterest] = useState(false);

    const calculateAge = (birthYear) => {
        const currentYear = new Date().getFullYear();
        return currentYear - birthYear;
    };
    const [galleryImages, setGalleryImages] = useState([]);
    const navigate = useNavigate();


    // const fetchGalleryImages = async () => {
    //     try {
    //         const response = await axios.get('/user/gallery-approved/');
    //         const data = response.data;
    //         setGalleryImages(data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // useEffect(() => {
    //     fetchGalleryImages();
    // }, []);



    // const getMe = async (setCurrentUser) => {
    //     try {
    //         const res = await authAxios.get(
    //             process.env.REACT_APP_ADMIN_BASE_URL_SERVER + "/user/me"
    //         );
    //         setCurrentUser(res.data.user);
    //     } catch (error) {
    //         console.log(error.message);
    //     }
    // };

    const handleNotificationClick = async (targetId) => {
        try {
            const response = await authAxios.patch(
                `/interactions/shortlist/${targetId}`,
                {}
            );

            // Check if API request was successful
            if (response.status === 200) {
                setIsOpen(true);

                // Close the notification after 3 seconds
                setTimeout(() => {
                    setIsOpen(false);
                }, 3000);
            }
        } catch (error) {
            console.error(error);
        }
    };


    const handleInterestClick = async (targetId) => {
        try {
            const response = await authAxios.patch(
                `/interactions/interest/${targetId}`,
                {}
            );

            // Check if API request was successful
            if (response.status === 200) {

                setIsOpenInterest(true);
                setvalueInterest(targetId);

                // setIsOpenInterest(true);
                // setvalueInterest(targetId);
                // Close the notification after 3 seconds
                setTimeout(() => {
                    setIsOpenInterest(false);
                }, 3000);
            }
        } catch (error) {
            console.error(error);
        }
    };



    useEffect(() => {
        const fetchSammelan = async () => {
            try {
                const response = await axios.get(`/user/single-user-profile/${id}`);
                // setCurrentUser(response.data.user)
                const galleryImagesWithoutIndex0 = response.data.user.approvals.gallery.slice(1);
                setGalleryImages(galleryImagesWithoutIndex0);
                // console.log("single user",response.data.user)
                // console.log("single images", galleryImagesWithoutIndex0) 
                // setSammelan(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchSammelan();
    }, [id]);

    if (!currentUser) return <div></div>

    return (
        <>
            <div className="user-profile">
                <div className="user-profile__left">
                    <img
                        className="user-profile__image"
                        src={process.env.REACT_APP_SERVER_URL + currentUser.profile.avatar}
                        alt="User profile"
                    />
                    <h2 className="user-profile__name">{currentUser.profile.prefix +
                        " " + currentUser.firstName + " " + currentUser.surname}</h2>
                    <p className="user-profile__value">OLAKH ID: {currentUser.userName}</p>

                    <div className="user-profile__row user-profile__highlight-row">
                        <div className="user-profile__column">
                            <p className="user-profile__text">Maritial Status</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__value">{currentUser.profile.maritialStatus}</p>
                        </div>
                    </div>

                    <div className="user-profile__row user-profile__highlight-row">
                        <div className="user-profile__column">
                            <p className="user-profile__text">Age</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__value">{calculateAge(currentUser.profile.DobYear)} Years</p>
                        </div>
                    </div>

                    <div className="user-profile__row user-profile__highlight-row">
                        <div className="user-profile__column">
                            <p className="user-profile__text">Caste</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__value">{currentUser.profile.caste}</p>
                        </div>
                    </div>

                    {currentUser.profile.caste === 'Brahmin' && currentUser.profile.subCaste && (
                        <div className="user-profile__row user-profile__highlight-row">
                            <div className="user-profile__column">
                                <p className="user-profile__text">Shakha</p>
                            </div>
                            <div className="user-profile__column">
                                <p className="user-profile__value">{currentUser.profile.subCaste}</p>
                            </div>
                        </div>
                    )}



                    {/* Interaction buttons start */}
                    {/* <div className="search-result__user-interaction-container">
                        <div className="sammelan-user__user-interaction-box" onClick={() => handleInterestClick(currentUser._id)}>
                            <AiFillHeart className="sammelan-user__user-interaction-box__interest-icon" />
                            <p>Interest</p>


                            {isOpenInterest && currentUser._id === valueInterest && (
                                <div className="notification">
                                    <p>{`You have shown interest in ${currentUser.firstName + currentUser.surname}`}</p>
                                </div>
                            )}

                        </div> */}
                        {/* <div className="search-result__user-interaction-box">
                            <FaUserTimes className="search-result__user-interaction-box__ignore-icon" />
                            <p>Ignore</p>
                        </div> */}
                        {/* <div className="sammelan-user__user-interaction-box" onClick={() =>
                            navigate(`/dashboard/singleChat/${currentUser._id}`)
                        }>
                            <MessageIcon fontSize="small" className="sammelan-user__user-interaction-box__interest-icon message-icon" />
                            <p>Message</p> */}


                            {/* {isOpenInterest && user._id === valueInterest && (
                      <div className="notification">
                        <p>{`You have shown interest in ${user.firstName + user.surname}`}</p>
                      </div>
                    )} */}

                        {/* </div> */}

                        {/* <div>

                            <AiOutlineStar className="sammelan-user__user-interaction-box__shortlist-icon" onClick={() => handleNotificationClick(currentUser._id)} />
                            <p>Shortlist</p>

                            {isOpen && (
                                <div className="notification">
                                    <p>{`You have shortlisted ${currentUser.firstName + currentUser.surname
                                        }`}</p>
                                </div>
                            )}
                        </div> */}

                        {/* <div className="search-result__user-interaction-box">
                            <MdReport className="search-result__user-interaction-box__report-icon" />
                            <p>Report</p>
                        </div> */}
                    {/* </div> */}
                    {/* Interaction buttons end */}
                    <Link to='/dashboard/profile-settings'>
                    <button className="profile-avatar-info-btn">Edit My Profile</button>
                    </Link>
                </div>
                <div className="user-profile__right">
                    <h2 className="user-profile__section-title">Personal Information</h2>

                    <div className="user-profile__row">

                        <div className="user-profile__column">
                            <p className="user-profile__label">Father Name:</p>
                            <p className="user-profile__value">{currentUser.profile.fatherPrefix + " " + currentUser.profile.fatherName}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">Mother Name:</p>
                            <p className="user-profile__value">{currentUser.profile.motherPrefix + " " + currentUser.profile.motherName}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">

                        <div className="user-profile__column">
                            <p className="user-profile__label">No. of Brothers:</p>
                            <p className="user-profile__value">{currentUser.profile.noOfBrothers}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">No. of Sisters:</p>
                            <p className="user-profile__value">{currentUser.profile.noOfSisters}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">

                        <div className="user-profile__column">
                            <p className="user-profile__label">Gender:</p>
                            <p className="user-profile__value">{currentUser.profile.gender}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">Email:</p>
                            <p className="user-profile__value">{currentUser.email}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">
                        <div className="user-profile__column">
                            <p className="user-profile__label">Postal Address:</p>
                            <p className="user-profile__value">{currentUser.profile.permanentAddress}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">City:</p>
                            <p className="user-profile__value">{currentUser.profile.city}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">
                        <div className="user-profile__column">
                            <p className="user-profile__label">Pincode:</p>
                            <p className="user-profile__value">{currentUser.profile.pincode}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">Phone number:</p>
                            <p className="user-profile__value">{currentUser.profile.phoneNumber}</p>
                        </div>
                    </div>
                    <div className="user-profile__row">
                        <div className="user-profile__column">
                            <p className="user-profile__label">Whatsapp  Number:</p>
                            <p className="user-profile__value">{currentUser.profile.whatsappNumber}</p>
                        </div>

                        <div className="user-profile__column">
                            <p className="user-profile__label">Other Contact no.:</p>
                            <p className="user-profile__value">{currentUser.profile.otherContactInfo}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">
                        <div className="user-profile__column">
                            <p className="user-profile__label">Date Of Birth:</p>
                            <p className="user-profile__value">{currentUser.profile.DobDay + "-" + currentUser.profile.DobMonth + "-" + currentUser.profile.DobYear}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">Birth Place:</p>
                            <p className="user-profile__value">{currentUser.profile.birthPlace}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">
                        <div className="user-profile__column">
                            <p className="user-profile__label">Birth Time (in Hours):</p>
                            <p className="user-profile__value">{currentUser.profile.DobHours}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">Birth Time (in Minutes):</p>
                            <p className="user-profile__value">{currentUser.profile.DobMinutes}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">
                        <div className="user-profile__column">
                            <p className="user-profile__label">Birth Time (AM/PM):</p>
                            <p className="user-profile__value">{currentUser.profile.DobAMPM}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">Complexion:</p>
                            <p className="user-profile__value">{currentUser.profile.complexion}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">
                        <div className="user-profile__column">
                            <p className="user-profile__label">Rashi:</p>
                            <p className="user-profile__value">{currentUser.profile.rashi}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">Gotra:</p>
                            <p className="user-profile__value">{currentUser.profile.gotra}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">
                        <div className="user-profile__column">
                            <p className="user-profile__label">Gan:</p>
                            <p className="user-profile__value">{currentUser.profile.gan}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">Naadi:</p>
                            <p className="user-profile__value">{currentUser.profile.naadi}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">
                        <div className="user-profile__column">
                            <p className="user-profile__label">Nakshatra:</p>
                            <p className="user-profile__value">{currentUser.profile.nakshatra}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">Charan:</p>
                            <p className="user-profile__value">{currentUser.profile.charan}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">
                        <div className="user-profile__column">
                            <p className="user-profile__label">Blood Group:</p>
                            <p className="user-profile__value">{currentUser.profile.bloodGroup}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">Body type:</p>
                            <p className="user-profile__value">{currentUser.profile.bodyType}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">
                        <div className="user-profile__column">
                            <p className="user-profile__label">Height:</p>
                            <p className="user-profile__value">{currentUser.profile.height}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">Mangal:</p>
                            <p className="user-profile__value">{currentUser.profile.mangalik}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">
                        <div className="user-profile__column">
                            <p className="user-profile__label">Educational Qualification:</p>
                            <p className="user-profile__value">{currentUser.profile.qualification}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">Occupation Details:</p>
                            <p className="user-profile__value">{currentUser.profile.completeOccupationDetails}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">
                        <div className="user-profile__column">
                            <p className="user-profile__label">Job Location:</p>
                            <p className="user-profile__value">{currentUser.profile.jobLocation}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">Currency:</p>
                            <p className="user-profile__value">{currentUser.profile.incomeType}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">
                        <div className="user-profile__column">
                            <p className="user-profile__label">Annual Income:</p>
                            <p className="user-profile__value">{currentUser.profile.annualIncomeExample}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">Organization Experience in Years:</p>
                            <p className="user-profile__value">{currentUser.profile.organizationExperienceYears}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">
                        <div className="user-profile__column">
                            <p className="user-profile__label">Organization Experience in Months:</p>
                            <p className="user-profile__value">{currentUser.profile.organizationExperienceMonths}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">Hobby:</p>
                            <p className="user-profile__value">{currentUser.profile.hobby}</p>
                        </div>
                    </div>

                    <div className="user-profile__row">
                        <div className="user-profile__column">
                            <p className="user-profile__label">About Me:</p>
                            <p className="user-profile__value">{currentUser.profile.aboutMe}</p>
                        </div>
                        <div className="user-profile__column">
                            <p className="user-profile__label">Othe Disabilities:</p>
                            <p className="user-profile__value">{currentUser.profile.otherDisabilities || "N/A"}</p>
                        </div>
                    </div>

                </div>
            </div><br />
            <br />
            <center><h2>Gallery Images</h2></center>
            <br />
            <br />
            <div className="image-grid">
                {Array.isArray(galleryImages) &&
                    galleryImages.map((image, index) => (
                        <div key={index}>
                            <div>
                                <img src={image} alt="Gallery Image" />
                            </div>
                        </div>
                    ))}
            </div>



        </>
    );
};

export default PublicProfile;
