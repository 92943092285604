import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Event.css";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoMdMail } from "react-icons/io";

function Event() {
  const [eventData, setEventData] = useState([]);

  useEffect(() => {
    fetchEventData();
  }, []);

  const fetchEventData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_ADMIN_BASE_URL_SERVER + "/cms/website-cms");
      if (response.status === 200) {
        const { event } = response.data;
        setEventData(event);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="event-page">
      <h1 className="event-title">Upcoming Events</h1>
      {eventData.map((event) => (
        <div className="event-info" key={event._id}>
          <div className="event-date">
            <div className="date-box">
              <p className="date">{event.eventdate}</p>
              <p className="month">{event.eventmonth}</p>
            </div>
          </div>
          <div className="event-description">
            <h2 className="event-heading">{event.eventtitle}</h2>
            <p className="event-paragraph">{event.eventdescription}</p>
          </div>
        </div>
      ))}
      <div className="event-contact">
        <h2 className="event-contact-heading">
          सविस्तर माहिती साठी 9074151229 व 6262799226 वर सम्पर्क करावे । नाव नोंदणी सुरु आहे !! " नाव नोंदवा यश मिळवा "
        </h2>
        {/* <ul className="contact-list">
          <li>
            <BsFillTelephoneFill className="contact-list-icon" /> Phone: +91 07314700547
          </li>
          <li>
            <IoMdMail className="contact-list-icon" /> Email: info@olakh.info
          </li>
        </ul> */}
      </div>
      <p className="event-notes">नाव नोंदणी सुरु आहे !! " नाव नोंदवा यश मिळवा "</p>
    </div>
  );
}

export default Event;
