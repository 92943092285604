import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

const ReportMisuse = () => {
  const [report, setReport] = useState('');

  useEffect(() => {
    fetchReport();
  }, []);

  const fetchReport = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/website-cms/`);
      if (response.status === 200) {
        const { report } = response.data;
        setReport(report);
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  const toolbarOptions = {
    toolbar: false, // Hide the toolbar
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center', margin: '1rem' }}>Report Misuse</h1>
      <style>{`.ql-container.ql-snow.ql-disabled { border: none; }`}</style>
      <ReactQuill
        value={report}
        readOnly
        theme="snow"
        modules={toolbarOptions}
      />
    </div>
  );
};

export default ReportMisuse;
