import {
  SEND_CONTENT_SUCCESS,
  GET_SINGLE_CHAT,
  SEND_CONTENT_FAIL,
  RECEIVE_CONTENT_SUCCESS,
  RECEIVE_CONTENT_FAIL,
  GET_MY_PARTICIPANTS,
} from "./types";
import axios from "../../axios";

export const sendMessage = (data, id) => async (dispatch) => {
  try {
    const res = await axios.post(`/chat/chats/${id}`, data);

    dispatch({
      type: SEND_CONTENT_SUCCESS,
      payload: res.data,
    });

    console.log(res);
  } catch (error) {
    console.log(error);
  }
};

export const getSingleChat = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/chat/single-chat/${id}`);
    dispatch({
      type: GET_SINGLE_CHAT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAllChat = () => async (dispatch) => {
  try {
    const res = await axios.get("/chat/allMyParticipants");
    dispatch({
      type: GET_MY_PARTICIPANTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteParticipant = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/chat/${id}`);
    console.log(res);
  } catch (error) {
    console.log(error);
  }
};
