import { useState, useEffect } from "react";
import "./HeroSection.css";
import { searchPartner } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { casteData } from "../../data/UserData";
import { image } from "../../constants";
import { Link } from "react-router-dom";
import { getMe } from "../../redux/actions";

const HeroSection = () => {
  const { isStandard, isAuthenticated, currentUser } = useSelector((state) => state.User);
  const [searchInput, setSearchInput] = useState({
    gender: "",
    minAge: "",
    maxAge: "",
    caste: "",
  });
  const [formError, setFormError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    localStorage.setItem("currentPage", 1);
    

    if (
      searchInput.gender === "" ||
      searchInput.minAge === "" ||
      searchInput.maxAge === "" ||
      searchInput.caste === ""
    ) {
      setFormError(true);
      return;
    }

    const body = {
      gender: searchInput.gender,
      filters: {
        "profile.age": {
          $gte: searchInput.minAge,
          $lte: searchInput.maxAge,
        },
        "profile.caste": searchInput.caste,
      },
    };

    localStorage.setItem("searchBody", JSON.stringify(body));

    dispatch(searchPartner(body));
    navigate("/search-result");
  };

  useEffect(() => {
    if (isAuthenticated && !currentUser) {
      dispatch(getMe());

    }
  }, [currentUser]);

  return (
    <>
      <div className="heroSection">
        {console.log('hero current')}
        <div className="mobile-image">
          {/* Add the <img> tag for the mobile image */}
          <img src={image.successStory3} alt="background image" />
        </div>
        <div className="heroSection-left-column">
          <h4>
            <span>Welcome</span> To Olakh Swapnanchi
          </h4>
          <h1>Find Your Perfect Life Partner With Us</h1>



          {isAuthenticated ? (<></>
          ) : (<>
            <Link to="/register">
              <button>Get Started</button>
            </Link>
          </>
          )}
          
          {/* {isAuthenticated ? (<></>
          ) : (<>
            <Link to="/register">
              <button>Get Started</button>
            </Link>
          </>
          )} */}
        </div>


        <div className="heroSection-right-column">
          <div className="heroSection-right-column-box">
            <h2>Find Your Partner</h2>
            {formError && <p>Please fill in all the fields.</p>}
            <form
              onSubmit={onSubmitHandler}
              className="heroSection-right-column-box-form"
            >
              <label htmlFor="looking-for">I am looking for a</label>
              <select
                onChange={(e) =>
                  setSearchInput((prev) => {
                    return { ...prev, gender: e.target.value };
                  })
                }
                name=""
                id=""
                className="heroSection-right-column-box-form-field"
                required
              >
                <option value="" defaultValue="partner">
                  select your partner
                </option>
                {isAuthenticated ? (
                  currentUser?.profile.gender === 'Male' ? (
                    <option value="Female">Bride</option>
                  ) : (
                    <option value="Male">Groom</option>
                  )
                ) : (
                  <>
                    <option value="Female">Bride</option>
                    <option value="Male">Groom</option>
                  </>
                )}
              </select>
              <label htmlFor="from-age">From age</label>
              <select
                onChange={(e) =>
                  setSearchInput((prev) => {
                    return { ...prev, minAge: e.target.value };
                  })
                }
                name=""
                id=""
                className="heroSection-right-column-box-form-field"
                required
              >
                <option value="" defaultValue="select">
                  select age from
                </option>
                {new Array(53).fill().map((_, i) => (
                  <option key={i} value={i + 18}>
                    {i + 18}
                  </option>
                ))}
              </select>
              <label htmlFor="to-age">To age</label>
              <select
                onChange={(e) =>
                  setSearchInput((prev) => {
                    return { ...prev, maxAge: e.target.value };
                  })
                }
                name=""
                id=""
                className="heroSection-right-column-box-form-field"
                required
              >
                <option value="" defaultValue="select">
                  select age to
                </option>
                {new Array(53).fill().map((_, i) => (
                  <option key={i} value={i + 18}>
                    {i + 18}
                  </option>
                ))}
              </select>
              <label htmlFor="from-age">Caste</label>
              <select
                onChange={(e) =>
                  setSearchInput((prev) => {
                    return { ...prev, caste: e.target.value };
                  })
                }
                name="caste"
                id=""
                className="heroSection-right-column-box-form-field"
                required
              >
                <option value="" defaultValue="caste">
                  select your caste
                </option>
                {casteData.map((caste) => (
                  <option key={caste} value={caste}>
                    {caste}
                  </option>
                ))}
              </select>

              <input
                type="submit"
                value="search"
                className="heroSection-right-column-box-form-submit"
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
