import React from "react";
import Tablecomp from "../../component/Table/Tablecomp";
import "./shortlist.css";
import { useSelector } from "react-redux";
import Redirect from "../../component/Redirect/Redirect";

const Shortlist = () => {
  const { isStandard } = useSelector((state) => state.User);
  return (
    <>
      {isStandard ? (
        <div className="">
          <div className="">
            <h2>Shortlisted Profiles</h2>
            <Tablecomp />
          </div>
        </div>
      ) : (
        <Redirect />
      )}
    </>
  );
};

export default Shortlist;
