import React, { useState, useEffect } from 'react';
import './Team.css'; // Import the CSS file
import axios from "axios"

const TeamSection = () => {
  const [teamData, setTeamData] = useState([]); // Corrected state initialization syntax

  const fetchTeamData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/cms/team`);
      const data = res.data.teamMembers;
      setTeamData(data);
      console.log(`team`,data)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTeamData();
  }, []);

  return (
    <>
      <h2 className='heading-team'>
        <center>Team</center>
      </h2>
      <div className="team-section">
        {teamData.map((member, index) => (
          <div className="team-member" key={index}>
            <div className="team-member-image">
              <img src={`${process.env.REACT_APP_SERVER_URL}${member.image}`} alt={member.name} />
            </div>
            <div className="team-member-info">
              <h4>{member.name}</h4>
              <p>{member.designation}</p>
              <p>{member.description}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default TeamSection;
