import React from "react";
import "./mobilesidebar.css";
import { image } from "../../constants";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import {
  AiOutlineHistory,
  AiOutlineUnorderedList,
  AiOutlineHeart,
  AiOutlineMail,
} from "react-icons/ai";
import { RiGalleryLine } from "react-icons/ri";
import { BiBlock } from "react-icons/bi";
import { HiOutlineTicket } from "react-icons/hi";
import { RxAvatar, RxExit } from "react-icons/rx";
import { BsKey, BsChatDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/actions";
import { toggleSidebar } from "../../redux/sidebar/actions";


const MobileSidebar = (props) => {
  
  const handleLinkClick = () => {
    props.onLinkClick();
  };
 
  const { currentUser  } = useSelector((state) => state.User);
  const { showSidebar } = useSelector((state) => state.sidebarReducer);

  const dispatch = useDispatch();
  if (!currentUser) return;

  // const handleClick = () => {
  //   dispatch(toggleSidebar());
  // };
  
  return (
    <>
      <div className="mobilesidebarsidebar">
        <div className="mobilesidebarprofile-avatar-info">
          {currentUser.profile.avatar ? (
            <img
              src={
                process.env.REACT_APP_SERVER_URL + currentUser.profile.avatar
              }
              alt={currentUser.firstName + " " + currentUser.surname}
            />
          ) : (
            <img src={image.avatar} alt="" />
          )}

          <h2>{currentUser.firstName + " " + currentUser.surname}</h2>
          <h5> ID : {currentUser.userName}</h5>
          <button
            type="button"
            className="mobilesidebarprofile-avatar-info-btn"
          >
            Public Profile
          </button>
        </div>

        <div className="mobilesidebarsidebar-tabs">
          <ul>
            <div className="mobilesidebarhover-div">
              <Link to="/dashboard/info" style={{ textDecoration: "none" }} onClick={handleLinkClick}>
                {" "}
                <li>
                  <MdOutlineSpaceDashboard
                    size={16}
                    className="mobilesidebarsidebar-tabs-icons"
                  />
                  Dashboard
                </li>
              </Link>
            </div>
            <div className="hover-div">
              <Link to="/search-olakhid" style={{ textDecoration: "none" }} onClick={handleLinkClick}>
                {" "}
                <li>
                  <RxAvatar
                    size={16}
                    className="sidebar-tabs-icons"
                  />
                  Search By OLAKH ID
                </li>
              </Link>
            </div>
            <Link to="/dashboard/gallery" style={{ textDecoration: "none" }} onClick={handleLinkClick}>
              <li>
                <RiGalleryLine size={16} className="sidebar-tabs-icons" />
                Gallery
              </li>
            </Link>

            <Link to="/dashboard/shortlist" style={{ textDecoration: "none" }} onClick={handleLinkClick}>
              <li>
                <AiOutlineUnorderedList
                  size={16}
                  className="sidebar-tabs-icons"
                />
                My Shortlist
              </li>
            </Link>

            <Link to="/dashboard/who-shortlisted-me" style={{ textDecoration: "none" }} onClick={handleLinkClick}>
              <li>
                <AiOutlineUnorderedList
                  size={16}
                  className="sidebar-tabs-icons"
                />
                Who Shortlisted Me
              </li>
            </Link>

            <Link
              to="/dashboard/my-interest"
              style={{ textDecoration: "none" }}
              onClick={handleLinkClick}
            >
              <li>
                <AiOutlineHeart size={16} className="sidebar-tabs-icons" />
                My Interest
              </li>
            </Link>

            <Link
              to="/dashboard/interest-received"
              style={{ textDecoration: "none" }}
              onClick={handleLinkClick}
            >
              <li>
                <AiOutlineHeart size={16} className="sidebar-tabs-icons" />
                Interest Received
              </li>
            </Link>
            <Link
             to="/dashboard/who-viewed-my-profile"
              style={{ textDecoration: "none" }}
              onClick={handleLinkClick}
            >
              <li>
                <AiOutlineHeart size={16} className="sidebar-tabs-icons" />
               Who Visited My Profile
              </li>
            </Link>
            <Link
              to="/dashboard/my-profile-views"
              style={{ textDecoration: "none" }}
              onClick={handleLinkClick}
            >
              <li>
                <AiOutlineHeart size={16} className="sidebar-tabs-icons" />
                My Profile Views
              </li>
            </Link>

            {/* <Link
              to="/dashboard/ignored-lists"
              style={{ textDecoration: "none" }}
            >
              <li>
                <BiBlock size={16} className="sidebar-tabs-icons" />
                Ignored Lists
              </li>
            </Link> */}
            {/* <Link to="/dashboard/message" style={{ textDecoration: "none" }}>
              <li>
                <AiOutlineMail size={16} className="sidebar-tabs-icons" />
                Message
              </li>
            </Link> */}
            {/* <Link
              to="/dashboard/support-ticket"
              style={{ textDecoration: "none" }}
            >
              <li>
                <HiOutlineTicket size={16} className="sidebar-tabs-icons" />
                Support Tickets
              </li>
            </Link> */}

            <Link
              to="/dashboard/profile-settings"
              style={{ textDecoration: "none" }}
              onClick={handleLinkClick}
            >
              <li>
                <RxAvatar size={16} className="sidebar-tabs-icons" />
                Profile Settings
              </li>
            </Link>

            <Link
              to="/dashboard/chats"
              style={{ textDecoration: "none" }}
              onClick={handleLinkClick}
            >
              <li>
                <BsChatDots size={16} className="sidebar-tabs-icons" />
                Chats
              </li>
            </Link>

            <Link
             to="/dashboard/change-password"
              style={{ textDecoration: "none" }}
              onClick={handleLinkClick}
            >
              <li>
                <BsChatDots size={16} className="sidebar-tabs-icons" />
                Change Password
              </li>
            </Link>
            {/* <Link
              to="/dashboard/change-password"
              style={{ textDecoration: "none" }}
            >
              <li>
                <BsKey size={16} className="sidebar-tabs-icons" />
                Change Password
              </li>
            </Link> */}
            <Link
              to="/home"
              style={{ textDecoration: "none" }}
              onClick={() => dispatch(logout())}
              
            >
              <li>
                <RxExit size={16} className="sidebar-tabs-icons" />
                Sign Out
              </li>{" "}
            </Link>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MobileSidebar;
