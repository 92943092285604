import React, { useState } from 'react';
import axios from 'axios';

const Salman = () => {
  const [profileImage, setProfileImage] = useState(null);
  const [name, setName] = useState('');

  const handleProfileImageChange = (event) => {
    setProfileImage(event.target.files[0]);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append('profileImage', profileImage);
      formData.append('name', name);

      await axios.post('http://localhost:5005/api/user/api/salmanadd', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      // Reset form
      setProfileImage(null);
      setName('');
    } catch (error) {
      console.log('Error submitting form:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>
          Upload Profile Image:
          <input type="file" onChange={handleProfileImageChange} />
        </label>
      </div>
      <div>
        <label>
          Name:
          <input type="text" value={name} onChange={handleNameChange} />
        </label>
      </div>
      <button type="submit">Submit</button>
    </form>
  );
};

export default Salman;
