import React, { useEffect, useState } from 'react';
import axios from '../axios';

function Activity() {
  const [activity, setActivity] = useState(false);
  let timer = null;
  let timerActive = false;

  const sendActivity = (isActive) => {
    axios
      .post('/user/activity-check', { resFromFront: isActive })
      .then((response) => {
        console.log(`Activity sent to the API as ${isActive}`);
        // Handle the API response if needed
      })
      .catch((error) => {
        console.error(`Error sending activity as ${isActive}:`, error);
        // Handle API error
      });
  };

  const startTimer = () => {
    if (!timerActive) {
      timer = setTimeout(() => {
        if (activity) {
          sendActivity(true);
        }
        setActivity(false);
        timerActive = false;
      }, 5000); // 5 minutes
      timerActive = true;
    }
  };

  const checkAndUpdateActivity = () => {
    setActivity(true);
    startTimer();
    console.log('User activity detected');
  };

  useEffect(() => {
    sendActivity(true);
    const listeners = ['keydown', 'mousemove', 'touchstart'];

    listeners.forEach((event) => {
      document.addEventListener(event, checkAndUpdateActivity);
    });

    console.log('Activity tracker script is running');
   
    // Add a beforeunload event listener to send a final API request before the browser closes
    const handleBeforeUnload = (event) => {
      // Prevent the default confirmation dialog
      event.preventDefault();
      sendActivity(false);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up event listeners and timers when the component unmounts
    return () => {
      listeners.forEach((event) => {
        document.removeEventListener(event, checkAndUpdateActivity);
      });
      if (timerActive) {
        clearTimeout(timer);
        if (activity) {
          sendActivity(true);
        }
      }
      // Remove the beforeunload event listener
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [activity]);

  return (
    <div>
      {/* Your React component's content */}
    </div>
  );
}

export default Activity;
