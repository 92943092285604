import axiosLogin from "axios";
import axios from "../../axios";
import base64 from "base-64";
import {
  USER_LOGIN_GOOD,
  USER_LOGIN_BAD,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  GET_ME_SUCCESS,
  GET_ME_FAIL,
  PROFILE_PICTURE_UPDATE_SUCCESS,
  PROFILE_PICTURE_UPDATE_fail,
  GET_SINGLE_USER,
  FETCH_SAMMELAN_SUCCESS,
  FETCH_SAMMELAN_FAILURE,
  FETCH_SAMMLEN_USERS,
  SET_CURRENT_PAGE
} from "./types";

const BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL_SERVER;

export const login =
  ({ userName, password }) =>
  async (dispatch) => {
    try {
      let encoded = userName + ":" + password;
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Basic ${base64.encode(encoded)}`,
        },
      };
      const res = await axiosLogin.get(BASE_URL + "/user/login", config);

      dispatch({
        type: USER_LOGIN_GOOD,
        payload: res.data,
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        dispatch({
          type: USER_LOGIN_BAD,
          payload: error.response.data.msg,
        });
      } else {
        dispatch({
          type: USER_LOGIN_BAD,
          payload: "An error occurred. Please try again.",
        });
      }
    }
  };

export const logout = () => async (dispatch) => {
  try {
    await axios.delete("/user/logout");
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: LOGOUT_FAIL,
    });
  }
};

export const getMe = () => async (dispatch) => {
  try {
    const res = await axios.get("/user/me");
    dispatch({
      type: GET_ME_SUCCESS,
      payload: res.data.user,
    });
  } catch (error) {
    dispatch({
      type: GET_ME_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const profilePictureUpdate = (body) => async (dispatch) => {
  try {
    console.log(body);
    const res = await axios.post("/approval/by-user/profile-image", body);
    console.log(res);
  } catch (error) {
    console.log(error);
  }
};

export const getSingleUser = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/user/single-user-profile/${id}`);
    dispatch({
      type: GET_SINGLE_USER,
      payload: res.data.user,
    });
    console.log(res);
  } catch (error) {
    console.log(error);
  }
};


export const fetchSammelan = (id) =>async (dispatch)=>{
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/sammelan/${id}`
    );
    dispatch ({
      type : FETCH_SAMMELAN_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    console.log(error)
  }
}


// export const fetchSammleanUsers = (id, currentPage, usersPerPage, ) => async (dispatch, getState) => {
//   console.log('Fetching Sammlean Users with:', id, currentPage, usersPerPage);

//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/user/sammelanusers/${id}?page=${currentPage}&pageSize=${usersPerPage}`
//     );
//   //  console.log('response from action', response)
//    let pagination = response.data.data.pagination
//     let filteredUsers = response.data.data.users;
//     filteredUsers.sort((a, b) => {
//       const numericA = parseInt(a.userName.match(/\d+/)?.[0] || 0, 10);
//       const numericB = parseInt(b.userName.match(/\d+/)?.[0] || 0, 10);

//       if (isNaN(numericA) && !isNaN(numericB)) {
//         return 1;
//       } else if (!isNaN(numericA) && isNaN(numericB)) {
//         return -1;
//       } else {
//         return numericA - numericB;
//       }
//     });

//     const { User } = getState(); // Assuming 'User' is the slice where you store user-related data

//     if (User.isAuthenticated && User.currentUser && User.currentUser.profile) {
//       const  gender  = User.currentUser.profile.gender;

//       // Check if the user is authenticated and the gender is specified
//       if (gender) {
//         if (gender === 'Male') {
//           filteredUsers = filteredUsers.filter(
//             (user) => user.profile.gender === 'Female'
//           );
//         } else if (gender === 'Female') {
//           filteredUsers = filteredUsers.filter(
//             (user) => user.profile.gender === 'Male'
//           );
//         }
//       }
//     } else {
//       // If the user is not authenticated or gender is not specified, filter out all users
//       filteredUsers = [];
    
//     }

//     dispatch({
//       type: FETCH_SAMMLEN_USERS,
//       payload: filteredUsers, 
//       pagination :pagination
//     });
//     // console.log("Dispatched action with filtered users:", filteredUsers);
//     // console.log('action paginations' , pagination)
 
//   } catch (error) {
//     console.log(error)
//   }
// };


export const fetchSammleanUsers = (id, currentPage, usersPerPage) => async (dispatch, getState) => {
  console.log('Fetching Sammlean Users with:', id, currentPage, usersPerPage);

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ADMIN_BASE_URL_SERVER}/user/sammelanusers/${id}?page=${currentPage}&pageSize=${usersPerPage}`
    );

    const { User } = getState();

    let filteredUsers = response.data.data.users;

    if (User.isAuthenticated && User.currentUser && User.currentUser.profile) {
      const gender = User.currentUser.profile.gender;

      if (gender) {
        if (gender === 'Male') {
          filteredUsers = filteredUsers.filter((user) => user.profile.gender === 'Female');
        } else if (gender === 'Female') {
          filteredUsers = filteredUsers.filter((user) => user.profile.gender === 'Male');
        }
      }
    } else {
      filteredUsers = [];
    }

    dispatch({
      type: FETCH_SAMMLEN_USERS,
      payload: filteredUsers,
      pagination: response.data.data.pagination,
    });
  } catch (error) {
    console.log(error);
  }
};
  


export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
});
