import React, { useState } from 'react';
import './mobilenav.css';
import { AiOutlineHome, AiOutlineDashboard } from 'react-icons/ai';
import { VscAccount } from 'react-icons/vsc';
import { BsPeople } from 'react-icons/bs';
import { BiMessageDetail } from 'react-icons/bi';
import MobileSidebar from '../mobilesidebar/MobileSidebar';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

function Mobilenav() {
  const [showSidebar, setShowSidebar] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.User);

  const handleDashboardClick = () => {
    setShowSidebar(!showSidebar);
  };

  const handleLinkClick = () => {
    setShowSidebar(false);
  };

  return (
    <>
      <div className="mobilesidebar-container" style={{ display: showSidebar ? 'block' : 'none' }}>
        <div className="mobilesidebar-header">
          
        </div>
        <MobileSidebar onLinkClick={handleLinkClick} />
      </div>

      <div className="mobilenav-strip-container">
        <div className="mobilenav-strip-icons">
          <Link to='/home'>
            <AiOutlineHome color="grey" size={24} />
            <p>Home</p>
          </Link>
        </div>
        <div className="mobilenav-strip-icons">
          <Link to="/search-olakhid">
            <BsPeople color="grey" size={24} />
            <p>Search ID</p>
          </Link>
        </div>
        <div className="mobilenav-strip-icons">
          <Link to="/sammelans">
            <BiMessageDetail color="grey" size={24} />
            <p>Sammelans</p>
          </Link>
        </div>

        {isAuthenticated ? (
          <div className="mobilenav-strip-icons" onClick={handleDashboardClick}>
            {showSidebar ? (
              <FaTimes color="grey" size={24} />
            ) : (
              <AiOutlineDashboard color="grey" size={24} />
            )}
            <p>{showSidebar ? "Close" : "Dashboard"}</p>
          </div>
        ) : (
          <div className="mobilenav-strip-icons">
            <Link to="/register">
              <VscAccount color="grey" size={24} />
              <p>Account</p>
            </Link>
          </div>
        )}
      </div>
    </>
  );
}

export default Mobilenav;
