import React, { useEffect } from "react";
import "./user.css";
import Sidebar from "../../component/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../../redux/actions";

const User = ({ id, name, status, age, religion, handleMessageClick }) => {
  const dispatch = useDispatch();

  const { currentUser } = useSelector((state) => state.User);

  useEffect(() => {
    if (!currentUser) {
      dispatch(getMe());
    }
  }, [dispatch, currentUser]);
  return (
    <>
      <div className="main-container">
        <Sidebar />
        <div className="container">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default User;
