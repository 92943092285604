import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Gallery.css";
import axios from "../../axios";
import { useSelector } from "react-redux";
import Redirect from "../../component/Redirect/Redirect";

const Gallery = () => {
  const { isStandard } = useSelector((state) => state.User);

  const [images, setImages] = useState([]);
  const [Galleryimages, setGalleryImages] = useState([]);

  const handleUpload = (event) => {
    const fileArray = Array.from(event.target.files);
    const allowedFormats = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/webp",
    ];
    const filteredFiles = fileArray.filter((file) =>
      allowedFormats.includes(file.type)
    );
    const remainingSlots = 10 - images.length;
    setImages([...images, ...filteredFiles.slice(0, remainingSlots)]);
  };

  const fetchGalleryImages = async () => {
    try {
      const response = await axios.get("/user/gallery-approved/");
      const data = response.data.gallery;
      setGalleryImages(data);
      console.log( 'gallery ' ,data)
    } catch (error) {
      console.log(error);
    }
  };

  console.log(images);

  useEffect(() => {
    fetchGalleryImages();
  }, []);

  const handleSubmit = async () => {
    const formData = new FormData();
    images.forEach((image) => {
      formData.append("images", image);
    });

    try {
      const response = await axios.post("/user/gallery-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        alert(
          "Images uploaded successfully! Admin will approve your images shortly."
        );
        setImages([]);
      } else {
        console.error(
          "Failed to upload images. Response message:",
          response.data.message
        );
      }
    } catch (error) {
      alert(error.response.data.message);
      console.error("Error uploading images: ", error.response.data.message);
    }
  };



  const handleDeleteImage = async (imageIndex) => {
    try {
      const response = await axios.delete(`/user/gallery-delete-user/${imageIndex}`);
      if (response.status === 200) {
        // Log the state before updating
        console.log("Galleryimages before update:", Galleryimages);
  
        // Remove the deleted image from the local state
        const updatedGalleryImages = [...Galleryimages];
        updatedGalleryImages.splice(imageIndex, 1);
        
        // Log the updated state
        console.log("Updated Galleryimages:", updatedGalleryImages);
  
        // Update the state
        setGalleryImages(updatedGalleryImages);
  
        // Log the state after updating
        console.log("Galleryimages after update:", Galleryimages);
  
        toast.success("Image Deleted", {
          position: toast.POSITION.TOP_CENTER
        });
      } else {
        toast.error("Failed to delete image.");
      }
    } catch (error) {
      console.error("Error deleting image: ", error);
    }
  };


  return (
    <>
      {isStandard ? (
        <>
          <div>
            <ToastContainer/>
            <br />
            <h2>Gallery</h2>
            <label className="upload-btn">
              Upload Images
              <input
                type="file"
                multiple
                accept=".jpg,.png,.webp,.jpeg"
                onChange={handleUpload}
                name="images"
              />
            </label>
            {images.length === 10 && (
              <h3
                style={{ color: "red", fontWeight: "400", margin: "20px 0px" }}
              >
                You have reached the limit of 10 images!
              </h3>
            )}
            <div className="image-grid">
              {images.map((image) => (
                <img
                  key={image.name}
                  src={URL.createObjectURL(image)}
                  
                  alt={image.name}
                />
              ))}
            </div>
            <button className="gallery-submit-btn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </>
      ) : (
        <Redirect />
      )}

      <br />
      <br />
      <h2>Profile Picture</h2>
      <br />
      <p>You must contact admin if you want to modify your profile image.</p>
      <a href="tel:+9107314700547" target="_blank">
        <button className="profile-submit-btn">Call Now</button>
      </a>
      <br />
      <br />
      <div className="image-grid">
  {Galleryimages && Galleryimages.length > 0 ? (
    Galleryimages.slice(1).map((imageData, index) => (
      <div key={index} className="gallery-images">
        {typeof imageData === "string" ? (
          <img src={imageData} alt="Gallery Image" />
        ) : (
          <img src={imageData.image} alt="Gallery Image" />
        )}
        <div className="gallery-delete">
          <button className="gallery-btn" onClick={() => handleDeleteImage(index + 1)}>
            Delete
          </button>
        </div>
      </div>
    ))
  ) : (
    <p>No gallery images found.</p>
  )}
</div>



    </>
  );
};

export default Gallery;
