import React, { useState, useEffect } from "react";
// import "./table.css";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "../../axios";
import { Link, useParams } from "react-router-dom";
import Redirect from "../../component/Redirect/Redirect";
import { useSelector } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination"; // Import TablePagination

const useStyles = makeStyles({
  table: {
    border: "none",
    "& th": {
      border: "none",
      fontWeight: "bold",
    },
  },
  avatar: {
    display: "inline-flex",
    marginRight: "1rem",
  },
});

const { personid } = useParams
const Tablecomp = () => {
  const { isStandard } = useSelector((state) => state.User);
  const [interestedUsers, setInterestedUsers] = useState([]);
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page

  useEffect(() => {
    axios
      .get("/interactions/interested/:id")
      .then((response) => {
        setInterestedUsers(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleDelete = (personId) => {
    axios
      .post(`/interactions/myinterest_delete/${personId}`)
      .then((response) => {
        console.log("User deleted successfully");
        window.location.reload();
        // Perform any additional actions after deletion
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
        // Handle any error that occurred during deletion
      });
  };

  const classes = useStyles;

  // Calculate the index range for the currently visible items
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const calculateAge = (birthYear) => {
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear;
  };

  return (
    <>
      {isStandard ? (
        <div>
          <h2>My Interested Profiles</h2>

          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Olakh Id</TableCell>
                  <TableCell>Avatar</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Age</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {interestedUsers
                  .filter((user) => user.interestedByMe !== null)
                  .slice(startIndex, endIndex)
                  .map((user, index) => (
                    <TableRow key={index}>
                      {/* <TableCell>{startIndex + index + 1}</TableCell> */}
                      <TableCell> {user.interestedByMe.userName?.match(/\d+/)}</TableCell>

                      <TableCell>
                        {user.interestedByMe &&
                          user.interestedByMe.profile &&
                          user.interestedByMe.profile.avatar ? (
                          <Link to={`/user-profile/${user.interestedByMe._id}`}>
                            <Avatar
                              className={classes.avatar}
                              alt={user.interestedByMe.firstName}
                              src={
                                process.env.REACT_APP_SERVER_URL +
                                user.interestedByMe.profile.avatar
                              }
                            />
                          </Link>
                        ) : (
                          <Avatar />
                        )}
                      </TableCell>
                      <TableCell>
                        {user.interestedByMe ? (
                          <Link to={`/user-profile/${user.interestedByMe._id}`}>
                            {user.interestedByMe.firstName}
                          </Link>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>

                      {/* <TableCell>{calculateAge(user.interestedByMe.profile.DobYear)}</TableCell> */}
                      <TableCell>
                        {user.interestedByMe && calculateAge(user.interestedByMe.profile.DobYear)
                          ? calculateAge(user.interestedByMe.profile.DobYear)
                          : "N/A"}
                      </TableCell>



                      <TableCell>
                        <Link to="#" onClick={() => handleDelete(user.interestedByMe._id)}>
                          <IconButton>
                            <DeleteIcon style={{ color: "red" }} />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={interestedUsers.filter((user) => user.interestedByMe !== null).length} // Total number of items
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={(event, newPage) => setPage(newPage)} // Handle page change
            onChangeRowsPerPage={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0); // Reset the page when the number of rows per page changes
            }}
          />
        </div>
      ) : (
        <Redirect />
      )}
    </>
  );
};

export default Tablecomp;
